import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import Alladmins from "../../pages/views/Admin/Alladmin";


const Alladmin = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <Alladmins/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default Alladmin;