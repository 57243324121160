import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import SellerAdd from "../../pages/views/Seller/SellerCreate";


const SellerCreate = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <SellerAdd/>      
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default SellerCreate;