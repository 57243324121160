
import React from 'react';

const InputCheckbox = ({ label, type = 'checkbox', name, value, onChange, placeholder,id, defaultChecked }) => {
  return (
    <div className="form-group">
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="form-check-input"
        defaultChecked={defaultChecked}
      />
    </div>
  );
};

export default InputCheckbox;