import React from 'react';

const CommonTextarea = ({ label, name, value, onChange, placeholder, disabled = false, error,id }) => {
  return (
    <div className="form-group">
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <textarea
        name={name}
        id={id}
        rows="5"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        disabled={disabled}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default CommonTextarea;
