import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import AdminCreate from "../../pages/views/Admin/CreateAdmin";


const Admincreate = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <AdminCreate/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default Admincreate;