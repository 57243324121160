import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  GetClassificationList,
  MediaList,
} from "../../../service/apiService";
import {
  faStreetView,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { ValidateCategoryName } from "../../../validation/CustomValidation";
import { CustomValidationError } from "../../../Helper/Helper";
import { useNavigate } from "react-router-dom";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import UploadImage from "../../../assets/images/defimg.jpeg";
import noImage from "../../../assets/images/noimage.jpg";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const ClassificationList = () => {
  const [Classification, setClassification] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedcategoryId, setSelectedCategoryId] = useState(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [ClassificationImage, setClassificationImage] = useState(null);
  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [formDataUrl, setformDataUrl] = useState(null);
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [isColorPickerDisabled, setIsColorPickerDisabled] = useState(false);
  const [categoryDropdownList, setCategoryDropdown] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [ClassificationImageUrl, setClassificationImageUrl] = useState(null);
  const [deletedImage, setDeletedImage] = useState(false);
  // const [selectedImages, setSelectedImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const ListUrl = "https://folcon.elegra.in/api/";
  const [imageUrl, setImageUrl] = useState([]);
  // const token = localStorage.getItem("admin_token");
  const [categoryImage, setCategoryImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const navigate = useNavigate();


  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter((i) => i !== index));
      }
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setCategoryImage(imageUrl[index]);
      closeModal();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length >0){
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      //  }
    }
  }, [token]);

  const [categoryformData, setCategoryFormData] = useState({
    classification_name: "",
    slug: "",
    image_path: "",
    is_active: 1
  });

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;

  const pageCount = Math.ceil(Classification.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentClassification = Classification.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  useEffect(() => {
    fetchData();

  }, []);
  const [validationErrors, setValidationErrors] = useState({
    category_name: "",
  });

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const response = await GetClassificationList(token);
      setClassification(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setCategoryFormData({
      ...categoryformData,
      color_code: color,
    });
  };


  const handleEdit = async (categoryId) => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const response = await axios.get(
        `https://folcon.elegra.in/admin/get-classification/${categoryId}`,
        config
      );
      const responseData = response.data;

      setCategoryFormData({
        classification_name: responseData.data.classification_name,
        slug: responseData.data.slug,
        image_path: responseData.data.image_path,
        is_active: responseData.data.is_active,
        classification_id: responseData.data.classification_id

      });
      const previewImageUrl = responseData.data.image_path
        ? AppUrl.concat(responseData.data.image_path)
        : null;
      setPreviewImage(previewImageUrl);


      setSelectedCategoryId(categoryId);
      openEditModal();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };
  const deleteImage = () => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      setPreviewImage(null);
      categoryformData.category_image = null;
      setformDataUrl(null);
      setClassificationImage(null);
      setShowTrashIcon(false);
      setDeletedImage(true);

      setSelectedImages([]);
      setCategoryImage(null);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const categoryNameError = ValidateCategoryName(
        categoryformData.classification_name
      );
      setValidationErrors({
        classification_name: categoryNameError,
      });

      const data = {
        ...categoryformData,
        classification_name: categoryformData.classification_name,
        slug: categoryformData.slug,
        image_path: categoryformData.image_path,
        is_active: categoryformData.is_active,
        classification_id: categoryformData.classification_id
      };

      const newImageUrl = categoryImage
        ? categoryImage
        : categoryformData.image_path;
      data.image_path = newImageUrl;
      if (deletedImage === true) {
        // alert("deletedImage");
        data.image_path = null;
        setDeletedImage(false);
      }
      if (!categoryNameError) {
        const response = await axios.post(
          "https://folcon.elegra.in/admin/update-classification",
          data,
          config
        );

        const responseData = response.data;

        if (responseData.status === "success") {
          fetchData();
          toast.success("Classification updated successfully!");
          closeEditModal();
          setClassificationImage(null);
          imageUrl = null;
        } else {
          toast.error("Classification update failed. Please try again.");
        }

      }
    } catch (error) {
      console.error("Error updating Classification:", error);
    }

  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    if (id === "classification_name") {
      const categoryName = value.replace(/[^a-zA-Z\s]/g, "");
      setCategoryFormData((prevData) => ({
        ...prevData,
        [id]: categoryName,
        slug: generateSlug(categoryName),
      }));
    } else {
      setCategoryFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };
  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setCategoryFormData({
      ...categoryformData,
      is_active: activeValue,
    });
  };

  const header = (
    <div className="table-header">
      <div>
        <h3>Classification</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        <button
          onClick={() => navigate("/admin/classification-create")}
          className="btn btn-primary"
        >
          Create Classification
        </button>
      </div>
    </div>
  );


  const handleImageChange = (e) => {
    const file = e.target.files[0];


    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setClassificationImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const columns = [
    { field: "classification_id", header: "ID", sortable: true },
    { field: "classification_name", header: "ClassificationName", sortable: true },
    {
      field: "image_path",
      header: "Image",

      body: (rowData) => (
        <img
          src={
            rowData.image_path ? AppUrl + rowData.image_path : noImage
          }
          width={100}
          height={100}
          alt="classification"
        />
      ),
    },
    { field: "slug", header: "Slug" },
    {
      field: "is_active",
      sortable: true,
      header: "Status",
      body: (rowData) => (
        <span
          className={
            rowData.is_active === 1 ? "active-status" : "inactive-status"
          }
        >
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              href="#"
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              <a
                className="dropdown-item edit-icon"
                onClick={() => handleEdit(rowData.classification_id)}
                style={{ cursor: "pointer" }}
              >
                Edit info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <main id="main" className="main">
        <AdminHeader /><SideBar />
        <div className="pagetitle">
          <h1>Classification List</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Category</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
        </div>
        <ReactDataTable
          data={currentClassification}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          emptyMessage="No Category records found"
          columns={columns}
        />
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </main>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Category"
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            maxWidth: "90%",
            maxHeight: "90%",
            overflowY: "auto",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <div className="container" style={{ width: "900px" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Edit Classification
              </h2>
            </div>
            <div className="col-auto">
              <button
                onClick={closeEditModal}
                style={{
                  fontSize: "30px",
                  marginTop: "0",
                  display: "flex",
                  justifyContent: "flex-end",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{ color: "#63E6BE" }}
                />
              </button>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="form-label">
                {" "}
                Name
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="classification_name"
                value={categoryformData.classification_name}
                onChange={handleChange}
              />
              <CustomValidationError error={validationErrors.classification_name} />
            </div>
            <div className="mb-4">
              <label htmlFor="slug" className="form-label">
                {" "}
                Slug
              </label>
              <input
                type="text"
                placeholder="Type here"
                className="form-control read-only"
                id="slug"
                value={categoryformData.slug}
                onChange={handleChange}
                readOnly
              />
            </div>
            <div className="mb-4">
              <label htmlFor="toggle" className="form-label">
                Status
              </label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="toggle"
                  checked={categoryformData.is_active === 1}
                  onChange={handleToggleChange}
                />
              </div>

            </div>
            <div className="col-md-6">
              <label>Preview</label>

              <br />
              {previewImage ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    //  src={ClassificationImage}
                    src={previewImage}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      width: "200px",
                      height: "200px",
                    }}
                  />

                  <button
                    type="button"
                    onMouseEnter={() => setShowTrashIcon(true)}
                    onMouseLeave={() => setShowTrashIcon(false)}
                    onClick={() => deleteImage()}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "rgba(255, 0, 0, 0)",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ) : (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={UploadImage}
                    alt="Alt"
                    style={{
                      maxWidth: "100%",
                      width: "350px",
                      height: "200px",
                    }}
                  />
                </div>
              )}

              <br></br>
              <button
                type="button"
                className="btn btn-primary mt-2 btn-sm btn-block"
                onClick={openModal}
              >
                Select Image
              </button>
            </div>

            <SingleImageSelect
              isOpen={modalIsOpen}
              handleClose={closeModal}
              handleImageClick={handleImageClick}
              selectedImages={selectedImages}
              imageUrl={imageUrl}
              token={token}
              setImageUrl={setImageUrl}
            />

            <br />
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>

        </div>
      </Modal>
      <AdminFooter />
    </>
  );
};

export default ClassificationList;
