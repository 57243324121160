import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

import {GetAllOrderList} from "../../../service/apiService";
import { useNavigate } from "react-router-dom";





const Orderlist = () => {
   

    const [Orderlist, setOrderlist] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [OrderFormData, setOrderFormData] = useState({
        order_code: '',
        order_status: '',
        payment_status: ''
       
    });


    useEffect(() => {
        fetchData();

    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(Orderlist.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentOrderlist = Orderlist.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)





    const fetchData = async () => {
        try {
          const token = localStorage.getItem("admin_token");
          const response = await GetAllOrderList(token);
          setOrderlist(response.data);
        } catch (error) {
          console.log(error);
        }
      };
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const navigate = useNavigate();

    const handleEdit = async (OrderId) => {
        try {
          localStorage.setItem("OrderId", OrderId);
          navigate(`/admin/order_details/`);
    
    
    
        } catch (error) {
            console.error('Error Reiderct data:', error);
        }
      };
   

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');
    
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };
    
        try {
            if (!OrderFormData.order_status || !OrderFormData.payment_status) {
                toast.error('Please Fill The Form Data');
                return;
            }
    
            const createPayload = {
                ...OrderFormData,
                id: selectedOrderId,
                order_code: OrderFormData.order_code,
                order_status: OrderFormData.order_status,
                payment_status: OrderFormData.payment_status,
            };
    
            console.log("Payload being sent:", createPayload); // Debugging line
    
            const createResponse = await axios.post('https://folcon.elegra.in/admin/update-order', createPayload, config);
            console.log("API response:", createResponse); // Debugging line
    
            
            if (createResponse.data.status === "success") {
                toast.success('Order updated successfully!');
                fetchData();
                
                // setOrderFormData({
                //     order_status: '',
                //     payment_status: '',
                // });
            } else {
                console.log("Error response data:", createResponse.data); // Debugging line
                toast.error('Order update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating Order:', error);
            toast.error('Order update failed. Please try again.');
        }
        closeModal();
    };
    





    const header = (


        <div className="table-header">
            <div>
                <h3>Order List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            {/* <div className="create-category-button">
                <button onClick={() => navigate('/admin/Order-create')} className="btn btn-primary">Create Order</button>
            </div> */}
        </div>
    );



   

    const handleChange = (e) => {
        const { name, value } = e.target;
       
        setOrderFormData({ ...OrderFormData, [name]: value });
    };

    const columns = [
        { field: 'order_id', header: 'ID' },
        { field: 'order_code', header: 'Order code' },
        
        { field: 'customer_name', header: 'Customer name' },
        { field: 'total_products', header: 'Total products' },

        { field: 'total_count', header: 'Total count' },

        { field: 'total_amount', header: 'Total amount' },

        { field: 'order_status', header: 'Order status' },
        { field: 'payment_status', header: 'Paymentstatus' },

        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.order_id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <>
        
        <main id="main" className="main">
             <AdminHeader /><SideBar />
            <div className="pagetitle">
                <h1>Order list</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Order</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>

            </div>
            <section className="section">


                <ReactDataTable
                    data={currentOrderlist}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No Order records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />

            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Tax Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        // maxWidth: '80%',
                        // maxHeight: '80%',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >


                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Order</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form onSubmit={handleSubmit}>

                            <div className="mb-4">
                                <label htmlFor="order_code" className="form-label">
                                Order code
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="order_code"
                                    name="order_code"
                                    value={OrderFormData.order_code}
                                    onChange={handleChange}
                                   
                                    
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="label" className="form-label">
                                Order status
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="order_status"
                                    name="order_status"
                                    value={OrderFormData.order_status}
                                    onChange={handleChange}

                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="label" className="form-label">
                                Payment status
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="payment_status"
                                    name="payment_status"
                                    value={OrderFormData.payment_status}
                                    onChange={handleChange}

                                />
                            </div>


                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>


                    </div>

                </div>
            </Modal>


        </main>
         <AdminFooter />
         </>
    );
};

export default Orderlist;
