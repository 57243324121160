import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { ClassificationDropDown, productClassificationSubmit, AllProducts, allClassification } from "../../service/apiService";

import AdminHeader from "../../pages/views/Dashboard/AdminFooter";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";

const ProductClassificationCreateForm = () => {
  const [ClassificationLists, setClassification] = useState([]);

  const [toastMessage, setToastMessage] = useState(null);
  const [ClassificationList, setClassificationList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [ClassificationFields, setClassificationFields] = useState([{ classification_id: null, dropdownValue: "" }]);
  const [ProductFields, setProductFields] = useState([{ product_id: null, dropdownValue: "" }]);



  useEffect(() => {
    ClassificationDropDownList();
    ProductDropDownList();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await allClassification(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setClassification(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const fetchDatas = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await AllProducts(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setClassification(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const ClassificationDropDownList = async () => {
    try {
      const response = await ClassificationDropDown(token);
      setClassificationList(response.data);
    } catch (error) {
      console.error("Error fetching Classification list:", error);
    }
  };


  const ProductDropDownList = async () => {
    try {
      const response = await AllProducts(token);
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching Product list:", error);
    }
  };


 

  

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...ClassificationFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].classification_id = parseInt(value, 10); // Convert to integer
    setClassificationFields(updatedFields);
  };
  
  const handleChangeDropdowns = (index, value) => {
    const updatedFields = [...ProductFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].product_id = parseInt(value, 10); // Convert to integer
    setProductFields(updatedFields);
  };
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (ProductFields[0].dropdownValue !== "" || ClassificationFields[0].dropdownValue !== "") {
      const classification_id = ClassificationFields[0]?.classification_id || null;
      const product_id = ProductFields[0]?.product_id || null;
      
      const payload = {
        classification_id,
        product_id
      };
      
      const response = await productClassificationSubmit(token, payload);
      
      if (response.status_code === 200) {
        toast.success(response.message);
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null); 
        }, 3000);
      }
      else if (response === 400) {
        toast.error("Already Exist");
        setToastMessage("Already Exist");
      
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select classification or enter value");
    }
  };  


return (
  <main id="main" className="main">
    <AdminHeader/>
    <SideBar/>
    <div className="pagetitle">
      <h1>Product Classification Create</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/dashboard">Dashboard</a>
          </li>
          <li className="breadcrumb-item">Product Classification</li>
          <li className="breadcrumb-item active">Create</li>
        </ol>
      </nav>
    </div>
    <section className="section">
      <div className="row">
       
        <div className="col-lg-10">
          <div className="card mb-4">
            <div className="card-body">
            <form className="row g-3" onSubmit={handleSubmit}>
                  <>
                    {ProductFields.map((Product, index) => (
                      <div key={index} className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label">Product Name</label>
                            <select
                              id={`Product-dropdown-${index}`}
                              className="form-select"
                              value={Product.dropdownValue}
                              onChange={(e) => handleChangeDropdowns(index, e.target.value)}
                            >
                              {!ProductList ? (
                                <option value="">No Data...</option>
                              ) : ProductList.length === 0 ? (
                                <option value="">No Product found</option>
                              ) : (
                                <>
                                  <option value="">Select Product</option>
                                  {ProductList.map((ProductItem) => (
                                    <option key={ProductItem.product_id} value={ProductItem.product_id}>
                                      {ProductItem.product_name.toUpperCase()}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    ))}
                    {ClassificationFields.map((Classification, index) => (
                      <div key={index} className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label">Classification</label>
                            <select
                              id={`Classification-dropdown-${index}`}
                              className="form-select"
                              value={Classification.dropdownValue}
                              onChange={(e) => handleChangeDropdown(index, e.target.value)}
                            >
                              {!ClassificationList ? (
                                <option value="">No Data...</option>
                              ) : ClassificationList.length === 0 ? (
                                <option value="">No Classification found</option>
                              ) : (
                                <>
                                  <option value="">Select Classification</option>
                                  {ClassificationList.map((ClassificationItem) => (
                                    <option key={ClassificationItem.classification_id} value={ClassificationItem.classification_id}>
                                      {ClassificationItem.classification_name.toUpperCase()}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-md-3 pt-4">
                      <label className="form-label"></label>
                      <button type="submit" className="btn btn-primary">
                        Create
                      </button>
                    </div>
                  </>
                </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AdminFooter/>
  </main>
);

};



export default ProductClassificationCreateForm;
