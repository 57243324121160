import React from 'react';
import AdminHeader from '../../pages/views/Dashboard/AdminHeader';
import SideBar from '../../pages/views/Dashboard/SideBar';
import AdminFooter from '../../pages/views/Dashboard/AdminFooter';
import MediaList from '../../pages/views/Media/MediaList';


const  Media =() => {
    return (
        <div>
            <AdminHeader/>
            <SideBar/>
            <main className="main-wrap">
                <MediaList/>
            </main>
            <AdminFooter/>


        </div>



    )



}


export default Media;