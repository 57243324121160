import React from "react";
import AdminHeader from "../../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../../pages/views/Dashboard/AdminFooter";

import Footers from "../../../pages/views/Appearance/Home/Footer";


const Footercreate = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <Footers/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default Footercreate;