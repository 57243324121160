import React, { useState } from "react";
import ProductForm from "../../../components/Product/ProductForm";
import ProductEditImageMedia from "../../../components/Product/ProductEditImageMedia";
import ProductVariantsForm from "../../../components/Product/ProductVariantsForm";
import ProductVideoEditMedia from "../../../components/Product/ProductVideoEditMedia";
import ProductEditForm from "../../../components/Product/ProductEditForm";
import AdminHeader from "../Dashboard/AdminHeader";
import AdminFooter from "../Dashboard/AdminFooter";
import SideBar from "../Dashboard/SideBar";
import ProductVariantEditForm from "./ProductVariantEdit";
import ProductClassificationEditForm from "./ProductClassificationEdit";
import ProductCampaignEditForm from "./ProductCampaignEdit";


const ProductEdit = () => {
  const [productId, setProductId] = useState(null);
    const handleProductFormSuccess = (productId) => {
        if (productId) {
        setProductId(productId);
        }
      };
  return (
    <>
      <AdminHeader />
      <SideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Product Edit</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Product</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <ProductEditForm onSuccess={handleProductFormSuccess}/>
            <div className="col-lg-6">
              <ProductEditImageMedia productId={productId} />
              <ProductVideoEditMedia productId={productId} />
              <ProductVariantEditForm productId={productId} />
              <ProductClassificationEditForm productId={productId} />
              <ProductCampaignEditForm productId={productId} />
             
            </div>
          </div>
        </section>
      </main>
     <AdminFooter/>
    </>
  );
};

export default ProductEdit;
