import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadImage from '../../../assets/images/defimg.jpeg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";
import { useNavigate } from "react-router-dom";


const DiscountCreate = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [DiscountFormData, setDiscountFormData] = useState({
    name: '',
    slug: '',
    percentage: '',
    from_date: '',
    to_date: '',
    status: '',
    is_active: 1
  });
  const navigate = useNavigate();


  const [errors, setErrors] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [discountImage, setDiscountImage] = useState(null);
  const [minEndDate, setMinEndDate] = useState('');

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter(i => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setDiscountImage(imageUrl[index]);
      closeModal();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      //  if(response.length > 0) {
      response.then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
      //  }
    }
  }, [token]);

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };

  const generateToDate = (slug) => {
    const today = new Date();
    const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    return nextWeek.toISOString().split('T')[0]; // Convert date to ISO string and take the date part
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'from_date') {
      const toDate = generateToDate(value);
      setDiscountFormData({
        ...DiscountFormData,
        [id]: value,
        to_date: toDate
      });
      setMinEndDate(value);
    } else if (id === 'percentage') {
      // Remove non-numeric characters except '.'
      const filteredValue = value.replace(/[^0-9.]/g, '');
      // Ensure only one decimal point is allowed
      const formattedValue = filteredValue.split('.').slice(0, 2).join('.');
      setDiscountFormData({
        ...DiscountFormData,
        [id]: formattedValue
      });
    } else if (id === 'name') {
      const trimmedName = value.trimStart().toUpperCase();
      const hasSpaceAfterTrimmed = trimmedName.indexOf(' ') !== -1;
      if (hasSpaceAfterTrimmed) {
        setDiscountFormData({
          ...DiscountFormData,
          [id]: trimmedName,
          slug: generateSlug(trimmedName)
        });
      } else if (value === '' || value[0] !== ' ') {
        setDiscountFormData({
          ...DiscountFormData,
          [id]: value.toUpperCase(),
          slug: generateSlug(value)
        });
      }
    } else {
      setDiscountFormData({
        ...DiscountFormData,
        [id]: value
      });
    }
  };


  const validateForm = () => {
    let formErrors = {};
    if (!DiscountFormData.name) formErrors.name = "Name is required";
    if (!DiscountFormData.slug) formErrors.slug = "Slug is required";
    if (!DiscountFormData.percentage) formErrors.percentage = "Percentage is required";
    if (!DiscountFormData.from_date) formErrors.from_date = "Start date is required";
    if (!DiscountFormData.to_date) formErrors.to_date = "End date is required";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setDiscountFormData({
      ...DiscountFormData,
      is_active: activeValue
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      const percentage = parseFloat(DiscountFormData.percentage);

      const createPayload = {
        ...DiscountFormData,
        image_path: discountImage,
        name: DiscountFormData.name,
        percentage: percentage,
      };

      const createResponse = await axios.post('https://folcon.elegra.in/admin/create-discount', createPayload, config);

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setSelectedFile(null);
        toast.success('Discount created successfully!');
        setDiscountFormData({
          name: '',
          slug: '',
          percentage: '',
          from_date: '',
          to_date: '',
          is_active: 1
        });
        setErrors({});
        navigate('/admin/discount-list');
      } else {
        toast.error('Discount creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating Discount:', error);
      toast.error('Discount creation failed. Please try again.');
    }
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Discount Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Discount</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-8">
                      <input id="name" type="text" value={DiscountFormData.name} placeholder="Type here" onChange={handleChange} className="form-control" />

                      <small className="form-text text-danger">
                        <b>Note:</b>  Please enter a discount name using uppercase letters only.
                      </small>
                      {errors.name && <small className="form-text text-danger">{errors.name}</small>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">Slug</label>
                    <div className="col-sm-8">
                      <input id="slug" type="text" value={DiscountFormData.slug} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {errors.slug && <small className="form-text text-danger">{errors.slug}</small>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="from_date" className="col-sm-2 col-form-label">Start Date</label>
                    <div className="col-sm-8">
                      <input id="from_date" type="date" value={DiscountFormData.from_date} placeholder="Type here" onChange={handleChange} className="form-control" min={today} />
                      {errors.from_date && <small className="form-text text-danger">{errors.from_date}</small>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="to_date" className="col-sm-2 col-form-label">End Date</label>
                    <div className="col-sm-8">
                      <input id="to_date" type="date" value={DiscountFormData.to_date} min={minEndDate} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {errors.to_date && <small className="form-text text-danger">{errors.to_date}</small>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="percentage" className="col-sm-2 col-form-label">Percentage</label>
                    <div className="col-sm-8">
                      <input id="percentage" type="text" value={DiscountFormData.percentage} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {errors.percentage && <small className="form-text text-danger">{errors.percentage}</small>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-8">
                      <input className="form-check-input" type="checkbox" id="toggle" checked={DiscountFormData.is_active === 1} onChange={handleToggleChange} />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label>Preview</label>
                    <br />
                    {previewImage ? (
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />
                        <button
                          type="button"
                          onClick={handleDeletePreview}
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            border: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br />
                    <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openModal}>
                      Select Image
                    </button>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={closeModal}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
            token={token}
            setImageUrl={setImageUrl}
          />
        </div>
      </section>
    </main>
  );
}

export default DiscountCreate;
