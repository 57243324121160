import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import DiscountList from "../../pages/views/discount/Discountlist";


const Discountlist = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <DiscountList/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default Discountlist;