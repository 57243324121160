import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import VariantsAdd from '../../pages/views/Variants/VariantsCreate';



const BrandCreate = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <VariantsAdd/>
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default BrandCreate;