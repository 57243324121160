
import React from 'react';

const Dropdown = ({ label, name, value, onChange, options,id, disabled = false, error }) => {
  return (
    <div className="form-group">
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <select
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        className="form-select"
              disabled={disabled}
      >
        <option value="">Select an option</option>
        {/* {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))} */}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default Dropdown;
