import React from "react";
import { Link } from "react-router-dom";
import PageNotFound from "../../../assets/images/404.png";
import '../../../assets/css/notFound.css';

const NotFound = () => {
    return (
        <div className="not-found-container">
        <div className="not-found-content">
          <img src={PageNotFound} height={"500"} width={"500px"} />
          <h1 className="not-found-title">404</h1>
          <p className="not-found-text">Oops! Page not found.</p>
          <Link to="/admin/dashboard" className="not-found-link">
            Go back to Home
          </Link>
        </div>
      </div>
    );
};
export default NotFound;