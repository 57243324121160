import React, { useState, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faTrashCanArrowUp, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UploadImage from '../../../assets/images/upload.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import ReactDataTable from '../../../components/DataTable';
import ReactPaginate from 'react-paginate';
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { MediaList } from "../../../service/apiService";
import noImage from "../../../assets/images/noimage.jpg";


const BrandList = () => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [brands, setBrands] = useState([]);
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const ImageAppUrl = 'https://folcon.elegra.in/api/files/';
  const ListUrl = 'https://folcon.elegra.in/api/';
  const [formData, setFormData] = useState({
    name: '',
    slug: '',
  });

  const [imag, setImag] = useState(null);
  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [deletedImage, setDeletedImage] = useState(false);



  const [brandImage, setBrandImage] = useState(null);

  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;

  const pageCount = Math.ceil(brands.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentbrands = brands.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)



  //  const handleDeletePreview = () => {
  //   setPreviewImage(null);
  //   setSelectedImages([]);
  //   setBrandImage(null);

  // };


  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter(i => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(ImageAppUrl + imageUrl[index]);
      //alert(imageUrl);
      setBrandImage(imageUrl[index]);
      closeMediaModal();
    }
  };


  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setFormData({
      ...formData,
      is_active: activeValue
    });
  };
  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };
  useEffect(() => {
    fetchData();

    if (token) {
      const response = MediaList(token);
      //   if(response.length > 0){
      response.then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') || url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
      // }

    }
  }, [token]);



  const deleteImage = () => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      setSelectedFile(null);
      setPreviewImage(null);
      setShowTrashIcon(false);
      setImag(null);
    }
  }

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await fetch('https://folcon.elegra.in/admin/all-brands', config);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();

      let brandsData = [];

      if (Array.isArray(responseData)) {
        brandsData = responseData;
      } else if (Array.isArray(responseData.data)) {
        brandsData = responseData.data;
      } else {
        throw new Error('Data is not in the expected format');
      }

      setBrands(brandsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setShowTrashIcon(true);
    };
    reader.readAsDataURL(file);
  };


  const handleEdit = async (brandId) => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const response = await axios.get(`https://folcon.elegra.in/admin/get-brand/${brandId}`, config);
      const responseData = response.data;
      setFormData({
        name: responseData.data.brand_name,
        slug: responseData.data.slug,
        brand_id: responseData.data.id,
        is_active: responseData.data.is_active,
        brand_image: responseData.data.brand_image

      });
      setSelectedBrandId(brandId);
      const previewImageUrl = responseData.data.brand_image ? ImageAppUrl.concat(responseData.data.brand_image) : null;
      setPreviewImage(previewImageUrl);
      setImag(responseData.data.brand_image);
      openEditModal();

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const handleDeletePreview = () => {
    if (window.confirm('Are you sure you want to delete this image?')) {
      setPreviewImage(null);
      setSelectedImages([]);
      setBrandImage(null);
      setDeletedImage(true);
      formData.brand_image = null;
    }

  };

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const [mediaModalIsOpen, setMediaModalIsOpen] = useState(false);

  const openMediaModal = () => {
    setMediaModalIsOpen(true);
  };

  const closeMediaModal = () => {
    setMediaModalIsOpen(false);
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!formData.name || !formData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const createPayload = {
        ...formData,
        brand_image: brandImage,
        brand_name: formData.name,
        brand_id: formData.brand_id,
      };
      const newImageUrl = brandImage ? brandImage : formData.brand_image;
      createPayload.brand_image = newImageUrl;
      if (deletedImage === true) {
        createPayload.brand_image = null;

      }

      console.log("createPayload", createPayload);
      const createResponse = await axios.post('https://folcon.elegra.in/admin/update-brand', createPayload, config);

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setSelectedFile(null);
        toast.success('Brand Updated successfully!');
        fetchData();
        setFormData({
          name: '',
          slug: '',
          is_active: 0,
          brand_id: ''
        });
      } else {
        toast.error('Brand creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating brand:', error);
      toast.error('Brand creation failed. Please try again.');
    }
    closeEditModal();
  };


  const handleDelete = async (brandId) => {
    try {
      const token = localStorage.getItem('admin_token');
      const csrfToken = localStorage.getItem('csrfToken');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-CSRF-Token': csrfToken,
        },
      };

      const data = {
        brand_id: brandId
      };

      const response = await axios.post('https://folcon.elegra.in/admin/delete-brand', data, config);

      const responseData = response.data;
      if (responseData.status === "success") {
        toast.success('Brand deleted successfully!');
        fetchData();
      } else {
        toast.error('Brand deletion failed. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value,
      slug: generateSlug(value)
    }));
  };


  const columns = [
    { field: 'id', header: 'ID' },
    { field: 'brand_name', header: 'Brand Name' },
    { field: 'slug', header: ' Slug' },

    { field: 'image_path', header: 'Image', body: (rowData) => <img src={rowData.brand_image ? ImageAppUrl + (rowData.brand_image) : noImage} width={100} height={100} alt="brands" /> },
    { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
    {
      field: 'action', header: 'Action', body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.id)}>Edit info</a>
              <a className="dropdown-item text-danger" style={{ cursor: 'pointer' }} onClick={() => handleDelete(rowData.id)}>Delete</a>
            </div>
          </div>
        </div>
      )
    }
  ];


  const header = (
    <div className="table-header">
      <div>
        <h3>Brands</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        <button onClick={() => navigate('/admin/brands/create')} className="btn btn-primary">Create Brand</button>
      </div>
    </div>
  );







  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Brand List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Brand</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <ReactDataTable
          data={currentbrands}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          emptyMessage="No brands records found"
          columns={columns}
        />
        <ReactPaginate
          previousLabel={'Previous'}
          nextLabel={'Next'}
          breakLabel={'...'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />

      </section>
      <ToastContainer />
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Brand Info"
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            position: 'relative',
            backgroundColor: '#fff',
            borderRadius: '10px',
            padding: '20px',
            // maxWidth: '80%',
            // maxHeight: '80%',
            overflowY: 'auto',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          },
        }}
      >
        <div className='container' style={{ width: '900px' }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Brand</h2>
            </div>
            <div className="col-auto"></div>
            <button onClick={closeEditModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
            </button>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label htmlFor="slug" className="form-label">
                  Slug
                </label>
                <input
                  type="text"
                  placeholder="Type here"
                  className="form-control readonly"
                  id="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label htmlFor="toggle" className="form-label">
                  Status
                </label>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggle"
                    checked={formData.is_active === 1}
                    onChange={handleToggleChange}
                  />
                </div>
              </div>
              <div className="mb-4">

                <div className="card-header">
                  <h4>Preview</h4>
                  <br />

                  {previewImage ? (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />

                      <button
                        type="button"
                        onClick={handleDeletePreview}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  ) : (
                    <img src={UploadImage} alt="Alt" />
                  )}
                  <br></br>
                  <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openMediaModal}>
                    Select Image
                  </button>
                </div>
              </div>

              <button type="submit" className="btn btn-primary">Update</button>
            </form>
          </div>
        </div>
        <SingleImageSelect
          isOpen={mediaModalIsOpen}
          handleClose={closeMediaModal}
          handleImageClick={handleImageClick}
          selectedImages={selectedImages}
          imageUrl={imageUrl}
          token={token}
          setImageUrl={setImageUrl}
        />

      </Modal>

    </main>

  );
}

export default BrandList;
