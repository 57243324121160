import React from "react";

const AdminFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className="footer">
      <div className="copyright">
        © Copyright{" "}
        <strong>
          <span>ReactAdmin</span>
        </strong>
        . All Rights Reserved, {currentYear}
      </div>
    </footer>
  );
};

export default AdminFooter;
