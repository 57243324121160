import React from "react";



const MainDashboard = () => {
    return (
        <>
        <main>
            <h1>
                Dashboard
            </h1>
        </main>
        </>
    );
};

    export default MainDashboard;