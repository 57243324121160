import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

import { CampaignDropDown, productCampaignSubmit, AllProducts, AllCampaign} from "../../service/apiService";

import AdminHeader from "../../pages/views/Dashboard/AdminFooter";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";

const ProductcampaignCreateForm = () => {
  const [campaignLists, setcampaign] = useState([]);

  const [toastMessage, setToastMessage] = useState(null);
  const [campaignList, setcampaignList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [campaignFields, setcampaignFields] = useState([{ campaign_id: null, dropdownValue: "" }]);
  const [ProductFields, setProductFields] = useState([{ product_id: null, dropdownValue: "" }]);



  useEffect(() => {
    campaignDropDownList();
    ProductDropDownList();
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await AllCampaign(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setcampaign(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const fetchDatas = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await AllProducts(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setcampaign(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const campaignDropDownList = async () => {
    try {
      const response = await CampaignDropDown(token);
      setcampaignList(response.data);
    } catch (error) {
      console.error("Error fetching campaign list:", error);
    }
  };


  const ProductDropDownList = async () => {
    try {
      const response = await AllProducts(token);
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching Product list:", error);
    }
  };


 

  

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...campaignFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].campaign_id = parseInt(value, 10); // Convert to integer
    setcampaignFields(updatedFields);
  };
  
  const handleChangeDropdowns = (index, value) => {
    const updatedFields = [...ProductFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].product_id = parseInt(value, 10); // Convert to integer
    setProductFields(updatedFields);
  };
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (ProductFields[0].dropdownValue !== "" || campaignFields[0].dropdownValue !== "") {
      const campaign_id = campaignFields[0]?.campaign_id || null;
      const product_id = ProductFields[0]?.product_id || null;
      
      const payload = {
        campaign_id,
        product_id
      };
      
      const response = await productCampaignSubmit(token, payload);
      
      if (response.status_code === 200) {
        toast.success(response.message);
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null); 
        }, 3000);
      }

      else if (response === 400) {
        toast.error("Already Exist");
        setToastMessage("Already Exist");
      
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select campaign or enter value");
    }
  };  


return (
  <main id="main" className="main">
    <AdminHeader/>
    <SideBar/>
    <div className="pagetitle">
      <h1>Product campaign Create</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/dashboard">Dashboard</a>
          </li>
          <li className="breadcrumb-item">Product campaign</li>
          <li className="breadcrumb-item active">Create</li>
        </ol>
      </nav>
    </div>
    <section className="section">
      <div className="row">
       
        <div className="col-lg-10">
          <div className="card mb-4">
            <div className="card-body">
            <form className="row g-3" onSubmit={handleSubmit}>
                  <>
                    {ProductFields.map((Product, index) => (
                      <div key={index} className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label">Product Name</label>
                            <select
                              id={`Product-dropdown-${index}`}
                              className="form-select"
                              value={Product.dropdownValue}
                              onChange={(e) => handleChangeDropdowns(index, e.target.value)}
                            >
                              {!ProductList ? (
                                <option value="">No Data...</option>
                              ) : ProductList.length === 0 ? (
                                <option value="">No Product found</option>
                              ) : (
                                <>
                                  <option value="">Select Product</option>
                                  {ProductList.map((ProductItem) => (
                                    <option key={ProductItem.product_id} value={ProductItem.product_id}>
                                      {ProductItem.product_name.toUpperCase()}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    ))}
                    {campaignFields.map((campaign, index) => (
                      <div key={index} className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="form-label">campaign</label>
                            <select
                              id={`campaign-dropdown-${index}`}
                              className="form-select"
                              value={campaign.dropdownValue}
                              onChange={(e) => handleChangeDropdown(index, e.target.value)}
                            >
                              {!campaignList ? (
                                <option value="">No Data...</option>
                              ) : campaignList.length === 0 ? (
                                <option value="">No campaign found</option>
                              ) : (
                                <>
                                  <option value="">Select campaign</option>
                                  {campaignList.map((campaignItem) => (
                                    <option key={campaignItem.campaign_id} value={campaignItem.campaign_id}>
                                      {campaignItem.campaign_name.toUpperCase()}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="col-md-3 pt-4">
                      <label className="form-label"></label>
                      <button type="submit" className="btn btn-primary">
                        Create
                      </button>
                    </div>
                  </>
                </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AdminFooter/>
  </main>
);

};



export default ProductcampaignCreateForm;
