import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import BannerMapCreate from "../../pages/views/BannerMap/BannerMapCreate";


const BannerMap = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <BannerMapCreate/>
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default BannerMap;