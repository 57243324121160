import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  GetPagesList,
  pageSectionList,

} from "../../../service/apiService";
import {
  faStreetView,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

import { useNavigate } from "react-router-dom";

import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const PagesList = () => {
  const [Campaign, setCampaign] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");


  const token = localStorage.getItem("admin_token");

  const ListUrl = "https://folcon.elegra.in/api/";

  const navigate = useNavigate();


  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;

  const pageCount = Math.ceil(Campaign.length / itemsPerPage);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentCampaign = Campaign.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );



  useEffect(() => {
    fetchData();

  }, []);




  const handleEdit = async (pageid) => {
    try {
      localStorage.setItem("pageid", pageid);
      navigate(`/admin/sections/`);



    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const response = await GetPagesList(token);
      setCampaign(response.data);
    } catch (error) {
      console.log(error);
    }
  };




  const header = (
    <div className="table-header">
      <div>
        <h3>Pages</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        {/* <button
          onClick={() => navigate("/admin/create")}
          className="btn btn-primary"
        >
          Create Pages 
        </button> */}
      </div>
    </div>
  );



  const columns = [
    { field: "id", header: "ID", sortable: true },
    { field: "name", header: "Name", sortable: true },
    { field: "slug", header: "Slug", sortable: true },


    {
      field: "is_active",
      sortable: true,
      header: "Status",
      body: (rowData) => (
        <span
          className={
            rowData.is_active === 1 ? "active-status" : "inactive-status"
          }
        >
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              href="#"
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              <a
                className="dropdown-item edit-icon"
                onClick={() => handleEdit(rowData.id ? rowData.id : 0)}
                style={{ cursor: "pointer" }}
              >
                Edit info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <main id="main" className="main">
        <AdminHeader /><SideBar />
        <div className="pagetitle">
          <h1>Pages List</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li className="breadcrumb-item">Pages</li>
              <li className="breadcrumb-item active">List</li>
            </ol>
          </nav>
        </div>
        <ReactDataTable
          data={currentCampaign}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          emptyMessage="No Category records found"
          columns={columns}
        />
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </main>

      <AdminFooter />
    </>
  );
};

export default PagesList;
