import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";
import { CategoriesDropDown, MediaList } from "../../../service/apiService";
import Input from '../../../components/Input';
import CommonTextarea from '../../../components/CommonTextarea';
import { useNavigate } from "react-router-dom";
import UploadImage from '../../../assets/images/defimg.jpeg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SingleImageSelect from "../../../components/Media/SingleImageSelect";
import { ValidateCategoryName, ValidateDiscriptionName } from "../../../validation/CustomValidation";
import { CustomValidationError } from "../../../Helper/Helper";

const CategoryCreate = () => {
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [categoryImage, setCategoryImage] = useState(null);

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [updateParentId, setupdateParentId] = useState(0)
  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter(i => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setCategoryImage(imageUrl[index]);
      closeModal();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(url => url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.jpeg') || url.endsWith('.gif') ||url.endsWith('.webp'));
        setImageUrl(imageUrls);
      });
    }
  }, [token]);

  const [categoryDropdownList, setCategoryDropdown] = useState([]);
  const [formData, setFormData] = useState({
    category_name: '',
    slug: '',
    description: '',
    color_code: '',
    is_active: 1,
    parent_id: '',
  });
  const [validationErrors, setValidationErrors] = useState({
    category_name: '',
    description: '',
  });

  const [selectedColor, setSelectedColor] = useState("#000000");
  const [selectedColorCode, setSelectedColorCode] = useState("#000000");
  const [isColorPickerDisabled, setIsColorPickerDisabled] = useState(false);

  useEffect(() => {
    const categoryDropdown = CategoriesDropDown(token);
    categoryDropdown.then((response) => {
      setCategoryDropdown(response.data);
    }).catch((error) => {
      console.log(error);
    });
  }, [token]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    const capitalizeWords = (text) => {
      return text.replace(/[^\w\s]/gi, "").replace(/[\d]+/g, "").replace(/\b\w+\b/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    };

    const transformedValue = (id === "category_name" || id === "seller_name") ? capitalizeWords(value) : value;

    if (id === "category_name") {
      setFormData({
        ...formData,
        [id]: transformedValue,
        slug: generateSlug(transformedValue),
      });
    } else if (id === "parent_id") {
      const parentId = parseInt(value);
      setupdateParentId(parentId);
      setFormData({
        ...formData,
        parent_id: parentId,
      });

      if (parentId === 0) {
        setIsColorPickerDisabled(false);
        setSelectedColor("#000000");
        setSelectedColorCode("#000000");
      } else {
        const selectedCategory = categoryDropdownList.find((category) => category.id === parentId);
        if (selectedCategory) {
          setSelectedColor(selectedCategory.color_code);
          setSelectedColorCode(selectedCategory.color_code);
          setIsColorPickerDisabled(true);
          setFormData({
            ...formData,
            color_code: selectedCategory.color_code,
          });
        }
      }
    } else {
      setFormData({
        ...formData,
        [id]: transformedValue,
      });
    }
  };


  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, "-");
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setFormData({
      ...formData,
      is_active: activeValue,
    });
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setSelectedColorCode(color);
    setFormData({
      ...formData,
      color_code: color,
    });
  };

  //const ParentId = parseInt(formData.parent_id);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const categoryNameError = ValidateCategoryName(formData.category_name);
    setValidationErrors({
      category_name: categoryNameError,
    });

    try {
      const token = localStorage.getItem('admin_token');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const createPayload = {
        ...formData,
        category_image: categoryImage,
        parent_id: parseInt(updateParentId),
      };

      if (!categoryNameError) {
        const categoryCreateResponse = await axios.post('https://folcon.elegra.in/admin/create-category', createPayload, config);

        if (categoryCreateResponse.data.status === "success") {
          setPreviewImage(null);
          setSelectedFile(null);
          toast.success('Category created successfully!');
          navigate('/admin/categories');
          setFormData({
            category_name: "",
            description: "",
            slug: "",
            is_active: 1,
            color_code: "",
            parent_category: null,
          });
        } else {
          toast.error('Category creation failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error creating category:', error);
    }
  };

  return (
    <main id="main" className="main">
      <AdminHeader />
      <SideBar />
      <div className="pagetitle">
        <h1>Category Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/Dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Category</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10">
              <div className="card mb-4">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <label htmlFor="category_name" className="col-sm-2 col-form-label">
                        Name
                      </label>
                      <div className="col-sm-8">
                        <Input
                          id="category_name"
                          value={formData.category_name}
                          onChange={handleChange}
                          placeholder="Enter Category Name"
                        />
                        <CustomValidationError error={validationErrors.category_name} />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label htmlFor="slug" className="col-sm-2 col-form-label">
                        Slug
                      </label>
                      <div className="col-sm-8">
                        <Input
                          id="slug"
                          value={formData.slug}
                          placeholder="Slug will auto generate based on category name"
                          onChange={handleChange}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="description" className="col-sm-2 col-form-label">
                        Description
                      </label>
                      <div className="col-sm-8">
                        <CommonTextarea
                          id="description"
                          value={formData.description}
                          placeholder="Category description"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="discount" className="col-sm-2 col-form-label">
                        Parent Category
                      </label>
                      <div className="col-sm-4">
                        <select className="select-active form-select"
                          id="parent_id"
                          name="parent_id"
                          onChange={handleChange}
                        >
                          <option value={0}>No Parent</option>
                          {categoryDropdownList && categoryDropdownList.length > 0 ? (
                            categoryDropdownList.map((category) => (
                              <option key={category.id} value={category.id}>
                                {category.category_name}
                              </option>
                            ))
                          ) : (
                            <>
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="colorPicker" className="col-sm-2 col-form-label">
                        Color
                      </label>
                      <div className="col-sm-4">
                        <div className="input-group">
                          <input
                            id="colorPicker"
                            type="text"
                            name="color_code"
                            value={selectedColor}
                            onChange={(e) => handleColorChange(e.target.value)}
                            disabled={isColorPickerDisabled}
                            className="form-control"
                            style={{
                              border: `2px solid ${selectedColor}`,
                              borderRadius: "5px",
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span
                            className="input-group-text"
                            style={{
                              border: `2px solid ${selectedColor}`,
                              borderRadius: "5px",
                              backgroundColor: selectedColor,
                              color: '#000',
                              borderLeft: 'none',
                              padding: '0px 5px',
                              width: '192px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="md-4">
                      <label htmlFor="toggle" className="col-sm-2 col-form-label">
                        Status
                      </label>
                      <div className="col-sm-4 d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="toggle"
                          checked={formData.is_active === 1}
                          onChange={handleToggleChange}
                        />
                        <span className="ms-2">
                          {formData.is_active === 1 ? "Active" : "Inactive"}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label>Preview</label>
                      <br />
                      {previewImage ? (
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <img src={previewImage} alt="Preview" style={{ maxWidth: "100%", width: "200px", height: "200px" }} />
                          <button
                            type="button"
                            onClick={handleDeletePreview}
                            style={{
                              position: 'absolute',
                              top: '5px',
                              right: '5px',
                              backgroundColor: 'rgba(255, 255, 255, 0.7)',
                              border: 'none',
                              cursor: 'pointer'
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </div>
                      ) : (
                        <img src={UploadImage} alt="Alt" />
                      )}
                      <br />
                      <button type="button" className="btn btn-primary mt-2 btn-sm btn-block" onClick={openModal}>
                        Select Image
                      </button>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-2"></div>
                      <div className="col-sm-8">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={closeModal}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
            token={token}
            setImageUrl={setImageUrl}
          />
        </div>
      </section>
      <AdminFooter />
    </main>
  );
};

export default CategoryCreate;