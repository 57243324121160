import React from "react";
import AdminHeader from "../../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../../pages/views/Dashboard/AdminFooter";
import BannerCreates from "../../../pages/views/Appearance/Banner/create";


const Bannercreates = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <BannerCreates/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default Bannercreates;