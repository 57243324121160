import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { MultipleMediaUpload } from "../../service/apiService";


const SingleImageSelect = ({ isOpen, handleClose, handleImageClick, selectedImages, imageUrl, token, setImageUrl }) => {

  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeTab, setActiveTab] = useState('gallery');
  const [modalMessage, setModalMessage] = useState({ type: '', text: '' });
  const [previewImages, setPreviewImages] = useState([]);
  const MESSAGE_TIMEOUT = 5000;
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    const previews = files.map(file => URL.createObjectURL(file));
    setPreviewImages(previews);

    const allowedTypes = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
    const validFiles = Array.from(files).filter(file => allowedTypes.includes(file.type));
    if (validFiles.length !== files.length) {
      setModalMessage({ type: 'error', text: "Only JPEG, PNG, WEBP, and GIF formats are allowed" });
      setTimeout(() => setModalMessage({ type: '', text: '' }), MESSAGE_TIMEOUT);
      setSelectedFiles(null);
      setPreviewImages([]);
      return;
    }
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = selectedFiles.filter((file, i) => i !== index);
    const updatedPreviews = previewImages.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    setPreviewImages(updatedPreviews);
  };

  const handleMultiImageUpload = async () => {
    if (selectedFiles.length === 0) {
      setModalMessage({ type: 'error', text: "Please select images to upload" });
      setTimeout(() => setModalMessage({ type: '', text: '' }), MESSAGE_TIMEOUT);
      return;
    }

    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append("file_name", file);
    });

    try {
      const response = await MultipleMediaUpload(token, formData);
      if (response.status_code === 200) {
        setModalMessage({ type: 'success', text: "Images uploaded successfully" });
        setTimeout(() => setModalMessage({ type: '', text: '' }), MESSAGE_TIMEOUT);
        const newImageUrls = response.data.uploaded_files.filter(url => /\.(jpg|png|jpeg|gif)$/i.test(url));
        setImageUrl([...newImageUrls, ...imageUrl]);
      }
    } catch (error) {
      console.log(error);
      setModalMessage({ type: 'error', text: "Error uploading images" });
      setTimeout(() => setModalMessage({ type: '', text: '' }), MESSAGE_TIMEOUT);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Image Gallery"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        content: {
          position: "relative",
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: "20px",
          maxWidth: "1500px",
          minWidth: "1500px",
          maxHeight: "700px",
          minHeight: "800px",
          overflowY: "auto",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
          border: "2px solid #63E6BE",
        },
      }}
    >
      <div className="container" style={{ width: "100%" }}>
        <div className="row">
          <div className="col">
            <h2 style={{ display: "inline-block", marginRight: "10px" }}>
              Image Gallery
            </h2>
            <div className="tab-buttons">
              <button
                onClick={() => handleTabChange('gallery')}
                className={`btn btn-secondary  ${activeTab === 'gallery' ? 'active-tab' : ''}`}
              >
                Gallery
              </button>
              <span style={{ display: 'inline-block', width: '10px' }}></span>
              <button
                onClick={() => handleTabChange('upload')}
                className={`btn btn-secondary ${activeTab === 'upload' ? 'active-tab' : ''}`}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="col-auto">
            <button
              onClick={handleClose}
              style={{
                fontSize: "30px",
                marginTop: "0",
                display: "flex",
                justifyContent: "flex-end",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ color: "#63E6BE" }} />
            </button>
          </div>
        </div>
        {modalMessage.text && (
          <div className={`alert ${modalMessage.type === 'error' ? 'alert-danger' : 'alert-success'}`}>
            {modalMessage.text}
          </div>
        )}
        {activeTab === 'gallery' && (
          <div className="row image-gallery">
            {imageUrl.map((image, index) => (
              <div key={index} className="col-md-3 mb-3">
                <div
                  className="image-container"
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={AppUrl + image}
                    className="img-fluid"
                    alt={image}
                    style={{ cursor: "pointer", width: "200px", height: "250px" }}
                  />
                  <input
                    type="checkbox"
                    checked={selectedImages.includes(image)}
                    onChange={() => handleImageClick(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'upload' && (
          <div className="upload-section">
            <h2>Upload Images</h2>
            <input type="file" multiple onChange={handleFileChange} />
            <button onClick={handleMultiImageUpload} className="btn btn-secondary">Upload</button>
            <div className="selected-files-preview">
              {previewImages.map((preview, index) => (
                <div key={index} className="file-preview mb-3">
                  <img src={preview} alt={`Preview ${index}`} className="img-fluid" width="255px" height="255px" />
                  <button onClick={() => handleDeleteFile(index)} className="delete-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SingleImageSelect;
