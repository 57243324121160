import React from "react";
import Csspage from "../../pages/views/CustomCss/Csspage";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";

const CustomCss = () => {
  return (
    <>
      <AdminHeader />
      <SideBar />
      <main className="main-wrap">
        <Csspage />
      </main>
      <AdminFooter />
    </>
  );
};

export default CustomCss;
