import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGauge, faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";

const SideBar = () => {

  const initialCollapsedSections = JSON.parse(localStorage.getItem('collapsedSections')) || {
    dashboard: false,
    products: false,
    brands: false,
    categories: false,
    tax: false,
    coupon: false,
    discount: false,
    seller: false,
    variants: false,
    classification: false,
    media: false,
    orders: false,
    section: false,
    bannerType: false,
    banner: false,
    productPage: false,
    pages: false,
    ads: false,
    home: false,
  };
  const [collapsedSections, setCollapsedSections] = useState(initialCollapsedSections);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('collapsedSections', JSON.stringify(collapsedSections));
  }, [collapsedSections]);

  const toggleCollapse = (section) => {
    setCollapsedSections(prevState => ({
      ...Object.keys(prevState).reduce((acc, key) => ({
        ...acc,
        [key]: key === section ? !prevState[key] : false
      }), {})
    }));
  };

  const handleSubMenuClick = (section) => {
    setCollapsedSections(prevState => ({
      ...prevState,
      [section]: false
    }));
  };

  const handleSubMenuClick1 = (section) => {
    setCollapsedSections(prevState => ({
      ...prevState,
      [section]: true
    }));
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const isCollapse = (path) => {
    return location.pathname.startsWith(path);
  };

  const pagesPaths = [
    '/admin/appearance-faq', '/admin/admin-create', '/admin/appearance-policy',
    '/admin/appearance-policylist', '/admin/appearance-terms', '/admin/appearance-termslist', '/admin/appearance-contact',
    '/admin/appearance-contactlist', '/admin/appearance-about', '/admin/appearance-aboutlist'
  ];

  const homePaths = [
    '/admin/appearance-slider', '/admin/appearance-sliderlist', '/admin/appearance-banner',
    '/admin/appearance-bannerlist', '/admin/appearance-footer', '/admin/appearance-footerlist',
    '/admin/appearance-faq', '/admin/appearance-faqlist',
    '/admin/faqCategories',
    '/admin/faqCategory-create'

  ];

  
  const chatbotPaths = [
    '/admin/chatbot/category-create', '/admin/chatbot/category-list', '/admin/chatbot/enquiry-create',
     '/admin/chatbot/enquirylist', '/admin/chatbot/question', '/admin/chatbot/questionall'
  ];

  const isPagesActive = pagesPaths.includes(location.pathname);
  const isHomeActive = homePaths.includes(location.pathname);
  const isChatbotActive = chatbotPaths.includes(location.pathname);


  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {/* Dashboard Nav */}
        <li className="nav-item">
          <Link className="nav-link" to="/admin/dashboard"
          // onClick={() => toggleCollapse('dashboard')}
          >
            <FontAwesomeIcon icon={faGauge} />
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Ecommerce Section */}
        <li className="nav-heading">Ecommerce</li>
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/product') ? '' : 'collapsed'}`}
            data-bs-target="#products-nav"
            data-bs-toggle="collapse"
          // onClick={!collapsedSections.products  ? () => toggleCollapse('products') : ()=> handleSubMenuClick('products')}
          >
            <span>Products</span>
            {/* <FontAwesomeIcon icon={collapsedSections.products  ? faAngleDown : faAngleRight} className="ms-auto" /> */}
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="products-nav"
            className={`nav-content collapse ${isCollapse('/admin/product') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/product-list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/product-list">
                <span>All Products</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/product-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/product-create">
                <span>Create Product</span>
              </Link>
            </li>

            <li className={`${isActive('/admin/productclassification-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/productclassification-create">
                <span> Product Classification Create</span>
              </Link>
            </li>

            <li className={`${isActive('/admin/productcampaign-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/productcampaign-create">
                <span>Product Campaign create</span>
              </Link>
            </li>

          </ul>
        </li>

        {/* Brands Section */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/brands') ? '' : 'collapsed'}`}
            data-bs-target="#brands-nav"
            data-bs-toggle="collapse"
          // onClick={!collapsedSections.brands  ? () => toggleCollapse('brands') : ()=> handleSubMenuClick('brands')}
          >
            <span>Brands</span>
            {/* <FontAwesomeIcon icon={collapsedSections.brands ? faAngleDown : faAngleRight} className="ms-auto" /> */}
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="brands-nav"
            className={`nav-content collapse ${isCollapse('/admin/brands') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/brands') ? 'sidebarActive' : ''}`} >
              <Link to="/admin/brands">
                <span>All Brands</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/brands/create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/brands/create">
                <span>Create Brand</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Categories Section */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/categories') ? '' : 'collapsed'}`}
            data-bs-target="#categories-nav"
            data-bs-toggle="collapse"
          // onClick={!collapsedSections.categories  ? () => toggleCollapse('categories') : ()=> handleSubMenuClick('categories')}
          >
            <span>Categories</span>
            {/* <FontAwesomeIcon icon={collapsedSections.categories ? faAngleDown : faAngleRight} className="ms-auto" /> */}
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="categories-nav"
            className={`nav-content collapse ${isCollapse('/admin/categories') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/categories') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/categories">
                <span>All Categories</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/categories/create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/categories/create">
                <span>Create Category</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/main-categories">
                <span>Main Categories</span>
              </Link>
            </li>
            <li>
              <Link to="/sub-categories">
                <span>Sub Categories</span>
              </Link>
            </li> */}
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/tax') ? '' : 'collapsed'}`}
            data-bs-target="#tax"
            data-bs-toggle="collapse"
          >
            <span>Tax</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="tax"
            className={`nav-content collapse ${isCollapse('/admin/tax') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/tax-list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/tax-list">
                <span>Tax List</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/tax-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/tax-create">
                <span>Create Tax</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/coupon') ? '' : 'collapsed'}`}
            data-bs-target="#coupon"
            data-bs-toggle="collapse"
          >
            <span>Coupon</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="coupon"
            className={`nav-content collapse ${isCollapse('/admin/coupon') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/coupon-list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/coupon-list">
                <span>Coupon List</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/coupon-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/coupon-create">
                <span>Create Coupon</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/discount') ? '' : 'collapsed'}`}
            data-bs-target="#discount"
            data-bs-toggle="collapse"
          >
            <span>Discount</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="discount"
            className={`nav-content collapse ${isCollapse('/admin/discount') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/discount-list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/discount-list">
                <span>Discount List</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/discount-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/discount-create">
                <span>Create Discount</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Coupons Section */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/seller') ? '' : 'collapsed'}`}
            data-bs-target="#coupons-nav"
            data-bs-toggle="collapse"
          >
            <span>Seller</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="coupons-nav"
            className={`nav-content collapse ${isCollapse('/admin/seller') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/seller') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/seller">
                <span>All Seller</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/seller-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/seller-create">
                <span>Create Seller</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Variants Section */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/variants') ? '' : 'collapsed'}`}
            data-bs-target="#variants-nav"
            data-bs-toggle="collapse"
          >
            <span>Variants</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="variants-nav"
            className={`nav-content collapse ${isCollapse('/admin/variants') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/variants') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/variants">
                <span>all Variant</span>
              </Link>
            </li>
            {/* <li className={`${isActive('/admin/variants-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/variants-create">
                <span>Create Variant</span>
              </Link>
            </li> */}
          </ul>
        </li>
        {/* <li className="nav-item">
          <Link
            className="nav-link collapsed"
            data-bs-target="#banner-map"
            data-bs-toggle="collapse"
          >
            <span>BannerMap</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="banner-map"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link
               to="/tax-list">
                <span>Banner Map List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-map-create">
                <span>Create Banner Map</span>
              </Link>
            </li>
          </ul>
        </li> */}

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/classifications') ? '' : 'collapsed'}`}
            data-bs-target="#classification"
            data-bs-toggle="collapse"
          >
            <span>Classification</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="classification"
            className={`nav-content collapse ${isCollapse('/admin/classification') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/classifications') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/classifications">
                <span>all Classification</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/classification-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/classification-create">
                <span>Create Classification</span>
              </Link>
            </li>
          </ul>
        </li>


        <li className="nav-item">
          <Link
           className={`nav-link ${isCollapse('/admin/campaign') ? '' : 'collapsed'}`}
            data-bs-target="#campaign"
            data-bs-toggle="collapse"
          >
            <span>Campaign</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="campaign"
            className={`nav-content collapse ${isCollapse('/admin/campaign') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/campaigns') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/campaigns">
                <span>All Campaign</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/campaign-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/campaign-create">
                <span>Create Campaign</span>
              </Link>
            </li>
          </ul>
        </li>


        {/* Media  Section */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('admin/media') ? '' : 'collapsed'}`}
            data-bs-target="#media-nav"
            data-bs-toggle="collapse"
          >
            <span>Media</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="media-nav"
            className={`nav-content collapse ${isCollapse('/admin/media') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/media') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/media">
                <span>all Media</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/media-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/media-create">
                <span>Create Media</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* Pages Section */}

        <li className="nav-item">
          <Link
           className={`nav-link ${isCollapse('/admin/list') ? '' : 'collapsed'}`}
            data-bs-target="#pages"
            data-bs-toggle="collapse"
          >
            <span>Pages</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="pages"
            className={`nav-content collapse ${isCollapse('/admin/list') ? 'show' : ''}`}

            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/list">
                <span>All Pages</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/admin/create">
                <span>Create Pages</span>
              </Link>
            </li> */}
          </ul>
        </li>

        {/* Orders Section */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/all-orders') ? '' : 'collapsed'}`}
            data-bs-target="#orders-nav"
            data-bs-toggle="collapse"
          >
            <span>Orders</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="orders-nav"
            className={`nav-content collapse ${isCollapse('/admin/all-orders') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/all-orders') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/all-orders">
                <span>All Orders</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/order-generate') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/order-generate">
                <span>Order Generate</span>
              </Link>
            </li>
            
            {/* <li className={`${isActive('/admin/paid-orders') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/paid-orders">
                <span>Paid Orders</span>
              </Link>
            </li>
            <li className={`${isActive('admin/unpaid-orders') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/unpaid-orders">
                <span>Unpaid Orders</span>
              </Link>
            </li> */}
          </ul>
        </li>
        {/* <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/sections') ? '' : 'collapsed'}`}
            data-bs-target="#section-nav"
            data-bs-toggle="collapse"
          >
            <span>Section</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="section-nav"
            className={`nav-content collapse ${isCollapse('/admin/sections') ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className={`${isActive('/admin/sections') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/sections">
                <span>Section List</span>
              </Link>
            </li>
          </ul>
        </li> */}
        {/* Settings Section */}

        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/banner-type') ? '' : 'collapsed'}`}
            data-bs-target="#banner_type"
            data-bs-toggle="collapse"
          >
            <span>Banner Type</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="banner_type"
            className={`nav-content collapse ${isCollapse('/admin/banner-type') ? 'show' : ''}`}
            data-bs-parent=""
          >
            <li className={`${isActive('/admin/banner-type') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/banner-type">
                <span>Create</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/banner-type-list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/banner-type-list">
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        {/* <li className="nav-item">
          <Link
            className="nav-link collapsed"
            data-bs-target="#admin"
            data-bs-toggle="collapse"
          >
            <span>Admin</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="admin"
            className="nav-content collapse"
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <Link to="/admin">
                <span>All Admin</span>
              </Link> 
            </li>
            <li>
              <Link to="/admin-create">
                <span>Create Admin</span>
              </Link>
            </li>
          </ul>
        </li> */}

        {/* Appearance Section */}
        <li className="nav-heading">Appearance</li>

        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === '/admin/banner-create' || location.pathname === '/admin/banner-list' ? '' : 'collapsed'}`}
            data-bs-target="#mainbanner"
            data-bs-toggle="collapse"
          >
            <span>Banner</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="mainbanner"
            className={`nav-content collapse ${location.pathname === '/admin/banner-create' || location.pathname === '/admin/banner-list' ? 'show' : ''}`}
            data-bs-parent=""
          >
            <li className={`${isActive('/admin/banner-create') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/banner-create">
                <span>Create</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/banner-list') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/banner-list">
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === '/admin/appearance-product' || location.pathname === '/admin/appearance-productlist' ? '' : 'collapsed'}`}
            data-bs-target="#productpage"
            data-bs-toggle="collapse"
          >
            <span>Product Page</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="productpage"
            className={`nav-content collapse ${location.pathname === '/admin/appearance-product' || location.pathname === '/admin/appearance-productlist' ? 'show' : ''}`}
            data-bs-parent=""
          >
            <li className={`${isActive('/admin/appearance-product') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/appearance-product">
                <span>Create</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/appearance-productlist') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/appearance-productlist">
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>



        {/* {SubPage} */}
        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === '/admin/appearance-subpage' || location.pathname === '/admin/appearance-subpageCreate' ? '' : 'collapsed'}`}
            data-bs-target="#subpage"
            data-bs-toggle="collapse"
          >
            <span>Sub Pages</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="subpage"
            className={`nav-content collapse ${location.pathname === '/admin/appearance-subpage' || location.pathname === '/admin/appearance-subpageCreate' ? 'show' : ''}`}
            data-bs-parent="">
            <li className={`${isActive('/admin/appearance-subpage') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/appearance-subpage">
                <span> List</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/appearance-subpageCreate') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/appearance-subpageCreate">
                <span>Create </span>
              </Link>
            </li>
          </ul>
        </li>


        


        {/* <li className="nav-item">
          <Link
            className={`nav-link ${isPagesActive ? '' : 'collapsed'}`}
            data-bs-target="#page"
            data-bs-toggle="collapse"
          >
            <span>Sub Pages</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="page"
            className={`nav-content collapse ${isPagesActive ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
           

            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-policy') ? '' : 'collapsed'}`}
                data-bs-target="#policy"
                data-bs-toggle="collapse"
              >
                <span>Policy</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="policy"
                className={`nav-content collapse ${isCollapse('/admin/appearance-policy') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/appearance-policy') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-policy">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-policylist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-policylist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-terms') ? '' : 'collapsed'}`}
                data-bs-target="#term"
                data-bs-toggle="collapse"
              >
                <span>Terms and condition</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="term"
                className={`nav-content collapse ${isCollapse('/admin/appearance-terms') ? 'show' : ''}`}
                data-bs-parent="">
                <li className={`${isActive('/admin/appearance-terms') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-terms">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-termslist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-termslist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-contact') ? '' : 'collapsed'}`}
                data-bs-target="#contact"
                data-bs-toggle="collapse"
              >
                <span>Contact Us</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="contact"
                className={`nav-content collapse ${isCollapse('/admin/appearance-contact') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/appearance-contact') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-contact">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-contactlist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-contactlist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-about') ? '' : 'collapsed'}`}
                data-bs-target="#about"
                data-bs-toggle="collapse"
              >
                <span>About Us</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="about"
                className={`nav-content collapse ${isCollapse('/admin/appearance-about') ? 'show' : ''}`}
                data-bs-parent="">

                <li className={`${isActive('/admin/appearance-about') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-about">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-aboutlist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-aboutlist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li> */}


        {/* {Blogs} */}
        <li className="nav-item">
          <Link
            className={`nav-link ${isCollapse('/admin/blogs/') ? '' : 'collapsed'}`}
            data-bs-target="#blogs"
            data-bs-toggle="collapse"
          >
            <span>Blogs</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="blogs"
            className={`nav-content collapse ${isCollapse('/admin/blogs/') ? 'show' : ''}`}
            data-bs-parent="">
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/admin/blogs/blog/create' || location.pathname === '/admin/blogs/blog/list' ? '' : 'collapsed'}`}
                data-bs-target="#blog"
                data-bs-toggle="collapse"
              >
                <span>Blog</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="blog"
                className={`nav-content collapse ${location.pathname === '/admin/blogs/blog/create' || location.pathname === '/admin/blogs/blog/list' ? 'show' : ''}`}
                data-bs-parent="">
                <li className={`${isActive('/admin/blogs/blog/create') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/blogs/blog/create">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/blogs/blog/list') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/blogs/blog/list">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/admin/blogs/blogCategory/create' || location.pathname === '/admin/blogs/blogCategory/list' ? '' : 'collapsed'}`}
                data-bs-target="#blogCategory"
                data-bs-toggle="collapse"
              >
                <span>BlogCategory</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="blogCategory"
                className={`nav-content collapse ${location.pathname === '/admin/blogs/blogCategory/create' || location.pathname === '/admin/blogs/blogCategory/list' ? 'show' : ''}`}
                data-bs-parent="">
                <li className={`${isActive('/admin/blogs/blogCategory/create') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/blogs/blogCategory/create">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/blogs/blogCategory/list') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/blogs/blogCategory/list">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/admin/blogs/blogTags/create' || location.pathname === '/admin/blogs/blogTags/list' ? '' : 'collapsed'}`}
                data-bs-target="#blogTags"
                data-bs-toggle="collapse"
              >
                <span>BlogTags</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="blogTags"
                className={`nav-content collapse ${location.pathname === '/admin/blogs/blogTags/create' || location.pathname === '/admin/blogs/blogTags/list' ? 'show' : ''}`}
                data-bs-parent="">
                <li className={`${isActive('/admin/blogs/blogTags/create') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/blogs/blogTags/create">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/blogs/blogTags/list') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/blogs/blogTags/list">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${location.pathname === '/admin/appearance-ads' || location.pathname === '/admin/appearance-adslist' ? '' : 'collapsed'}`}
            data-bs-target="#ads"
            data-bs-toggle="collapse"
          >
            <span>Ads</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="ads"
            className={`nav-content collapse ${location.pathname === '/admin/appearance-ads' || location.pathname === '/admin/appearance-adslist' ? 'show' : ''}`}
            data-bs-parent="">
            <li className={`${isActive('/admin/appearance-ads') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/appearance-ads">
                <span>Create</span>
              </Link>
            </li>
            <li className={`${isActive('/admin/appearance-adslist') ? 'sidebarActive' : ''}`}>
              <Link to="/admin/appearance-adslist">
                <span>List</span>
              </Link>
            </li>
          </ul>
        </li>

        <li className="nav-item">
          <Link
            className={`nav-link ${isHomeActive ? '' : 'collapsed'}`}
            data-bs-target="#home"
            data-bs-toggle="collapse"
          >
            <span>Home</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="home"
            className={`nav-content collapse ${isHomeActive ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-slider') ? '' : 'collapsed'}`}
                data-bs-target="#slider"
                data-bs-toggle="collapse"
              >
                <span>Slider</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="slider"
                className={`nav-content collapse ${isCollapse('/admin/appearance-slider') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/appearance-slider') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-slider">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-sliderlist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-sliderlist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-banner') ? '' : 'collapsed'}`}
                data-bs-target="#banner"
                data-bs-toggle="collapse"
              >
                <span>Banner</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="banner"
                className={`nav-content collapse ${isCollapse('/admin/appearance-banner') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/appearance-banner') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-banner">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-bannerlist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-bannerlist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>



            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/faqCategor') ? '' : 'collapsed'}`}
                data-bs-target="#faqCategory"
                data-bs-toggle="collapse"
              >
                <span>FAQ Category</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="faqCategory"
                className={`nav-content collapse ${isCollapse('/admin/faqCategor') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/faqCategory-create') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/faqCategory-create">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/faqCategories') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/faqCategories">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>


            <li className="nav-item">
              <Link
                className={`nav-link ${location.pathname === '/admin/appearance-faq' || location.pathname === '/admin/admin-create' ? '' : 'collapsed'}`}
                data-bs-target="#faq"
                data-bs-toggle="collapse"
              >
                <span>FAQ</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="faq"
                className={`nav-content collapse ${location.pathname === '/admin/appearance-faq' || location.pathname === '/admin/appearance-faqlist' || location.pathname === '/admin/admin-create' ? 'show' : ''}`}
                data-bs-parent="">
                <li className={`${isActive('/admin/appearance-faq') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-faq">
                  Create
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-faqlist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-faqlist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>



            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/appearance-footer') ? '' : 'collapsed'}`}
                data-bs-target="#footers"
                data-bs-toggle="collapse"
              >
                <span>Footer</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="footers"
                className={`nav-content collapse ${isCollapse('/admin/appearance-footer') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/appearance-footer') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-footer">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/appearance-footerlist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/appearance-footerlist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>




        <li className="nav-item">
          <Link
            className={`nav-link ${isChatbotActive ? '' : 'collapsed'}`}
            data-bs-target="#ChatBot"
            data-bs-toggle="collapse"
          >
            <span>ChatBot</span>
            <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
          </Link>
          <ul
            id="ChatBot"
            className={`nav-content collapse ${isChatbotActive ? 'show' : ''}`}
            data-bs-parent="#sidebar-nav"
          >
            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/chatbot/') ? '' : 'collapsed'}`}
                data-bs-target="#slider"
                data-bs-toggle="collapse"
              >
                <span>Category</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="slider"
                className={`nav-content collapse ${isCollapse('/admin/chatbot/category') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/chatbot/category-list') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/chatbot/category-list">
                    <span>List</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/chatbot/category-create') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/chatbot/category-create">
                    <span>Create</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/chatbot/enquiry') ? '' : 'collapsed'}`}
                data-bs-target="#charbot_enquiry"
                data-bs-toggle="collapse"
              >
                <span>ChatBot Enquiry</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="charbot_enquiry"
                className={`nav-content collapse ${isCollapse('/admin/chatbot/enquiry-create') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/chatbot/enquiry-create') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/chatbot/enquiry-create">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/chatbot/enquirylist') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/chatbot/enquirylist">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>


            <li className="nav-item">
              <Link
                className={`nav-link ${isCollapse('/admin/chatbot/question') ? '' : 'collapsed'}`}
                data-bs-target="#qusetion"
                data-bs-toggle="collapse"
              >
                <span>Chatbot Qusetions and Answer</span>
                <FontAwesomeIcon icon={faAngleDown} className="ms-auto" />
              </Link>
              <ul
                id="qusetion"
                className={`nav-content collapse ${isCollapse('/admin/chatbot/question') ? 'show' : ''}`}
                data-bs-parent=""
              >
                <li className={`${isActive('/admin/chatbot/question') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/chatbot/question">
                    <span>Create</span>
                  </Link>
                </li>
                <li className={`${isActive('/admin/chatbot/questionall') ? 'sidebarActive' : ''}`}>
                  <Link to="/admin/chatbot/questionall">
                    <span>List</span>
                  </Link>
                </li>
              </ul>
            </li>


          



          </ul>
        </li>


        <li className="nav-heading">Settings</li>
        <li className="nav-item">
          <Link to="/admin/setting-list" className="nav-link">
            <span>Setting List</span>
          </Link>
        </li>
        <li className="nav-heading">About Us</li>
        <li className="nav-item">
          <Link to="/admin/app/about-us" className="nav-link">
            <span>App About Us</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
