import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  AdsDropDown,
  getSectionData,
  updateSection,
  ClassificationDropDown,
  CampaignDropDown
} from "../../../../service/apiService";
import Select from "react-select";
import { toast } from "react-toastify";
import ImageGallery from "../../../../components/Product/ImageGallery";
import VideoGallery from "../../../../components/Product/VideoGallery";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch"

const SectionEditModel = ({ isOpen, onRequestClose, sectionId, onSuccess, sectionGroupId, limit }) => {
  const [editFormData, setEditFormData] = useState({});
  console.log(editFormData)
  const [editAdsDropdown, setEditAdsDropdown] = useState([]);
  const [editSectionDropdownData, setEditSectionDropdownData] = useState([]);
  //single dropdown
  const [editSingleDropDown, setEditSingleDropDown] = useState([]);

  ///Image and vedio 
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [editSelectedImageMedia, setEditSelectedImageMedia] = useState([]);
  const [editSelectedVideoMedia, setEditSelectedVideoMedia] = useState([]);
  const [EditisImageGalleryOpen, setEditIsImageGalleryOpen] = useState(false);
  const [editCampaignData, seteditCampaignData] = useState([]);
  const [EditsVideoGalleryOpen, seteditIsVideoGalleryOpen] = useState(false);
  const [EditerrorMessage, setEditErrorMessage] = useState("");
  const [mapId, setMapId] = useState([]);
  const [section14ImgId, setSection14ImgId] = useState(null);
  const [sec14FormData, setSec14FormData] = useState([
    { "key_name": "TITLE_1", "key_value": "" },
    { "key_name": "IMG_1", "key_value": "" },
    { "key_name": "TITLE_2", "key_value": "" },
    { "key_name": "IMG_2", "key_value": "" },
    { "key_name": "TITLE_3", "key_value": "" },
    { "key_name": "IMG_3", "key_value": "" },
    { "key_name": "TITLE_4", "key_value": "" },
    { "key_name": "IMG_4", "key_value": "" }
  ]);

  const [Editsection23ImgId, setEditSection23ImgId] = useState(null);
  const [editsec23, seteditsec23] = useState([
    { "key_name": "LINK", "key_value": "" },
    { "key_name": "BG_IMG", "key_value": "" },
    { "key_name": "IMG", "key_value": "" },
  ])

  const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);

  const editHandleImageMediaSelect = (mediaUrls) => {
    const newSelectedMedia = [...editSelectedImageMedia, ...mediaUrls].slice(0, 12);
    setEditSelectedImageMedia(newSelectedMedia);
    setEditIsImageGalleryOpen(false);
  };
  const handleVideoMediaSelect = (mediaUrls) => {
    setEditSelectedVideoMedia(
      limit === 1 ? [mediaUrls[0]] : [...editSelectedVideoMedia, ...mediaUrls]
    );
    seteditIsVideoGalleryOpen(false);
  };
  const handleDeleteMedia = (type, index) => {
    if (type === "image") {
      setEditSelectedImageMedia(editSelectedImageMedia.filter((_, i) => i !== index));
    } else if (type === "video") {
      setEditSelectedVideoMedia(editSelectedVideoMedia.filter((_, i) => i !== index));
    }
  };

  //  { getting section number }
  const extractSectionNumber = (input) => {
    const match = input.match(/section(\d+)_/);
    if (match) {
      return parseInt(match[1], 10);
    }
    return null;
  }

  //section id 
  const SectionId_Number = extractSectionNumber(sectionId);

  const token = localStorage.getItem("admin_token");

  ///handlesubmit for 1,3,10
  const handleChange_1310 = async (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // {section 9}
  const Edit_section09_HandleChange = (selectedOptions) => {
    if (selectedOptions.length > 3) {
      setEditErrorMessage("Only  3 ads can be selected")
      return;
    } else {
      setEditErrorMessage("atleaste.target select 3 ads")
    }
    const selectedValue = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValue,
    }));
  }
  // {section 19}
  const Edit_section19_HandleChange = (selectedOptions) => {
    if (selectedOptions.length > 2) {
      setEditErrorMessage("Only  2 ads can be selected")
      return;
    } else {
      setEditErrorMessage("atleast select 2 ads")
    }
    const selectedValue = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValue,
    }));
  }
  const handle_Chnage14 = (selectedOptions) => {
    if (selectedOptions.length > 12) {
      setEditErrorMessage("Only  12 ads can be selected")
      return;
    } else {
      setEditErrorMessage("atleast select 12 ads")
    }
    const selectedValue = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValue,
    }));
  }
  // HandleChange
  const handleChange = (selectedOption) => {
    const selectedValues = selectedOption
      ? selectedOption.map((option) => option.value)
      : [];
    setEditFormData((prevData) => ({
      ...prevData,
      ADS: selectedValues,
    }));
  };


  console.log(editFormData.PRE_ORDER)
  if (editFormData.PRE_ORDER === 1 || editFormData.PRE_ORDER === "1") {
    console.log(true)
  } else {
    console.log(false)
  }

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  //handle change for section 5
  const handle_Chnage_05_Text_Field = (e) => {
    const { name, value } = e.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  // Fetching  dropdown data when the edit model is open 
  useEffect(() => {
    if (isOpen) {
      EditfetchAdsDropdown();
      editFetchAdsDropdownSingle();
      editFetchClassificationDropdown();
      EdicampaignDropdown();
    }
  }, [isOpen]);


  // {function to get the sectionMapId }
  const getSectionMapId = (response) => {
    const sectionsData = response.sections_data;
    if (sectionsData && sectionsData.length > 0) {
      return sectionsData;
    }
    return null;
  }
  const clearData = () => {
    setEditSelectedImageMedia([])
    setEditSelectedVideoMedia([])
    setEditFormData({})
  }
  // Api Called to fill the data In the sections
  useEffect(() => {
    if (SectionId_Number) {
      const fetchSectionDetails = async () => {
        const response = await getSectionData(token, sectionGroupId);

        if (response.data) {
          const data = response.data;
          console.log(data, SectionId_Number)
          const transform = getSectionMapId(data)
          setMapId(transform)
          const ads = data.sections_data?.length ? data.sections_data : [];
          // All data
          let obj = {}
          let obj_section_14 = []
          if (SectionId_Number === 14 || SectionId_Number === 23) {
            obj_section_14 = ads?.filter((item) => {
              let obj = {}
              obj["key_name"] = item?.key_name;
              obj["key_value"] = item?.key_value;
              return obj;
            })
          } else {
            ads?.map((item) => {
              if (item?.key_name === "File" && item?.key_value) {
                let arr = item?.key_value?.split(",")
                if (arr[0]?.includes('jpg')) {
                  setEditSelectedImageMedia(arr)
                }
                if (arr[0]?.includes('mp4')) {
                  setEditSelectedVideoMedia(arr)
                }
              }
              obj[item?.key_name === "CLASSIFI" ? "CLASSIFICATION" : item?.key_name] = item?.key_value
            })
          }
          console.log('setting the data', obj);
          if (SectionId_Number === 14) {
            setSec14FormData([...obj_section_14])
          }
          if (SectionId_Number === 23) {
            seteditsec23([...obj_section_14])
          }
          setEditFormData({
            ...editFormData,
            ...obj
          });
        }
      };
      fetchSectionDetails();
    }
  }, [SectionId_Number, isOpen]);

  console.log(sec14FormData)
  const returnSec_14_payload = (key) => {
    let obj = sec14FormData?.find((item) => item?.key_name === key);
    return obj?.key_value
  }
  const returnSec_23_payload = (key) => {
    let obj = editsec23?.find((item) => item?.key_name === key);
    return obj?.key_value
  }

  // const returnSec_22_payload = (key) => {
  //   let obj = editFormData?.find((item) => item?.key_name === key);
  //   return obj?.key_value
  // }



  const section22Payload = (key) => {
    console.log("Key to match:", key);

    if (editFormData.hasOwnProperty(key)) {
      const value = editFormData[key];
      console.log("Matched value:", value);
      return value;
    } else {
      console.log("Key not found.");
      return null;
    }
  };

  const section06Payload = (key) => {
    if (key === "CLASSIFI") {
      return `${editFormData["CLASSIFICATION"]}`
    } else if (key === "File") {
      return key === "File" ? editSelectedImageMedia?.join(",") : editSelectedVideoMedia?.join(",")
    } else if (key === "TITLE") {
      return editFormData[key]
    }
  }



  // Dynamic PlayLoad for sectins 
  const createPayload = (id, key) => {
    console.log(id, key)
    switch (id) {
      case 1:
      case 3:
      case 10:
      case 12:
      case 16:
        return editFormData[key];
      case 8:
        return key === "CAMP" ? editFormData[key].toString() : editFormData[key];
      case 2:
        return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      case 15:
        return key === "PRE_ORDER" ? editFormData[key].toString() : null
      case 9:
        return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      case 19:
        return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      case 5:
        return key === 'ADS' ? editFormData[key].join(",") : editFormData[key]
      case 7:
      case 6:
        return section06Payload(key);
      // return key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : key === "File" ? editSelectedImageMedia?.join(",") : editSelectedVideoMedia?.join(",")
      case 4:
        return key === "TITLE" ? editFormData[key] : typeof (editFormData[key]) === 'string' ? editFormData[key] : editFormData[key]?.join(",");
      case 11:
        return key === 'File' ? editSelectedImageMedia?.join(",") : editFormData[key];
      case 13:
        return key === "CLASSIFI" ? `${editFormData["CLASSIFICATION"]}` : editFormData[key];
      case 18:
        return key === 'ADS' ? editFormData[key].join(",") : key === "TITLE" ? editFormData[key] : key === "File" ? editSelectedImageMedia?.join(",") : editSelectedVideoMedia?.join(",");
      case 14:
        return returnSec_14_payload(key);
      case 20:
        return `${editFormData[key]}`;
      case 21:
        return `${editFormData[key]}`
      case 22:
        return section22Payload(key)
      case 23:
        return returnSec_23_payload(key);
      default:
        return '';
    }
  };
  const extractSectionMapId = (key) => {
    let keyName = key;
    if (key === "File_Video") {
      keyName = "File"
    }
    let obj = mapId?.find(item => {
      console.log(item?.key_name, keyName)
      if (item?.key_name === keyName) return item;
    })
    return obj ? obj?.section_map_id : -1
  }

  // Api  UpdateSection
  const handleEditSectionSubmit = async (e, key) => {
    e.preventDefault();
    try {
      let payload = {
        section_group: sectionGroupId,
        section_map_id: extractSectionMapId(key),
        key_value: createPayload(SectionId_Number, key),
        // is_active: onSuccess
      };
      console.log("payload", payload);
      const res = await updateSection(token, payload);
      if (res) {
        console.log(res);
        toast.success("Section updated successfully!");
        clearData();
        onRequestClose();
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Failed to update section.");
    }
  };


  // API fetched  DATA FOR THE DROPDOWN
  const EditfetchAdsDropdown = async () => {
    const response = await AdsDropDown(token);
    setEditAdsDropdown(
      response.data.map((option) => ({
        value: option.id,
        label: option.name.toUpperCase(),
      }))
    );
  };



  const EdicampaignDropdown = async () => {
    const response = await CampaignDropDown(token);
    seteditCampaignData(
      response.data.map((option) => ({
        value: option.campaign_id,
        label: option.campaign_name.toUpperCase(),
      }))
    );
  };


  //API fetched for single dropdown data 
  const editFetchAdsDropdownSingle = async () => {
    const response = await AdsDropDown(token);
    setEditSingleDropDown(response.data);
  };

  // Api fetched for classifications
  const editFetchClassificationDropdown = async () => {
    const classifications = await ClassificationDropDown(token);
    console.log(classifications?.data)
    setEditSectionDropdownData(
      classifications.data.map((option) => ({
        value: option.classification_id,
        label: option.classification_name.toUpperCase(),
      }))
    );
  };

  const sec14_ImageGalleryOpen = (rowIndex) => {
    setSection14ImgId(rowIndex);
    setIsImageGalleryOpen(true);
    setEditIsImageGalleryOpen(true);
  };

  const sec14HandleOnChange = (event, index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: event.target.value
    };
    setSec14FormData(updatedFormData);
  };

  const sec14_ImageGalleryHandle = (mediaUrl) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[section14ImgId] = {
      ...updatedFormData[section14ImgId],
      key_value: mediaUrl
    };
    setSec14FormData(updatedFormData);
  };
  const sec14_handleDeleteMedia = (index) => {
    const updatedFormData = [...sec14FormData];
    updatedFormData[index].key_value = "";
    setSec14FormData(updatedFormData);
  };



  // {  sections23}

  const sec23_Edit_ImageGalleryOpen = (rowIndex, keyName) => {
    setEditSection23ImgId({ rowIndex, keyName });
    // setIsImageGalleryOpen(true);
    setEditIsImageGalleryOpen(true);
  };

  const sec23_ImageGalleryHandle = (mediaUrl) => {
    const { keyName } = Editsection23ImgId;
    const updatedFormData = [...editsec23];
    const index = updatedFormData.findIndex((item) => item.key_name === keyName);
    updatedFormData[index] = {
      ...updatedFormData[index],
      key_value: mediaUrl
    };
    seteditsec23(updatedFormData);
  };

  const sec23HandleOnchange = (event) => {
    const { name, value } = event.target;
    seteditsec23((prevFormData) =>
      prevFormData.map((item) =>
        item.key_name === name ? { ...item, key_value: value } : item
      )
    );
  };

  const sec23_handleDeleteMedia = (index) => {
    const updatedFormData = [...editsec23];
    updatedFormData[index].key_value = "";
    seteditsec23(updatedFormData);
  };



  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Edit Section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "15px",
            padding: "20px",
            maxWidth: "1500px",
            minWidth: "1500px",
            maxHeight: "700px",
            minHeight: "800px",
            overflowY: "auto",
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)",
            border: "2px solid #63E6BE",
          },
        }}
      >
        <div className="modal-header">
          <h2>Edit Section</h2>
          <button
            onClick={() => {
              onRequestClose();
              clearData();
            }}
          >
            Close
          </button>
        </div>
        {/* Section Input fields */}
        <form>
          {EditerrorMessage && <div className="error-message">{EditerrorMessage}</div>}
          {/* section 1,3,10 */}
          {[1, 3, 10].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <select
                  id="adsDropdown2"
                  name="ADS"
                  value={editFormData.ADS || ""}
                  onChange={handleChange_1310}
                  className="dropdown"
                >
                  <option value="">Select an Ads</option>
                  {editSingleDropDown.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name.toUpperCase()}
                    </option>
                  ))}
                </select>
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* section 2 */}
          {[2].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <div>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* {section 9} */}
          {[9].includes(SectionId_Number) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown1">Ads Dropdown</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS ?
                      editAdsDropdown.filter((option) => editFormData.ADS.includes(option.value)) : []
                  }
                  onChange={Edit_section09_HandleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Save Changes
                </button>
              </div>
            </div>
          )}
          {[19].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS ?
                      editAdsDropdown.filter((option) => editFormData.ADS.includes(option.value)) : []
                  }
                  onChange={Edit_section19_HandleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Save Changes
                </button>
              </div>
            </div>
          )}
          {[4].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handle_Chnage14}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[5].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <label htmlFor="editAdsDropdown">Ads</label>
              <Select
                id="editAdsDropdown"
                name="ADS"
                value={
                  editFormData.ADS
                    ? editAdsDropdown.filter((option) =>
                      editFormData.ADS.includes(option.value)
                    )
                    : []
                }
                onChange={(selectedOption) => {
                  if (selectedOption.length <= 15) {
                    handleChange(selectedOption);
                    setEditErrorMessage("");
                  } else {
                    setEditErrorMessage("You can select a maximum of 15 ads.");
                  }
                }}
                options={editAdsDropdown}
                isMulti
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>
            </div>
          )}
          {[6].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              {limit === 0 ? (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setEditIsImageGalleryOpen(true)}
                  >
                    Image Gallery
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => seteditIsVideoGalleryOpen(true)}
                  >
                    Video Gallery
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                    }
                    disabled={editSelectedImageMedia.length > 0}
                  >
                    Image Gallery
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      seteditIsVideoGalleryOpen(editSelectedVideoMedia.length === 0)
                    }
                    disabled={editSelectedVideoMedia.length > 0}
                  >
                    Video Gallery
                  </button>
                </>
              )}
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={editSectionDropdownData.filter(
                  (option) => option?.value === parseInt(editFormData.CLASSIFICATION)
                )}
                onChange={(selectedOption) =>
                  setEditFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={editSectionDropdownData}
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                Edit
              </button>
            </div>
          )}
          {[7].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
                <Select
                  id="ClassificationDropdown"
                  name="CLASSIFI"
                  value={editSectionDropdownData.filter(
                    (option) => option.value === parseInt(editFormData.CLASSIFICATION)
                  )}
                  //onChange={handle_Chnage07}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CLASSIFICATION: selectedOption.value,
                    }))
                  }
                  options={editSectionDropdownData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[12].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <label htmlFor="editAdsDropdown">Ads</label>
              <div>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
              </div>

              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title 1: </label>
                <input
                  id="titleInput1"
                  type="text"
                  name="TITLE_1"
                  value={editFormData.TITLE_1}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 1"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                  Edit
                </button>
              </div>
              <div className="input-container form-group">
                <label htmlFor="titleInput2">Title 2: </label>
                <input
                  id="titleInput2"
                  type="text"
                  name="TITLE_2"
                  value={editFormData.TITLE_2}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title 2"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                  Edit
                </button>

              </div>
            </div>
          )}
          {[13].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <label htmlFor="ClassificationDropdown">CLASSIFICATION</label>
              <Select
                id="ClassificationDropdown"
                name="CLASSIFI"
                value={editSectionDropdownData.filter(
                  (option) => option.value === parseInt(editFormData.CLASSIFICATION)
                )}
                onChange={(selectedOption) =>
                  setEditFormData((prevData) => ({
                    ...prevData,
                    CLASSIFICATION: selectedOption.value,
                  }))
                }
                options={editSectionDropdownData}
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "CLASSIFI")}>
                Edit
              </button>
            </div>
          )}
          {[16].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handleUpdateChange}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <label htmlFor="editAdsDropdown">Ads</label>
              <select
                id="adsDropdown2"
                name="ADS"
                value={editFormData.ADS || ""}
                onChange={handleUpdateChange}
                className="dropdown"
              >
                <option value="">Select an Ads</option>
                {editSingleDropDown.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name.toUpperCase()}
                  </option>
                ))}
              </select>
              <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                Edit
              </button>
            </div>
          )}
          {[18].includes(parseInt(SectionId_Number)) && (
            <>
              <div className="dropdown-container">
                {limit === 0 ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() => setEditIsImageGalleryOpen(true)}
                    >
                      Image Gallery
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() => seteditIsVideoGalleryOpen(true)}
                    >
                      Video Gallery
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() =>
                        setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                      }
                      disabled={editSelectedImageMedia.length > 0}
                    >
                      Image Gallery
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={() =>
                        seteditIsVideoGalleryOpen(editSelectedVideoMedia.length === 0)
                      }
                      disabled={editSelectedVideoMedia.length > 0}
                    >
                      Video Gallery
                    </button>
                  </>
                )}
              </div>

              <div className="dropdown-container">
                {EditerrorMessage.length > 0 ? (
                  <div className="Error-Msg">{EditerrorMessage}</div>
                ) : (
                  <p className="DeFault-Msg"> Select an Ad</p>
                )}
                <label htmlFor="editAdsDropdown">Ads</label>
                <Select
                  id="editAdsDropdown"
                  name="ADS"
                  value={
                    editFormData.ADS
                      ? editAdsDropdown.filter((option) =>
                        editFormData.ADS.includes(option.value)
                      )
                      : []
                  }
                  onChange={handleChange}
                  options={editAdsDropdown}
                  isMulti
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                  Edit
                </button>
                <div>
                  <label htmlFor="editAdsDropdown">Text Feild</label>
                  <input
                    id="titleInput"
                    type="text"
                    name="TITLE"
                    value={editFormData.TITLE}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                    Edit
                  </button>
                </div>
              </div>
            </>
          )}
          {/* *********************** */}
          {[11].includes(parseInt(SectionId_Number)) && (
            <div className="conditional-container">
              <div className="dropdown-container">
                <div className="input-container form-group">
                  <label htmlFor="titleInput1">Title 1: </label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE_1"
                    value={editFormData.TITLE_1}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title 1"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                    Edit
                  </button>
                </div>
                <div className="input-container form-group">
                  <label htmlFor="titleInput2">Title 2: </label>
                  <input
                    id="titleInput2"
                    type="text"
                    name="TITLE_2"
                    value={editFormData.TITLE_2}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title 2"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                    Edit
                  </button>
                </div>
              </div>
              <div className="image-gallery-container">

                {limit === 0 ? (
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => setEditIsImageGalleryOpen(true)}
                  >
                    Image Gallery
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() =>
                      setEditIsImageGalleryOpen(editSelectedImageMedia.length === 0)
                    }
                    disabled={editSelectedImageMedia.length > 0}
                  >
                    Image Gallery
                  </button>
                )}
              </div>
            </div>
          )}
          {[14].includes(parseInt(SectionId_Number)) && (
            <div style={{ paddingBottom: '20px' }}>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput1`}>Title 1</label>
                    <input
                      id={`titleInput1`}
                      type="text"
                      name={sec14FormData[0].key_name}
                      value={sec14FormData[0].key_value}
                      onChange={(event) => sec14HandleOnChange(event, 0)}
                      className="form-control"
                      placeholder={`Enter title 1`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[0].key_name)}>
                      Edit
                    </button>
                  </div>
                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(1)}
                  >
                    Image Gallery 1
                  </button>
                </div>
                {sec14FormData[1].key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[1].key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(1)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[1].key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput2`}>Title 2</label>
                    <input
                      id={`titleInput2`}
                      type="text"
                      name={sec14FormData[2]?.key_name}
                      value={sec14FormData[2]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 2)}
                      className="form-control"
                      placeholder={`Enter title 2`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[2].key_name)}>
                      Edit
                    </button>
                  </div>
                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(3)}
                  >
                    Image Gallery 2
                  </button>
                </div>
                {sec14FormData[3]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[3]?.key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(3)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[3].key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput3`}>Title 3</label>
                    <input
                      id={`titleInput3`}
                      type="text"
                      name={sec14FormData[4]?.key_name}
                      value={sec14FormData[4]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 4)}
                      className="form-control"
                      placeholder={`Enter title 3`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[4].key_name)}>
                      Edit
                    </button>
                  </div>
                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(5)}
                  >
                    Image Gallery 3
                  </button>
                </div>
                {sec14FormData[5]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[5].key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(5)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[5].key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="conditional-container" style={{ marginBottom: '10px' }}>
                <div className="dropdown-container">
                  <div className="input-container form-group">
                    <label htmlFor={`titleInput4`}>Title 4</label>
                    <input
                      id={`titleInput4`}
                      type="text"
                      name={sec14FormData[6]?.key_name}
                      value={sec14FormData[6]?.key_value}
                      onChange={(event) => sec14HandleOnChange(event, 6)}
                      className="form-control"
                      placeholder={`Enter title 4`}
                    />
                    <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[6].key_name)}>
                      Edit
                    </button>
                  </div>
                </div>
                <div className="image-gallery-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec14_ImageGalleryOpen(7)}
                  >
                    Image Gallery 4
                  </button>
                </div>
                {sec14FormData[7]?.key_value && (
                  <div className="media-preview">
                    <div className="media-items">
                      <div className="media-item">
                        <img
                          src={AppUrl + sec14FormData[7].key_value}
                          alt="Selected"
                          style={{ width: '150px', height: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          style={{ marginLeft: '30px' }}
                          onClick={() => sec14_handleDeleteMedia(7)}
                        >
                          Delete
                        </button>
                        <button onClick={(e) => handleEditSectionSubmit(e, sec14FormData[7].key_name)}>
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {[15].includes(parseInt(SectionId_Number)) && (
            <div className="dropdown-container">
              <label htmlFor="adsDropdown2">PreOrder</label>
              <ToggleSwitch

                checked={editFormData.PRE_ORDER == "1"}
                onChange={(e) =>
                  setEditFormData((prevData) => ({
                    ...prevData,
                    PRE_ORDER: e.target.checked ? 1 : 0,
                  }))
                }
              />
              <button onClick={(e) => handleEditSectionSubmit(e, "PRE_ORDER")}>
                Edit
              </button>
            </div>
          )}
          {/* section 8 */}
          {[8].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="Campaign">CAMPAIGN</label>
                <Select
                  id="CampaignDropdown"
                  name="CAMP"
                  value={editCampaignData.filter(
                    (option) => option.value === parseInt(editFormData.CAMP)
                  )}
                  onChange={(selectedOption) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      CAMP: selectedOption.value,
                    }))
                  }
                  options={editCampaignData}
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "CAMP")}>
                  Edit
                </button>
              </div>
            </div>
          )}
          {[20].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput">Title: </label>
                <input
                  id="titleInput"
                  type="text"
                  name="TITLE"
                  value={editFormData.TITLE}
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Active Spinner:</label>
                <ToggleSwitch
                  checked={Number(editFormData.SPIN) === 1}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      SPIN: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "SPIN")}>
                  Edit
                </button>
              </div>
            </div>
          )
          }



{[17].includes(parseInt(SectionId_Number)) && (
            <div>
             
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Active Notification:</label>
                <ToggleSwitch
                  checked={Number(editFormData.Notification) === 1}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      Notification: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "Notification")}>
                  Edit
                </button>
              </div>
            </div>
          )
          }




          {/* section 21 */}
          {[21].includes(parseInt(SectionId_Number)) && (
            <div>
              <div className="input-container form-group">
                <label htmlFor="titleInput1">Title: </label>
                <input
                  id="titleInput1"
                  type="text"
                  value={editFormData?.TITLE}
                  name="TITLE"
                  onChange={handle_Chnage_05_Text_Field}
                  className="form-control"
                  placeholder="Enter title 1"
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "TITLE")}>
                  Edit Title
                </button>
              </div>
              <div className="dropdown-container">
                <label htmlFor="adsDropdown2">Active Blogs:</label>
                <ToggleSwitch
                  checked={Number(editFormData.BLOG == 1)}
                  onChange={(e) =>
                    setEditFormData((prevData) => ({
                      ...prevData,
                      BLOG: e.target.checked ? 1 : 0,
                    }))
                  }
                />
                <button onClick={(e) => handleEditSectionSubmit(e, "BLOG")}>
                  Edit Toggle
                </button>
              </div>
            </div>
          )
          }
          {/* section 22 */}

          {
            [22].includes(parseInt(SectionId_Number)) && (
              <div className="dropdown-container">
                <div>
                  <label htmlFor="titleInput">Title 1</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE_1"
                    value={editFormData?.TITLE_1}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_1")}>
                    Edit Title
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Description 1</label>
                  <textarea
                    id="titleInput2"
                    name="DEC_1"
                    value={editFormData?.DEC_1}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DEC_1")}>
                    Edit Description
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Title 2</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE_2"
                    value={editFormData?.TITLE_2}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_2")}>
                    Edit Title
                  </button>
                </div>

                <div>

                  <label htmlFor="titleInput">Description 2</label>
                  <textarea
                    id="titleInput2"
                    name="DEC_2"
                    onChange={handleUpdateChange}
                    value={editFormData?.DEC_2}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DEC_2")}>
                    Edit Description
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Title 3</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE_3"
                    value={editFormData?.TITLE_3}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_3")}>
                    Edit Title
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Description 3</label>
                  <textarea
                    id="titleInput2"
                    name="DEC_3"
                    value={editFormData?.DEC_3}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DEC_3")}>
                    Edit Description
                  </button>
                </div>


                <div>
                  <label htmlFor="titleInput">Title 4</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE_4"
                    value={editFormData?.TITLE_4}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_4")}>
                    Edit Title
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Description 4</label>
                  <textarea
                    id="titleInput2"
                    name="DEC_4"
                    value={editFormData?.DEC_4}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "DEC_4")}>
                    Edit Description
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Title 5</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="TITLE_5"
                    value={editFormData?.TITLE_5}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_5")}>
                    Edit Title
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Description 5</label>
                  <textarea
                    id="titleInput2"
                    name="DEC_5"
                    value={editFormData?.DEC_5}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter another title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "TITLE_5")}>
                    Edit Description
                  </button>
                </div>

                <div>
                  <label htmlFor="adsDropdown">Ads</label>
                  <select
                    id="adsDropdown2"
                    name="ADS"
                    value={editFormData.ADS || ""}
                    onChange={handleUpdateChange}
                    className="dropdown"
                  >
                    <option value="">Select an Ads</option>
                    {editSingleDropDown.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name.toUpperCase()}
                      </option>
                    ))}
                  </select>
                  <button onClick={(e) => handleEditSectionSubmit(e, "ADS")}>
                    Edit Ads
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Link</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="LINK"
                    value={editFormData?.LINK}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "LINK")}>
                    Edit Link
                  </button>
                </div>

                <div>
                  <label htmlFor="titleInput">Button Name</label>
                  <input
                    id="titleInput1"
                    type="text"
                    name="BUTTON_NAME"
                    value={editFormData?.BUTTON_NAME}
                    onChange={handleUpdateChange}
                    className="form-control"
                    placeholder="Enter title here"
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, "BUTTON_NAME")}>
                    Edit button
                  </button>
                </div>
              </div>
            )
          }
          {/* section 23 */}
          {
            [23].includes(parseInt(SectionId_Number)) && (
              <div>
                <div className="input-container form-group">
                  <label htmlFor="titleInput">Link:</label>
                  <input
                    id="titleInput"
                    type="text"
                    name={editsec23[0]?.key_name}
                    value={editsec23[0]?.key_value}
                    onChange={(e) => sec23HandleOnchange(e, editsec23[0]?.key_name)}
                    className="form-control"
                    placeholder="Enter Link ...."
                  />
                  <button onClick={(e) => handleEditSectionSubmit(e, editsec23[0]?.key_name)}>
                    Edit
                  </button>
                </div>

                {/* BG_IMG */}
                <div className="dropdown-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec23_Edit_ImageGalleryOpen(1, "BG_IMG")}
                  >
                    Image Gallery - BG_IMG
                  </button>
                  {editsec23[1].key_value && (
                    <div className="media-preview">
                      <div className="media-items">
                        <div className="media-item">
                          <img
                            src={AppUrl + editsec23[1].key_value}
                            alt="Selected"
                            style={{ width: '150px', height: '200px' }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginLeft: '30px' }}
                            onClick={() => sec23_handleDeleteMedia(1)}
                          >
                            Delete
                          </button>
                          <button onClick={(e) => handleEditSectionSubmit(e, editsec23[1].key_name)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* IMG */}
                <div className="dropdown-container">
                  <button
                    type="button"
                    className="btn btn-secondary mb-3"
                    onClick={() => sec23_Edit_ImageGalleryOpen(2, "IMG")}
                  >
                    Image Gallery - IMG
                  </button>
                  {editsec23[2]?.key_value && (
                    <div className="media-preview">
                      <div className="media-items">
                        <div className="media-item">
                          <img
                            src={AppUrl + editsec23[2].key_value}
                            alt="Selected"
                            style={{ width: '150px', height: '200px' }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger"
                            style={{ marginLeft: '30px' }}
                            onClick={() => sec23_handleDeleteMedia(2)}
                          >
                            Delete
                          </button>
                          <button onClick={(e) => handleEditSectionSubmit(e, editsec23[2].key_name)}>
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          }
          <div className="media-preview-container">
            {editSelectedImageMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Images</h3>
                <div className="media-items">
                  {editSelectedImageMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <img
                        src={AppUrl + mediaUrl}
                        alt="Selected"
                        width={"250px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("image", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
            {editSelectedVideoMedia.length > 0 && (
              <div className="media-preview">
                <h3>Selected Videos</h3>
                <div className="media-items">
                  {editSelectedVideoMedia.map((mediaUrl, index) => (
                    <div key={index} className="media-item">
                      <video
                        src={AppUrl + mediaUrl}
                        controls
                        width={"250px"}
                        height={"200px"}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDeleteMedia("video", index)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                  <button onClick={(e) => handleEditSectionSubmit(e, "File_Video")}>
                    Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
        <ImageGallery
          isOpen={EditisImageGalleryOpen}
          onRequestClose={() => setEditIsImageGalleryOpen(false)}
          onMediaSelect={editHandleImageMediaSelect}
          selectedMedia={editSelectedImageMedia}
          setSelectedMedia={setEditSelectedImageMedia}
          limit={3}
          sectionId={sectionId}
          sec14_ImageGalleryHandle={sec14_ImageGalleryHandle}
          sec23_ImageGalleryHandle={sec23_ImageGalleryHandle}
          SectionId_Number={SectionId_Number}
        />
        <VideoGallery
          isOpen={EditsVideoGalleryOpen}
          onRequestClose={() => seteditIsVideoGalleryOpen(false)}
          onMediaSelect={handleVideoMediaSelect}
          selectedMedia={editSelectedVideoMedia}
          setSelectedMedia={setEditSelectedVideoMedia}
          limit={limit}
        />
      </Modal>
    </>
  );
};
export default SectionEditModel;






