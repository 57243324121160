import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../../components/DataTable";
import { InputText } from "primereact/inputtext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStreetView,
  faCircleXmark,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CommonTextarea from "../../../../components/CommonTextarea";
import Input from "../../../../components/Input";
import Inputcheckbox from "../../../../components/Inputcheckbox";
import UploadImage from "../../../../assets/images/defimg.jpeg";
import { MediaList } from "../../../../service/apiService";
import SingleImageSelect from "../../../../components/Media/SingleImageSelect";

import Modal from "react-modal";
import noImage from "../../../../assets/images/noimage.jpg";

import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BannerList = () => {
  const navigate = useNavigate();
  const [BannerTypeList, setBannerTypeList] = useState([]);

  const [BannerList, SetBannerList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [selectedColor, setSelectedColor] = useState("#000");

  const [showTrashIcon, setShowTrashIcon] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpens, setModalIsOpens] = useState(false);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const ImageAppUrl = "https://folcon.elegra.in/api/files/";
  const [selectedBannerId, setSelectedBrannerId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages_two, setSelectedImages_two] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [BannerImage, setBannerImage] = useState(null);
  const [BannerImages, setBannerImages] = useState(null);
  const [deletedImage, setDeletedImage] = useState(false);
  const [deletedImages, setDeletedImages] = useState(false);
  const [imag, setImag] = useState(null);
  const [imags, setImags] = useState(null);

  const [BannerformData, setBannerformData] = useState({
    banner_type: "",
    banner_name: "",
    title: "",
    description: "",
    link_one: "",
    link_two: "",
    high_lighted: "",
    image_path: "",
    responsive_image_path: "",
    background_color: "",
    is_responsive_image: 1,
    place_order: "",
    is_active: 1,
  });

  const pageCount = Math.ceil(BannerList.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const currentBlogList = BannerList.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBannerformData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    setBannerformData({
      ...BannerformData,
      is_responsive_image: isChecked ? 1 : 0,
      is_active: isChecked ? 1 : 0,
    });
  };

  const handleInputChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const handleDeletePreviews = () => {
    setPreviewImages(null);
    setSelectedImages_two([]);
  };

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter((i) => i !== index));
      }
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setBannerImage(imageUrl[index]);
      closeModal();
    }
  };

  const handleImageClicks = (index) => {
    if (selectedImages_two.includes(index)) {
      if (selectedImages_two.length > 0) {
        setSelectedImages_two(selectedImages_two.filter((i) => i !== index));
      }
    } else {
      setSelectedImages_two([index]);
      setPreviewImages(AppUrl + imageUrls[index]);
      setBannerImages(imageUrls[index]);
      closeModals();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length > 0){
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      //}
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length > 0){
      response.then((response) => {
        const imageUrlss = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrls(imageUrlss);
      });
      //}
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };

      const response = await fetch(
        "https://folcon.elegra.in/admin/all-banners",
        config
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();

      let BannerData = [];

      if (Array.isArray(responseData)) {
        BannerData = responseData;
      } else if (Array.isArray(responseData.data)) {
        BannerData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      SetBannerList(BannerData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEdit = async (BannerId) => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const response = await axios.get(
        `https://folcon.elegra.in/admin/get-banner/${BannerId}`,
        config
      );
      const bannerData = response.data;
      setBannerformData({
        banner_type_id: bannerData.data.banner_type,
        banner_name: bannerData.data.banner_name,
        title: bannerData.data.title,
        description: bannerData.data.description,
        link_one: bannerData.data.link_one,
        link_two: bannerData.data.link_two,
        high_lighted: bannerData.data.high_lighted,
        image_path: bannerData.data.image_path,
        responsive_image_path: bannerData.data.responsive_image_path,
        background_color: bannerData.data.background_color,
        is_responsive_image: bannerData.data.is_responsive_image,
        place_order: bannerData.data.place_order,
        is_active: bannerData.data.is_active,
      });
      setSelectedBrannerId(BannerId);
      const previewImageUrl = bannerData.data.image_path
        ? ImageAppUrl.concat(bannerData.data.image_path)
        : null;
      setPreviewImage(previewImageUrl);
      setImag(bannerData.data.responsive_image_path);
      const previewImageUrls = bannerData.data.responsive_image_path
        ? ImageAppUrl.concat(bannerData.data.responsive_image_path)
        : null;
      setPreviewImages(previewImageUrls);
      setImag(bannerData.data.responsive_image_path);
      setEditModalIsOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchBannerTypes = async () => {
      try {
        const token = localStorage.getItem("admin_token");
        const csrfToken = localStorage.getItem("csrfToken");

        const config = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
        };

        const response = await fetch(
          "https://folcon.elegra.in/admin/banner-type-dropdown",
          config
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();

        let bannertypeData = [];

        if (Array.isArray(responseData)) {
          bannertypeData = responseData;
        } else if (Array.isArray(responseData.data)) {
          bannertypeData = responseData.data;
        } else {
          throw new Error("Data is not in the expected format");
        }

        setBannerTypeList(bannertypeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBannerTypes();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(BannerformData.banner_type_id);
    const token = localStorage.getItem("admin_token");
    const csrfToken = localStorage.getItem("csrfToken");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-CSRF-Token": csrfToken,
      },
    };

    try {
      if (!BannerformData.banner_name || !BannerformData.title) {
        toast.error("Please Fill The Form Data");
        return;
      }

      const banner_type = parseInt(BannerformData.banner_type);
      const place_order = parseInt(BannerformData.place_order);

      const createPayload = {
        ...BannerformData,
        image_path: BannerImage,
        responsive_image_path: BannerImages,
        banner_name: BannerformData.banner_name,
        banner_type: banner_type,
        place_order: place_order,
        banner_id: selectedBannerId,
      };
      // console.log(createPayload);

      const newImageUrl = BannerImage ? BannerImage : BannerformData.image_path;
      createPayload.image_path = newImageUrl;
      if (deletedImage === true) {
        createPayload.image_path = null;
      }

      const newImageUrls = BannerImages
        ? BannerImages
        : BannerformData.responsive_image_path;
      createPayload.responsive_image_path = newImageUrls;
      if (deletedImages === true) {
        createPayload.responsive_image_path = null;
      }

      // console.log("createPayload", createPayload);
      const createResponse = await axios.post(
        "https://folcon.elegra.in/admin/update-banner",
        createPayload,
        config
      );

      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setSelectedFile(null);
        toast.success("banner Updated successfully!");
        fetchData();
        setBannerformData({
          banner_type_id: "",
          banner_name: "",
          title: "",
          description: "",
          link_one: "",
          link_two: "",
          high_lighted: "",
          image_path: "",
          responsive_image_path: "",
          background_color: "",
          is_responsive_image: 1,
          place_order: "",
          is_active: 1,
        });
      } else {
        toast.error("Banner creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error creating banner:", error);
      toast.error("Banner creation failed. Please try again.");
    }
    closeEditModal();
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const openModals = () => setModalIsOpens(true);
  const closeModals = () => setModalIsOpens(false);

  const header = (
    <div className="table-header">
      <div>
        <h3>Banner List</h3>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={globalFilter}
              onInput={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
      <div className="create-category-button">
        <button
          onClick={() => navigate("/admin/banner-create")}
          className="btn btn-primary"
        >
          Create Banner
        </button>
      </div>
    </div>
  );

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  const openEditModal = () => {
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false);
  };

  const columns = [
    { field: "id", header: "S.No" },
    { field: "banner_name", header: " Banner Name" },
    { field: "title", header: " Banner Title" },
    {
      field: "image_path",
      header: "Image",
      body: (rowData) => {
        if (rowData.image_path) {
          return (
            <img
              src={ImageAppUrl + rowData.image_path}
              width={100}
              height={100}
              alt="ads"
            />
          );
        } else {
          return <img src={noImage} width={100} height={100} alt="No Image" />;
        }
      },
    },
    {
      field: "is_active",
      header: "Status",
      body: (rowData) => (
        <span
          className={
            rowData.is_active === 1 ? "active-status" : "inactive-status"
          }
        >
          {rowData.is_active === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      field: "action",
      header: "Action",
      body: (rowData) => (
        <div className="text-end">
          <div className="dropdown">
            <a
              href="#"
              data-bs-toggle="dropdown"
              className="btn btn-light rounded btn-sm font-sm"
            >
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <div className="dropdown-menu">
              <a
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={() => handleEdit(rowData.id)}
              >
                Edit info
              </a>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Banner List</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashbaord">Dashbord</a>
            </li>
            <li className="breadcrumb-item">Banner</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <ReactDataTable
          data={currentBlogList}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          header={header}
          emptyMessage="No banner records found"
          columns={columns}
        />
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </section>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Banner Info"
        style={{
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
          content: {
            position: "relative",
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            // maxWidth: '80%',
            maxHeight: "100%",
            overflowY: "auto",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <div className="container" style={{ width: "900px" }}>
          <div className="row">
            <div className="col">
              <h2 style={{ display: "inline-block", marginRight: "10px" }}>
                Edit Banner info
              </h2>
            </div>
            <div className="col-auto"></div>
            <button
              onClick={closeEditModal}
              style={{
                fontSize: "30px",
                marginTop: "0",
                display: "flex",
                justifyContent: "flex-end",
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ color: "#63E6BE" }}
              />
            </button>
            <form className="row g-3" onSubmit={handleSubmit}>
              <div className="col-md-6">
                <label
                  htmlFor="banner_type_id"
                  className="col-sm-2 col-form-label"
                >
                  Banner Type
                </label>
                <div className="col-sm-8">
                  <select
                    type="text"
                    className="form-select"
                    name="banner_type_id"
                    id="banner_type_id"
                    value={BannerformData.banner_type}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select Banner Type
                    </option>
                    {BannerTypeList.map((bannertype) => (
                      <option
                        key={bannertype.banner_type}
                        value={bannertype.banner_type}
                      >
                        {bannertype.banner_type_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="banner_name"
                  className="col-sm-2 col-form-label"
                >
                  Banner Name
                </label>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    name="banner_name"
                    id="banner_name"
                    value={BannerformData.banner_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="title" className="col-sm-2 col-form-label">
                  Title
                </label>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    value={BannerformData.title}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="link_one" className="col-sm-2 col-form-label">
                  Link One
                </label>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    name="link_one"
                    id="link_one"
                    value={BannerformData.link_one}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label htmlFor="link_two" className="col-sm-2 col-form-label">
                  Link Two
                </label>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    name="link_two"
                    id="link_two"
                    value={BannerformData.link_two}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="high_lighted"
                  className="col-sm-2 col-form-label"
                >
                  High Lighted
                </label>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    name="high_lighted"
                    id="high_lighted"
                    value={BannerformData.high_lighted}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="place_order"
                  className="col-sm-2 col-form-label"
                >
                  Place Order
                </label>
                <div className="col-sm-8">
                  <Input
                    type="text"
                    name="place_order"
                    id="place_order"
                    value={BannerformData.place_order}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="description"
                  className="col-sm-2 col-form-label"
                >
                  Description
                </label>
                <div className="col-sm-8">
                  <CommonTextarea
                    type="text"
                    name="description"
                    id="description"
                    value={BannerformData.description}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="is_responsive_image"
                  className="col-sm-4 col-form-label"
                >
                  Responsive Image Status
                </label>
                <div className="col-sm-8 form-check form-switch">
                  <Inputcheckbox
                    className="form-check-input"
                    type="checkbox"
                    id="is_responsive_image"
                    name="is_responsive_image"
                    checked={BannerformData.is_responsive_image === 1}
                    onChange={handleToggleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="slug" className="col-sm-2 col-form-label">
                  Status
                </label>
                <div className="col-sm-8 form-check form-switch">
                  <Inputcheckbox
                    className="form-check-input"
                    type="checkbox"
                    id="is_active"
                    name="is_active"
                    checked={BannerformData.is_active === 1}
                    onChange={handleToggleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label
                  htmlFor="colorPicker"
                  className="col-sm-3 col-form-label"
                >
                  Background Color
                </label>
                <div className="col-sm-8">
                  <div className="input-group">
                    <input
                      id="colorPicker"
                      type="text"
                      name="color_code"
                      value={selectedColor}
                      onChange={handleInputChange}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <span
                      className="input-group-text"
                      style={{
                        marginTop: "-10px",
                        border: `1px solid #000`,
                        borderRadius: "100%",
                        backgroundColor: selectedColor,
                        color: "#000",
                        borderLeft: "none",
                        padding: "25px",
                        width: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6"></div>

              <div className="col-md-6">
                <label>Preview</label>

                <br />
                {previewImage ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{
                        maxWidth: "100%",
                        width: "200px",
                        height: "200px",
                      }}
                    />

                    <button
                      type="button"
                      onClick={handleDeletePreview}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                ) : (
                  <img src={UploadImage} alt="Alt" />
                )}
                <br></br>
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openModal}
                >
                  Select Image
                </button>
              </div>
              <div className="col-md-6">
                <label>Resposive Image</label>
                <br />
                {previewImages ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={previewImages}
                      alt="Preview"
                      style={{
                        maxWidth: "100%",
                        width: "200px",
                        height: "200px",
                      }}
                    />

                    <button
                      type="button"
                      onClick={handleDeletePreviews}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </div>
                ) : (
                  <img src={UploadImage} alt="Alt" />
                )}
                <br></br>
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openModals}
                >
                  Select Image
                </button>
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
        <SingleImageSelect
          isOpen={modalIsOpen}
          handleClose={closeModal}
          handleImageClick={handleImageClick}
          selectedImages={selectedImages}
          imageUrl={imageUrl}
        />

        <SingleImageSelect
          isOpen={modalIsOpens}
          handleClose={closeModals}
          handleImageClick={handleImageClicks}
          selectedImages={selectedImages_two}
          imageUrl={imageUrls}
        />
      </Modal>
    </main>
  );
};

export default BannerList;
