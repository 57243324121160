import React, { useEffect, useState } from 'react';
import SectionCreate from './SectionCreate';
import '../../../../assets/css/section.css';
import { SectionList } from '../../../../service/apiService';

const MainForm = ({ onSuccess }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [sectionDropdownData, setSectionDropdownData] = useState([]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSaveSection = (formData) => {
    setSections([...sections, formData]);
    onSuccess();  
  };

  const handleDropdownChange = (e) => {
    setSelectedSectionId(e.target.value);
  };

  useEffect(() => {
    const fetchSections = async () => {
      const token = localStorage.getItem('admin_token');
      const response = await SectionList(token);
      setSectionDropdownData(response.data);
    };
    fetchSections();
  }, []);

  return (
    <div className="main-form-container">
      <div className="main-form">
        <label className="main-label" htmlFor="main-dropdown">Select Main Option:</label>
        <select className="main-dropdown" id="main-dropdown" onChange={handleDropdownChange} value={selectedSectionId}>
          <option value="">Select Section</option>
          {sectionDropdownData.map((section) => (
            <option key={section.section_id} value={section.section_id}>
              {section.section_name.toUpperCase()}
            </option>
          ))}
        </select>
        <button className="add-section-btn" type="button" onClick={openModal}>Add Section</button>
        <SectionCreate
          onSuccess={onSuccess}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          onSave={handleSaveSection}
          sectionId={selectedSectionId}
        />
      </div>
    </div>
  );
};

export default MainForm;