import React from "react";

const About =() => {
   
     

    return  (
        <main id="main" className="main">
              <div className="pagetitle">
    <h1>About Us</h1>
    <nav>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/dashboard">Dashboard</a>
        </li>
        <li className="breadcrumb-item">Appearance</li>
        <li className="breadcrumb-item active">About Us</li>
      </ol>
    </nav>
  </div>
  <section className="section">
    <div className="row">
        <div className="col-lg-10">
            <div className="card mb-4">
                <div className="card-body">
                    <form className="row g-3" >
                        <div className="mb-4">
                            <label htmlFor="name" className="col-sm-2 col-form-label">Title</label>
                            <div className="col-sm-4">
                                <input id="name" type="text" className="form-control" placeholder="Type here" />
                            </div>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="slug" className="col-sm-2 col-form-label">Description</label>
                            <div className="col-sm-4">
                                <input id="slug" type="text"  placeholder="Type here" className="form-control" readOnly />
                            </div>
                        </div>                       

                    
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            </div>
        </div>
       
    </div>
      
  </section>
        </main>

    )



}


export default About