import React, { useEffect, useState } from "react";
import { AppAboutUsApi } from "../../../service/apiService";
import '../../../assets/css/appAboutUs.css';
import SideBar from "../Dashboard/SideBar";
import AdminHeader from "../Dashboard/AdminHeader";
import AdminFooter from "../Dashboard/AdminFooter";


const AppAboutUs = () => {
    const [appAboutUs, setAppAboutUs] = useState(null);

    const fetchAboutUs = async () => {
        const response = await AppAboutUsApi();
        setAppAboutUs(response);
        console.log(response);
    };

    useEffect(() => {
        fetchAboutUs();
    }, []);

    return (
        <div className="about-us-container">
          <AdminHeader />
            <SideBar />
            {appAboutUs ? (
                <div className="about">
                    <pre>{JSON.stringify(appAboutUs, null, 2)}</pre>
                </div>
            ) : (
                <p>Loading...</p>
            )}
             <AdminFooter />
        </div>
    );
};

export default AppAboutUs;
