import React from 'react';  
import AdminHeader from '../../pages/views/Dashboard/AdminHeader';
import SideBar from '../../pages/views/Dashboard/SideBar';
import AdminFooter from '../../pages/views/Dashboard/AdminFooter';
import OrderList from '../../pages/views/Orders/OrderList';

const AllOrders =() => {

    return (
        <div>
            <AdminHeader/>
            <SideBar/>
            <main className="main-wrap">
            <OrderList/>
        </main>
        <AdminFooter/>

        </div>
    )


}

export default AllOrders