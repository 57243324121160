import React, { useEffect, useState } from "react";
import Input from '../../../components/Input';

import InputCheckbox from '../../../components/Inputcheckbox';

import { useNavigate } from "react-router-dom";
import NewadminCreate from "../../../service/apiService";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";


const Admincreate = () => {

  const navigate = useNavigate();
  const token = localStorage.getItem('admin_token');
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    password: '',
    role: '',
    phone_no: '',
    is_active: 1
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setFormData({
      ...formData,
      is_active: activeValue,
    });
  };


  const role = parseInt(formData.role);
  const phone_no = parseInt(formData.phone_no);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.full_name || !formData.email || !formData.password || !formData.role || !formData.phone_no || !formData.is_active) {
      toast.error('Please Fill The Form Data.');
      return;
    }

    try {
      const token = localStorage.getItem('admin_token');

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,

        },
      };
      const body = {
        full_name: formData.full_name,
        email: formData.email,
        password: formData.password,
        role: role,
        phone_no: phone_no,
        is_active: 1

      }


      // const adminCreateResponse = NewadminCreate(token);
      const adminCreateResponse = await axios.post('https://folcon.elegra.in/admin/create-admin', body, config);
      if (adminCreateResponse.data.status === "success") {

        toast.success('Admin created successfully!');
        // navigate('/admin');
        setFormData({
          full_name: '',
          email: '',
          password: '',
          role: '',
          phone_no: '',
          is_active: 1
        });
      } else {
        toast.error('Admin creation failed. Please try again.');
      }
    }
    catch (error) {

      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || 'There was an error creating the admin.';
        toast.error(errorMessage);
        console.error('Error response:', error.response.data);
      }
      else {
        toast.error('There was an error creating the admin.');
        console.error('Error:', error);
      }
      console.error('Error creating Admin:', error);
    }
  };



  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Admin Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashbaord">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Admin</li>
            <li className="breadcrumb-item active">Data</li>
          </ol>
        </nav>
      </div>
      <div className="card">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit} >
            <div className="col-md-6">
              <label htmlFor="full_name" className="form-label">
                Admin Name
              </label>
              <Input
                type="text"
                id="full_name"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}

              />
            </div>

            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <Input
                type="text"
                id="email"
                name="email"

                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="email" className="form-label">
                password
              </label>
              <Input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />


            </div>
            <div className="col-md-6">
              <label htmlFor="phone_no" className="form-label">
                Role
              </label>
              <select name="role" value={formData.role} onChange={handleChange}>
                <option value={1}>1</option>;
                <option value={2}>2</option>;
                <option value={3}>3</option>;
                {/* Add more roles as needed */}
              </select>;
            </div>


            <div className="col-md-6">
              <label htmlFor="phone_no" className="form-label">
                Phone No
              </label>
              <Input
                type="text"
                id="phone_no"
                name="phone_no"
                value={formData.phone_no}
                onChange={handleChange}

              />

            </div>
            <div className="col-md-6">
              <label className="form-check-label" htmlFor="toggle">
                Status
              </label>
              <div className="form-check form-switch">
                <InputCheckbox
                  checked={formData.is_active === 1}
                  onChange={handleToggleChange}

                />
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Admincreate;

