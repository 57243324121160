import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

import { useNavigate } from 'react-router-dom';
import {GetFaqCategoryList} from "../../../service/apiService";






const Faqcategorylist = () => {
    const navigate = useNavigate();

    const [Faqcategorylist, setFaqcategorylist] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedFaqcategoryId, setSelectedFaqcategoryId] = useState(null);
    const [FaqcategoryFormData, setFaqcategoryFormData] = useState({
        name: '',
        description: ''
       
    });


    useEffect(() => {
        fetchData();

    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(Faqcategorylist?.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentFaqcategorylist = Faqcategorylist?.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)





    const fetchData = async () => {
        try {
          const token = localStorage.getItem("admin_token");
          const response = await GetFaqCategoryList(token);
          setFaqcategorylist(response.data);
        } catch (error) {
          console.log(error);
        }
      };
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleEdit = async (FaqcategoryId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get(`https://folcon.elegra.in/admin/get-faq-category/${FaqcategoryId}`, config);


            const responseData = response.data;
            setFaqcategoryFormData({
                name: responseData.data.name,
                description: responseData.data.description,
               
                is_active: responseData.data.is_active

            });
            setSelectedFaqcategoryId(FaqcategoryId);
            openModal();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!FaqcategoryFormData.name || !FaqcategoryFormData.description) {
                toast.error('Please Fill The Form Data');
                return;
            }

         
            const createPayload = {
                ...FaqcategoryFormData,
                name: FaqcategoryFormData.name,
                description: FaqcategoryFormData.description,
                id: selectedFaqcategoryId,
            };
            const createResponse = await axios.post('https://folcon.elegra.in/admin/update-faq-category', createPayload, config);

            if (createResponse.data.status === "success") {
                toast.success('Faq category updated successfully!');
                fetchData();
                setFaqcategoryFormData({
                    name: '',
                  
                    description: '',
                    is_active: 0,
                });
            } else {
                toast.error('Faq category update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating Faq category:', error);
            toast.error('Faq category update failed. Please try again.');
        }
        closeModal();
    };





    const header = (


        <div className="table-header">
            <div>
                <h3>Faqcategory List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/faqCategory-create')} className="btn btn-primary">Create Faqcategory</button>
            </div>
        </div>
    );



    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setFaqcategoryFormData({
            ...FaqcategoryFormData,
            is_active: activeValue
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
       
        setFaqcategoryFormData({ ...FaqcategoryFormData, [name]: value });
    };

    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'name', header: 'Name' },
        
        { field: 'description', header: 'Description' },
        { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <>
        
        <main id="main" className="main">
             <AdminHeader /><SideBar />
            <div className="pagetitle">
                <h1>Faqcategory list</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Faqcategory</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>

            </div>
            <section className="section">


                <ReactDataTable
                    data={currentFaqcategorylist}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No FaqCategory records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />

            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Tax Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        // maxWidth: '80%',
                        // maxHeight: '80%',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >


                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Faqcategory</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form onSubmit={handleSubmit}>

                            <div className="mb-4">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={FaqcategoryFormData.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="label" className="form-label">
                                    Description
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="description"
                                    name="description"
                                    value={FaqcategoryFormData.description}
                                    onChange={handleChange}

                                />
                            </div>


                            <div className="mb-4">
                                <label htmlFor="toggle" className="form-label">
                                    Status
                                </label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="toggle"
                                        name="is_active"
                                        checked={FaqcategoryFormData.is_active === 1}
                                        onChange={handleToggleChange}
                                    />
                                </div>
                            </div>


                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>


                    </div>

                </div>
            </Modal>


        </main>
         <AdminFooter />
         </>
    );
};

export default Faqcategorylist;
