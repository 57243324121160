import React,{useState} from "react";
import { useNavigate } from "react-router-dom";
import adminLogo from '../../assets/images/adminlogonew.png';
import adminbg  from '../../assets/images/admin.png'
import { validateEmail, validatePassword } from '../../validation/CustomValidation';
import { AdminSignIn, AdminSignup }  from '../../service/apiService'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import { Form } from 'react-bootstrap';


const AdminLogin = ({ setIsAdminAuthenticated }) => {

  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(userData.email);
    const passwordError = validatePassword(userData.password);

    setValidationErrors({
      email: emailError,
      password: passwordError,
    });

    if (!passwordError && !emailError) {
      try {
        const response = await  AdminSignIn(userData);
        if (response.status_code === 200) {
          localStorage.setItem('admin_token', response.token.token);
          setIsAdminAuthenticated(true); 
          toast.success(response.message);
          navigate('/admin/dashboard', { replace: true });
        } else {
          setErrorMessage(response.message);
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    }
  };
    return (
      <main className="testlogin">
        <div className="container">
          <div className="row">
          <div className="col-lg-6 d-none d-lg-block bg-image-logo" style={{backgroundImage: `url(${adminbg})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' , backgroundPosition: 'center'}}>

            </div>
            <div className="col-lg-6">
              <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10 d-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex justify-content-center py-4">
                        <img src={adminLogo} alt="Admin Logo" className="admin-logo" style={{height: '100px' } }  />
                    </div>
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Admin Panel
                          </h5>
                        </div>
                        <form onSubmit={handleSubmit} className=" row g-3 text-center">

                        <Form.Text className="text-danger bold">{errorMessage}</Form.Text>
    
                          <div className="col-12">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                id="email"
                                required=""
                                onChange={handleChange}
                                value={userData.email}
                              />
                              {validationErrors.email && (
                                <Form.Text className="text-danger">{validationErrors.email}</Form.Text>
                              )}
                            </div>
                        
                          <div className="col-12">
                            <label htmlFor="yourPassword" className="form-label">
                              Password
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              id="yourPassword"
                              required=""
                              value={userData.password}
                              onChange={handleChange}
                            />
                            {validationErrors.password && (
                              <Form.Text className="text-danger">{validationErrors.password}</Form.Text>
                            )}
                          </div>
    
                          <div className="col-12">
                            <button className="btn btn-primary w-100" type="submit" name="login">
                              Login
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
    
    
 
    )
}

export default AdminLogin;