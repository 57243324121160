import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faStreetView ,faCircleXmark, faTrashCanArrowUp} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import Modal from 'react-modal';

import ReactPaginate from 'react-paginate';




 
const AboutList = () => {
    const [AboutList, SetAboutList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
   
    
      const [showTrashIcon, setShowTrashIcon] = useState(false);
      const [modalIsOpen, setModalIsOpen] = useState(false);

      const [pageNumber, setPageNumber] = useState(0);
      const itemsPerPage = 10; 
    

      const pageCount = Math.ceil(AboutList.length / itemsPerPage);
      const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
      const currentAboutList = AboutList.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)



    const header = (
     
       
        <div className="table-header">
            <div>
                <h3>About List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                {/* <button onClick={() => navigate('/admin/brands/create')} className="btn btn-primary">Create Brand</button> */}
            </div>
        </div>
    );

   
   

    const openModal = () => {
        setModalIsOpen(true);
    };
    
    const closeModal = () => {
        setModalIsOpen(false);
    };

   

    const columns = [
        { field: 'id', header: 'S.No' },
        { field: 'coupon_code', header: 'Name' },
        { field: 'action', header: 'Action', body: (rowData) => (
            <div className="text-end">
                <div className="dropdown">
                    <a href="#" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                        <FontAwesomeIcon icon={faStreetView} />
                    </a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" >Edit info</a>
                        {/* <a className="dropdown-item text-danger" onClick={() => handleDelete(rowData)}>Delete</a> */}
                    </div>
                </div>
            </div>
        ) }
       
    ];

    return (
        <main id="main" className="main">
            <div className="pagetitle">
          <h1>About List</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashbaord">Dashbord</a>
              </li>
              <li className="breadcrumb-item">About</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
 
  </div>
  <section className="section">

            <ReactDataTable
                data={currentAboutList}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                header={header}
                emptyMessage="No Category records found"
                columns={columns}
            />
              <ReactPaginate
              previousLabel={'Previous'}
                                    nextLabel={'Next'}
                                    breakLabel={'...'}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />
   
        </section>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Edit Discount Info"
          style={{
              overlay: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
              },
              content: {
                  position: 'relative',
                  backgroundColor: '#fff',
                  borderRadius: '10px',
                  padding: '20px',
                  // maxWidth: '80%',
                  maxHeight: '100%',
                  overflowY: 'auto',
                  boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
              },
          }}
      >

      
      <div className='container' style={{ width: '900px' }}>
      <div className="row">
      <div className="col">
      <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Discount</h2>
     </div>
     <div className="col-auto"></div>
     <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display:'flex',justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
      <FontAwesomeIcon icon={faCircleXmark} style={{color: "#63E6BE",}} />
      </button>
      <form  className="row g-3" >


      <div className="col-md-6">
               
                  <input
                      type="text"
                      placeholder="Type id here"
                      className="form-control"
                      id="name"
                      name="name"
                     
                  />
              </div>

              <div className="col-md-6">
                  <label htmlFor="coupon_code" className="form-label">
               About
                  </label>
                  <input
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                      id="coupon_code"
                      name="coupon_code"
                     
                  />
              </div>


            
<div className="text-center">
<button type="submit" className="btn btn-primary">Update</button>

</div>
          </form>


      </div>

      </div>
      </Modal>

        </main>
    );
};

export default AboutList;
