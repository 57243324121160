import React, { useState } from "react";
import ProductForm from "../../../components/Product/ProductForm";
import ProductImageMedia from "../../../components/Product/ProductImageMedia";
import ProductVariantsForm from "../../../components/Product/ProductVariantsForm";
// import ProductClassificationForm from "../../../components/Product/ProductClassificationForm";
// import ProductCampaignForm from "../../../components/Product/ProductCampaignForm";


import ProductVideoMedia from "../../../components/Product/ProductVideoMedia";

const ProductCreate = () => {
  const [isProductFormSuccess, setIsProductFormSuccess] = useState(false);
  const [productId, setProductId] = useState(null);
    const handleProductFormSuccess = (productId) => {
        if (productId) {
        setIsProductFormSuccess(true);
        setProductId(productId);
        // localStorage.setItem("productId",productId);
        }
      };
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Product Create</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Dashbord</a>
              </li>
              <li className="breadcrumb-item">Product</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <ProductForm onSuccess={handleProductFormSuccess} />
            <div className="col-lg-6">
              <ProductImageMedia productId={productId} disabled={!isProductFormSuccess} />
              <ProductVideoMedia productId={productId} disabled={!isProductFormSuccess} />
               <ProductVariantsForm productId={productId} disabled={!isProductFormSuccess} /> 
               {/* <ProductClassificationForm productId={productId} disabled={!isProductFormSuccess} /> 
               <ProductCampaignForm productId={productId} disabled={!isProductFormSuccess} />  */}


            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductCreate;
