import React from "react";
import { Form } from "react-bootstrap";
import '../../src/assets/css/Toaster.css';

export const CustomValidationError = ({ error }) => {
    return (
        <>
        {error && (
            <Form.Text className="text-danger">
            {error}
            </Form.Text>
         )}
        </>
    );
};

export const CustomToast = ({ message, onClose }) => {
  return (
    <div className="toast-container">
      <div className="toast-message">
        <span>{message}</span>
        <button className="toast-close-button" onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};
