import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createfaqcategory} from "../../../service/apiService";

import { useNavigate } from "react-router-dom";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const FaqCategoryCreate = () => {
  const [faqformData, setfaqFormData] = useState({
    name: '',
    description: '',
    is_active: 1
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    description: ""
  });
  var navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState(null);

  const token = localStorage.getItem("admin_token");






  const handleChange = (e) => {
    const { id, value } = e.target;
    setfaqFormData({
      ...faqformData,
      [id]: value,
      
    });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setfaqFormData({
      ...faqformData,
      is_active: activeValue
    });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    // const csrfToken = localStorage.getItem('csrfToken');
    const errors = {};
    if (!faqformData.name) {
      errors.name = "Name is required";
      errors.description = "Description is required";
    }

    setValidationErrors(errors);

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'X-CSRF-Token': csrfToken,
    //   },
    // };

    try {
      if (!faqformData.name || !faqformData.description) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const payload = {
        ...faqformData,
        name: faqformData.name,
        description: faqformData.description,
      };

      const createResponse = await createfaqcategory(token, payload);
      if (createResponse.status === "success") {

        toast.success('FAQ Category created successfully!');
       // navigate('/admin/faqCategories');
        setfaqFormData({
          name: '',
          description: '',
          is_active: 1
        });
      } else {
        toast.error('FAQ Category creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating FAQ Category:', error);
      toast.error('FAQ Category creation failed. Please try again.');
    }
  };


  return (
    <main id="main" className="main">
      <AdminHeader />
      <SideBar />
      <div className="pagetitle">
        <h1>FAQ Category Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">FAQ Category</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-4">
                      <input id="name" type="text" value={faqformData.name} placeholder="Type here" onChange={handleChange}  className="form-control" />
                      {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}

                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">Description</label>
                    <div className="col-sm-4">
                      <input id="description" type="text" value={faqformData.description} placeholder="Type here" onChange={handleChange} className="form-control"  />
                      {validationErrors.description  && <div className="text-danger">{validationErrors.description}</div>}

                    </div>
                  </div>
                  <div className="md-4">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-4">
                      <input className="form-check-input" type="checkbox" id="toggle" checked={faqformData.is_active === 1} onChange={handleToggleChange} />
                    </div>
                  </div>



                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

      </section>
      <AdminFooter />
    </main>

  )



}


export default FaqCategoryCreate