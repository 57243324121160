import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import Bannertypecreates from "../../pages/views/Settings/Banner_type_create";


const Bannertypecreate = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <Bannertypecreates/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default Bannertypecreate;