import React from "react";
import AdminHeader from "../../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../../pages/views/Dashboard/AdminFooter";
import BannerLists from "../../../pages/views/Appearance/Banner/list";


const BannerList = ()=>{
    return (
     <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <BannerLists/>
            
        </main>
        <AdminFooter/>
     </div>
             
        
    );

}

export default BannerList;