import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

import { useNavigate } from 'react-router-dom';
import {GetqustionList,ChatbotEnquiryDropdown} from "../../../service/apiService";






const Faqlist = () => {
    const navigate = useNavigate();

    const [Faqlist, setFaqlist] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedFaqId, setSelectedFaqId] = useState(null);
    const [faqcategoryId, setfaqcategoryId] = useState(null);
    const [FaqFormData, setFaqFormData] = useState({
        question: '',
        answer: ''
       
    });

    const token = localStorage.getItem("admin_token");
    const [faqFields, setfaqFields] = useState([{ id: null, dropdownValue: "" }]);
    const [faqList, setfaqList] = useState([]);
    useEffect(() => {
      enquiryDropDownList();
    }, []);


    const handleChangeDropdowns = (index, value) => {
        const updatedFields = [...faqFields];
        updatedFields[index].dropdownValue = value;
        updatedFields[index].id = parseInt(value, 10); // Convert to integer
        setfaqFields(updatedFields);
        setfaqcategoryId(parseInt(value, 10)); // Update the selected category ID
    };
      
      
      const enquiryDropDownList = async () => {
        try {
          const response = await ChatbotEnquiryDropdown(token);
          setfaqList(response.data);
        } catch (error) {
          console.error("Error fetching Enquiry list:", error);
        }
      };
    


    useEffect(() => {
        fetchData();

    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(Faqlist?.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentFaqlist = Faqlist?.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)





    const fetchData = async () => {
        try {
          const token = localStorage.getItem("admin_token");
          const response = await GetqustionList(token);
          setFaqlist(response.data);
        } catch (error) {
          console.log(error);
        }
      };
    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleEdit = async (id) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');
    
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };
    
            const response = await axios.get(`https://folcon.elegra.in/admin/get-chatbot-question-answer/${id}`, config);
            const responseData = response.data;
    
            setFaqFormData({
                id: responseData.data.enqurie_id,
                question: responseData.data.question,
                answer: responseData.data.answer,
                
            });
    
            setfaqcategoryId(responseData.data.enqurie_id);
            setSelectedFaqId(id);
            openModal();
    
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (faqFields[0].id !== "" || faqFields[0].id === faqcategoryId) {
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!FaqFormData.question || !FaqFormData.answer) {
                toast.error('Please Fill The Form Data');
                return;
            }
            const faqcategory_id = faqFields[0]?.id || null;
         
            const createPayload = {
                ...FaqFormData,
                id: selectedFaqId,
                enqurie_id: faqcategory_id ? faqcategory_id : faqcategoryId,
                question: FaqFormData.question,
                answer: FaqFormData.answer,
               
            };
            const createResponse = await axios.post('https://folcon.elegra.in/admin/update-chatbot-question-answer', createPayload, config);

            if (createResponse.data.status === "success") {
                toast.success('Chatbot Question updated successfully!');
                fetchData();
              
            } else {
                toast.error('Chatbot Question update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating Chatbot Question:', error);
            toast.error('Chatbot Question update failed. Please try again.');
        }}

        else {
            toast.error("Please select Enquiry ");
          }
        closeModal();
    };





    const header = (


        <div className="table-header">
            <div>
                <h3> All Qustion</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/chatbot/question')} className="btn btn-primary">Create Qustion</button>
            </div>
        </div>
    );



    const handleChange = (e) => {
        const { id, value } = e.target;
        setFaqFormData({
          ...FaqFormData,
          [id]: value,
          
        });
      };

    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'category_name', header: 'Category name' },
        { field: 'enquiry_name', header: 'Enquiry name' },
        { field: 'question', header: 'Question' },
        
      
        // { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <>
        
        <main id="main" className="main">
        <AdminHeader /><SideBar />  
            <div className="pagetitle">
                <h1> All Qustion</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item"> All Qustion</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>

            </div>
            <section className="section">


                <ReactDataTable
                    data={currentFaqlist}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No Faq records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />

            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Tax Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        // maxWidth: '80%',
                        // maxHeight: '80%',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >


                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Qustion and Answer</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form onSubmit={handleSubmit}>


                        <div className="mb-4">
                                <label htmlFor="name" className="form-label">
                                  Enquiry
                                </label>
                                {faqFields.map((faq, index) => (
                     
                       
                        
                     <select
                     id={`faq-dropdown-${index}`}
                     className="form-select"
                     value={faqcategoryId} // Ensure the dropdown's value is set to the fetched category ID
                     onChange={(e) => handleChangeDropdowns(index, e.target.value)}
                 >
                     {!faqList ? (
                         <option value="">No Data...</option>
                     ) : faqList?.length === 0 ? (
                         <option value="">No enquiry found</option>
                     ) : (
                         <>
                             <option value="">Select enquiry</option>
                             {faqList.map((faqItem) => (
                                 <option key={faqItem.id} value={faqItem.id}>
                                     {faqItem.name.toUpperCase()}
                                 </option>
                             ))}
                         </>
                     )}
                 </select>
                 
                        
                       
                    ))}
                               
                            </div>

                            <div className="mb-4">
                                <label htmlFor="title" className="form-label">
                                    Question
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="question"
                                    name="question"
                                    value={FaqFormData.question}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="description" className="form-label">
                                   Answer
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="answer"
                                    name="answer"
                                    value={FaqFormData.answer}
                                    onChange={handleChange}

                                />
                            </div>




                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>


                    </div>

                </div>
            </Modal>

            <AdminFooter />
</main>

         </>
    );
};

export default Faqlist;
