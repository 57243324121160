import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';


import { useNavigate } from 'react-router-dom';






const Taxlist = () => {
    const navigate = useNavigate();

    const [Taxlist, setTaxlist] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedTaxId, setSelectedTaxId] = useState(null);
    const [TaxFormData, setTaxFormData] = useState({
        name: '',
        percentage: '',
        label: ''
    });


    useEffect(() => {
        fetchData();

    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(Taxlist.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentTaxlist = Taxlist.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)






    const fetchData = async () => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await fetch('https://folcon.elegra.in/admin/all-taxes', config);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();

            let TaxData = [];

            if (Array.isArray(responseData)) {
                TaxData = responseData;
            } else if (Array.isArray(responseData.data)) {
                TaxData = responseData.data;
            } else {
                throw new Error('Data is not in the expected format');
            }
            setTaxlist(TaxData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleEdit = async (TaxId) => {
        try {
            const token = localStorage.getItem('admin_token');
            const csrfToken = localStorage.getItem('csrfToken');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-Token': csrfToken,
                },
            };

            const response = await axios.get(`https://folcon.elegra.in/admin/get-tax/${TaxId}`, config);


            const responseData = response.data;
            setTaxFormData({
                name: responseData.data.name,
                percentage: responseData.data.percentage,
                label: responseData.data.label,
                is_active: responseData.data.is_active

            });
            setSelectedTaxId(TaxId);
            openModal();

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('admin_token');
        const csrfToken = localStorage.getItem('csrfToken');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'X-CSRF-Token': csrfToken,
            },
        };

        try {
            if (!TaxFormData.name || !TaxFormData.label) {
                toast.error('Please Fill The Form Data');
                return;
            }

            // Check if the name or label already exists
            const isDuplicate = Taxlist.some(tax => tax.name === TaxFormData.name || tax.label === TaxFormData.label);
            if (isDuplicate) {
                toast.error('Tax name or label already exists. Please choose a different one.');
                return;
            }

            const createPayload = {
                ...TaxFormData,
                name: TaxFormData.name,
                percentage: parseFloat(TaxFormData.percentage), // Convert to float
                label: TaxFormData.label,
                id: selectedTaxId,
            };
            const createResponse = await axios.post('https://folcon.elegra.in/admin/update-tax', createPayload, config);

            if (createResponse.data.status === "success") {
                toast.success('Tax updated successfully!');
                fetchData();
                setTaxFormData({
                    name: '',
                    percentage: '',
                    label: '',
                    is_active: 0,
                });
            } else {
                toast.error('Tax update failed. Please try again.');
            }
        } catch (error) {
            console.error('Error updating tax:', error);
            toast.error('Tax update failed. Please try again.');
        }
        closeModal();
    };





    const header = (


        <div className="table-header">
            <div>
                <h3>Tax List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/tax-create')} className="btn btn-primary">Create Tax</button>
            </div>
        </div>
    );



    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setTaxFormData({
            ...TaxFormData,
            is_active: activeValue
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        if (name === 'name') {
            newValue = value.toUpperCase().replace(/[^A-Z\s]/g, '');
        }
        if (name === 'percentage') {
            // Allow only numbers
            newValue = value.replace(/[^0-9]/g, '');
        }
        setTaxFormData({ ...TaxFormData, [name]: newValue });
    };

    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'name', header: 'Tax Name' },
        { field: 'percentage', header: 'percentage' },
        { field: 'label', header: 'Label' },
        { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                    <div className="dropdown">
                        <a href="/" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                            <FontAwesomeIcon icon={faStreetView} />
                        </a>
                        <div className="dropdown-menu">
                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => handleEdit(rowData.id)}>Edit info</a>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Tax list</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Tax</li>
                        <li className="breadcrumb-item active">Data</li>
                    </ol>
                </nav>

            </div>
            <section className="section">


                <ReactDataTable
                    data={currentTaxlist}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No tax records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />

            </section>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Tax Info"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        position: 'relative',
                        backgroundColor: '#fff',
                        borderRadius: '10px',
                        padding: '20px',
                        // maxWidth: '80%',
                        // maxHeight: '80%',
                        overflowY: 'auto',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    },
                }}
            >


                <div className='container' style={{ width: '900px' }}>
                    <div className="row">
                        <div className="col">
                            <h2 style={{ display: 'inline-block', marginRight: '10px' }}>Edit Tax</h2>
                        </div>
                        <div className="col-auto"></div>
                        <button onClick={closeModal} style={{ fontSize: '30px', marginTop: '0', display: 'flex', justifyContent: 'flex-end', border: 'none', background: 'none', cursor: 'pointer' }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: "#63E6BE", }} />
                        </button>
                        <form onSubmit={handleSubmit}>

                            <div className="mb-4">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={TaxFormData.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="percentage" className="form-label">
                                    Percentage

                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control "
                                    id="percentage"
                                    name="percentage"
                                    value={TaxFormData.percentage}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="label" className="form-label">
                                    Tax Label
                                </label>
                                <input
                                    type="text"
                                    placeholder="Type here"
                                    className="form-control"
                                    id="label"
                                    name="label"
                                    value={TaxFormData.label}
                                    onChange={handleChange}

                                />
                            </div>


                            <div className="mb-4">
                                <label htmlFor="toggle" className="form-label">
                                    Status
                                </label>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="toggle"
                                        name="is_active"
                                        checked={TaxFormData.is_active === 1}
                                        onChange={handleToggleChange}
                                    />
                                </div>
                            </div>


                            <button type="submit" className="btn btn-primary">Update</button>
                        </form>


                    </div>

                </div>
            </Modal>


        </main>
    );
};

export default Taxlist;
