import React, { useState, useEffect } from "react";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";
import { AllProducts, AllUsers } from "../../../service/apiService";
import { toast } from "react-toastify";
import noImage from "../../../assets/images/noimage.jpg";

const Ordergenerate = ({ min = 1, max = 100, step = 1, initial = 1 }) => {
  const [quantity, setQuantity] = useState(initial);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isProductSelected, setIsProductSelected] = useState(false);

  const [isshippingSelected, setIsshippingsected] = useState(false);
  const [isBillingSelected, setIsBillingsected] = useState(false);

  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  const handleIncrement = () => {
    if (quantity < max) {
      setQuantity((prevQuantity) => prevQuantity + step);
    }
  };

  const handleDecrement = () => {
    if (quantity > min) {
      setQuantity((prevQuantity) => prevQuantity - step);
    }
  };

  const handleChange = (e) => {
    const value = Math.max(min, Math.min(max, Number(e.target.value)));
    setQuantity(value);
  };

  const scrollContainerStyle = {
    maxHeight: "600px",
    overflowY: "auto",
  };

  useEffect(() => {
    ProductDropDownList();
    UserDropDownList();
  }, []);

  useEffect(() => {
    fetchDatas();
  }, []);

  const token = localStorage.getItem("admin_token");
  const [Users, setUsers] = useState([]);

  const [UserList, setUserList] = useState([]);
  const [ClassificationLists, setClassification] = useState([]);

  const [UserFields, setUserFields] = useState([
    { id: null, dropdownValue: "" },
  ]);
  const [ProductList, setProductList] = useState([]);

  const [ProductFields, setProductFields] = useState([
    { product_id: null, dropdownValue: "" },
  ]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState("");
  const [selectedVarients, setSelectedVarient] = useState("");

  const [selectedBillingAddress, setSelectedBillingAddress] = useState("");

  const [VarientList, setvarientList] = useState([]);

  const [VarientFields, setVarientFields] = useState([
    { id: null, dropdownValue: "" },
  ]);
  const [toastMessage, setToastMessage] = useState(null);

  const [Var, setvar] = useState([]);

  const fetchDatas = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await AllUsers(token);
      let userData = [];

      if (Array.isArray(responseData)) {
        userData = responseData;
      } else if (Array.isArray(responseData.data)) {
        userData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setUsers(userData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const UserDropDownList = async () => {
    try {
      const response = await AllUsers(token);
      setUserList(response.data);
    } catch (error) {
      console.error("Error fetching User list:", error);
    }
  };

  useEffect(() => {
    fetchProductDatas();
  }, []);

  const fetchProductDatas = async () => {
    try {
      const token = localStorage.getItem("admin_token");
      const csrfToken = localStorage.getItem("csrfToken");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-CSRF-Token": csrfToken,
        },
      };
      const responseData = await AllProducts(token);
      let productData = [];

      if (Array.isArray(responseData)) {
        productData = responseData;
      } else if (Array.isArray(responseData.data)) {
        productData = responseData.data;
      } else {
        throw new Error("Data is not in the expected format");
      }

      setClassification(productData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ProductDropDownList = async () => {
    try {
      const response = await AllProducts(token);
      setProductList(response.data);
    } catch (error) {
      console.error("Error fetching Product list:", error);
    }
  };

  const handleChangeDropdowns = async (index, value) => {
    const updatedFields = [...UserFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].id = parseInt(value, 10); // Convert to integer
    setUserFields(updatedFields);
    setIsUserSelected(value !== "");

    if (value) {
      await fetchShippingAddresses(value);
      await fetchBillingAddresses(value);
      await fetchCartData(value);
      await fetchCartTotal(value);
    } else {
      setShippingAddresses([]);
      setBillingAddresses([]);
      setCartData([]);
    }
  };

  const handleChangeProductDropdowns = async (index, value) => {
    const updatedFields = [...ProductFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].product_id = parseInt(value, 10);
    setProductFields(updatedFields);
    setIsProductSelected(value !== "");
    if (value) {
      await fetchVarientDatas(value);
    }
  };

  const fetchVarientDatas = async (productId) => {
    try {
      const response = await fetch(
        `https://folcon.elegra.in/admin/get-product-variant/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setvarientList(data.data.variants);
    } catch (error) {
      console.error("Error fetching Varient:", error);
    }
  };

  const fetchShippingAddresses = async (userId) => {
    try {
      let admin_token = null;
      admin_token = localStorage.getItem("admin_token");
      const response = await fetch(
        `https://folcon.elegra.in/admin/get-shipping-addresses/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${admin_token}`,
          },
        }
      );
      const data = await response.json();
      setShippingAddresses(data.data);
    } catch (error) {
      console.error("Error fetching shipping addresses:", error);
    }
  };

  // useEffect(() => {
  //   // Fetch the data from the API
  //   fetch('https://folcon.elegra.in/admin/get-user-cart/70')
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.status === 'success') {
  //         setCartData(data.data);
  //       } else {
  //         console.error('Failed to fetch data');
  //       }
  //     })
  //     .catch(error => console.error('Error fetching data:', error));
  // }, []);

  const fetchBillingAddresses = async (userId) => {
    try {
      const response = await fetch(
        `https://folcon.elegra.in/admin/get-billing-addresses/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setBillingAddresses(data.data);
    } catch (error) {
      console.error("Error fetching billing addresses:", error);
    }
  };

  const handleShippingAddressChange = (event) => {
    const selectedAddress = shippingAddresses?.find(
      (address) =>
        address.shipping_address_id === parseInt(event.target.value, 10)
    );

    if (selectedAddress) {
      setSelectedShippingAddress({
        shipping_address_id: selectedAddress.shipping_address_id,
        shipping_name: selectedAddress.shipping_name,
        shipping_email: selectedAddress.shipping_email,
        shipping_phone_no: selectedAddress.shipping_phone_no,
        shipping_address_line_one: selectedAddress.shipping_address_line_one,
        shipping_address_line_two: selectedAddress.shipping_address_line_two,
        shipping_country: selectedAddress.shipping_country,
        shipping_state: selectedAddress.shipping_state,
        shipping_city: selectedAddress.shipping_city,
        shipping_pincode: selectedAddress.shipping_pincode,
      });
    } else {
      setSelectedShippingAddress({});
    }

    setIsshippingsected(selectedAddress !== "");
  };

  const handleChangeVarientDropdowns = (event) => {
    const selectedVarient = VarientList.find(
      (item) => item.variant_id === parseInt(event.target.value, 10)
    );

    if (selectedVarient) {
      setSelectedVarient({
        variant_id: selectedVarient.variant_id,
        variant_label_id: selectedVarient.variant_label_id,
        value: selectedVarient.value,
      });
    } else {
      setSelectedVarient({});
    }
  };

  const handleBillingAddressChange = (event) => {
    const selectedAddressb = billingAddresses.find(
      (address) =>
        address.billing_address_id === parseInt(event.target.value, 10)
    );

    if (selectedAddressb) {
      setSelectedBillingAddress({
        billing_address_id: selectedAddressb.billing_address_id,

        billing_name: selectedAddressb.billing_name,
        billing_email: selectedAddressb.billing_email,
        billing_phone_no: selectedAddressb.billing_phone_no,
        billing_address_line_one: selectedAddressb.billing_address_line_one,
        billing_address_line_two: selectedAddressb.billing_address_line_two,
        billing_country: selectedAddressb.billing_country,
        billing_state: selectedAddressb.billing_state,
        billing_city: selectedAddressb.billing_city,
        billing_pincode: selectedAddressb.billing_pincode,
      });
    } else {
      setSelectedBillingAddress({});
    }
    setIsBillingsected(selectedAddressb !== "");
  };

  const fetchCartTotal = async (userId) => {
    try {
      const response = await fetch(
        `https://folcon.elegra.in/admin/get-cart-total/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setTotalPrice(data.data.total_price.toFixed(2));
      } else {
        console.error("Unexpected data format:", data);
        setTotalPrice(0);
      }
    } catch (error) {
      console.error("Error fetching cart total:", error);
    }
  };

  const AppUrl = "https://folcon.elegra.in/api/files/";

  const fetchCartData = async (userId) => {
    try {
      const response = await fetch(
        `https://folcon.elegra.in/admin/get-user-cart/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setCartData(data.data);
      } else {
        console.error("Unexpected data format:", data);
        setCartData([]);
      }
    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const handleAddToCart = async (event) => {
    event.preventDefault();

    const selectedProduct = ProductFields[0].product_id;
    const selectedUser = UserFields[0].id;
    if (!selectedVarients.variant_id || !quantity || !selectedProduct) {
        toast.error("Please select a Data.");
        setToastMessage("Please select a Data.");
    
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
    
        return;
      }

      else{

    const payload = {
      product_id: selectedProduct,
      quantity: quantity,
      variant_id: selectedVarients.variant_id,
      attribute_id: 0,
      user_id: selectedUser,
    };

    try {
      const response = await fetch(
        "https://folcon.elegra.in/admin/add-to-cart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      console.log(response);
      if (response.status === 200) {
        fetchCartData(selectedUser);
        fetchCartTotal(selectedUser);
        toast.success(response.message);
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
      if (response.status === 400) {
        toast.error("Already Exist");
        setToastMessage("Already Exist");

        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }

}
  };

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const response = await fetch(`https://folcon.elegra.in/admin/get-user-cart/${userId}`);
  //         const result = await response.json();
  //         if (result.status === 'success') {
  //           setCartData(result.data);
  //         } else {
  //           console.error('Failed to fetch data:', result.message);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     };

  //     fetchData();
  //   }, [userId]);

  const [editableIndex, setEditableIndex] = useState(null);
  const [quantities, setQuantities] = useState({});

  const handleEditClick = (index, event) => {
    event.preventDefault();
    setEditableIndex(index);
    setQuantities(cartData[index].item_count);
  };

  const handleQuantityChange = (e) => {
    setQuantities(e.target.value);
  };

  const handleUpdateClick = async (productId, event) => {
    event.preventDefault();

    const selectedUser = UserFields[0].id;

    try {
      const response = await fetch(
        "https://folcon.elegra.in/admin/update-to-cart",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            product_id: productId,
            quantity: parseInt(quantities, 10),
            user_id: selectedUser,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
        fetchCartData(selectedUser);
        fetchCartTotal(selectedUser);
        setEditableIndex(null);
      } else {
        toast.error("Update failed");
      }
    } catch (error) {
      console.error("Error updating cart data:", error);
    }
  };

  const handleDeleteClick = async (productId, event) => {
    event.preventDefault();
    const selectedUser = UserFields[0].id;

    try {
      const response = await fetch(
        "https://folcon.elegra.in/admin/remove-add-to-cart-product",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            product_id: productId,
            user_id: selectedUser,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        toast.success(data.message);
        setCartData(cartData.filter((item) => item.product_id !== productId));
        fetchCartTotal(selectedUser);
      } else {
        toast.error("Delete failed");
      }
    } catch (error) {
      console.error("Error deleting cart data:", error);
    }
  };

  const handleGenerateOrder = async (event) => {
    event.preventDefault();

    const selectedUser = UserFields[0].id;

    try {
      const response = await fetch(
        "https://folcon.elegra.in/admin/place-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            billing_address_id: selectedBillingAddress.billing_address_id,
            shipping_address_id: selectedShippingAddress.shipping_address_id,
            user_id: selectedUser,
          }),
        }
      );
      const data = await response.json();
      if (data.status_code === 200) {
        toast.success("Order placed successfully");
        const orderId = data.data;
        const sendingData = { order_id: orderId, is_admin: 1 };
        const encodedData = btoa(JSON.stringify(sendingData));
        const laravelAppUrl = process.env.REACT_APP_LARAVEL_APP_URL;
        window.location.href = `${laravelAppUrl}/manual-payment?F1!sO=${encodedData}`;
      } else {
        toast.error("Order placement failed");
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  return (
    <>
      <main id="main" className="main">
        <AdminHeader />
        <SideBar />

        <div className="container">
          <div className="row">
            <div className="pagetitle">
              <h1>Order Generate</h1>
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item">Order</li>
                  <li className="breadcrumb-item active">Generate</li>
                </ol>
              </nav>
            </div>

            <section className="section">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-4">
                    <div className="card-body">
                      <form className="row g-3">
                        {UserFields.map((user, index) => (
                          <div className="col-md-6" key={index}>
                            <label htmlFor="username" className="form-label">
                              <b>User Name</b>
                            </label>
                            <select
                              id={`User-dropdown-${index}`}
                              className="form-select"
                              value={user.dropdownValue}
                              onChange={(e) =>
                                handleChangeDropdowns(index, e.target.value)
                              }
                            >
                              {!UserList ? (
                                <option value="">No Data...</option>
                              ) : UserList?.length === 0 ? (
                                <option value="">No User found</option>
                              ) : (
                                <>
                                  <option value="">Select User</option>
                                  {UserList.map((UserItem) => (
                                    <option
                                      key={UserItem.id}
                                      value={UserItem.id}
                                    >
                                      {UserItem.name_email}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        ))}

                        <div className="col-md-6"></div>
                        {ProductFields.map((Product, index) => (
                          <div key={index} className="col-md-6">
                            <label className="form-label">
                              {" "}
                              <b>Product Name</b>
                            </label>
                            <select
                              id={`Product-dropdown-${index}`}
                              className="form-select"
                              value={Product.dropdownValue}
                              onChange={(e) =>
                                handleChangeProductDropdowns(
                                  index,
                                  e.target.value
                                )
                              }
                              disabled={!isUserSelected}
                            >
                              {!ProductList ? (
                                <option value="">No Data...</option>
                              ) : ProductList?.length === 0 ? (
                                <option value="">No Product found</option>
                              ) : (
                                <>
                                  <option value="">Select Product</option>
                                  {ProductList.map((ProductItem) => (
                                    <option
                                      key={ProductItem.product_id}
                                      value={ProductItem.product_id}
                                    >
                                      {ProductItem.product_name.toUpperCase()}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                          </div>
                        ))}

                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="quantity" className="form-label">
                                <b>Quantity</b>
                              </label>
                              <div className="quantity-input">
                                <button
                                  type="button"
                                  onClick={handleDecrement}
                                  disabled={
                                    quantity <= min ||
                                    !isUserSelected ||
                                    !isProductSelected
                                  }
                                >
                                  -
                                </button>
                                <input
                                  type="number"
                                  name="quantity"
                                  value={quantity}
                                  onChange={handleChange}
                                  min={min}
                                  max={max}
                                  step={step}
                                  disabled={
                                    !isProductSelected || !isUserSelected
                                  }
                                />
                                <button
                                  type="button"
                                  onClick={handleIncrement}
                                  disabled={
                                    quantity >= max ||
                                    !isUserSelected ||
                                    !isProductSelected
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <label htmlFor="varient" className="form-label">
                                <b>Varient</b>
                              </label>

                              <select
                                className="form-select"
                                onChange={handleChangeVarientDropdowns}
                                disabled={!isProductSelected || !isUserSelected}
                                title={
                                  selectedVarients
                                    ? `Selected: ${selectedVarients}`
                                    : "Select Varient"
                                }
                              >
                                {VarientList?.length === 0 ? (
                                  <option value="">No Varient found</option>
                                ) : (
                                  <>
                                    <option value="">Select Varient</option>
                                    {VarientList.map((VarientItem) => (
                                      <option
                                        key={VarientItem.variant_id}
                                        value={VarientItem.variant_id}
                                      >
                                        {VarientItem.value.toUpperCase()}
                                      </option>
                                    ))}
                                  </>
                                )}
                              </select>
                            </div>

                            <div className="col-md-4">
                              <button
                                type="submit"
                                className="btn btn-success"
                                style={{ marginTop: "30px" }}
                                disabled={!isProductSelected || !isUserSelected}
                                onClick={handleAddToCart}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <label className="form-label">
                                <b>Shipping Address</b>
                              </label>
                              <select
                                className="form-select"
                                disabled={!isUserSelected}
                                onChange={handleShippingAddressChange}
                                title={
                                  selectedShippingAddress
                                    ? `Selected: ${selectedShippingAddress}`
                                    : "Select Shipping Address"
                                }
                              >
                                <option value="">
                                  Select Shipping Address
                                </option>
                                {shippingAddresses?.map((address) => (
                                  <option
                                    key={address.shipping_address_id}
                                    value={address.shipping_address_id}
                                  >
                                    {address.shipping_name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-md-6">
                              <label className="form-label">
                                <b>Billing Address</b>
                              </label>
                              <select
                                className="form-select"
                                disabled={!isUserSelected}
                                onChange={handleBillingAddressChange}
                                title={
                                  selectedBillingAddress
                                    ? `Selected: ${selectedBillingAddress}`
                                    : "Select Shipping Address"
                                }
                              >
                                <option value="">Select Billing Address</option>
                                {billingAddresses?.map((address) => (
                                  <option
                                    key={address.billing_address_id}
                                    value={address.billing_address_id}
                                  >
                                    {address.billing_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6"></div>

                        <div className="col-md-6">
                          <div className="row">
                            {isshippingSelected && (
                              <div
                                className="col-md-6 card"
                                style={{ padding: "20px" }}
                              >
                                <span className="badge">Shipping Address</span>
                                <p>
                                  {" "}
                                  {selectedShippingAddress.shipping_name},<br />
                                  {selectedShippingAddress.shipping_email},
                                  <br />
                                  {selectedShippingAddress.shipping_phone_no},
                                  <br />
                                  {
                                    selectedShippingAddress.shipping_address_line_one
                                  }
                                  ,<br />
                                  {selectedShippingAddress.shipping_country},
                                  <br />
                                  {selectedShippingAddress.shipping_state},
                                  <br />
                                  {selectedShippingAddress.shipping_city} -{" "}
                                  {selectedShippingAddress.shipping_pincode}
                                </p>
                              </div>
                            )}

                            {isBillingSelected && (
                              <div
                                className="col-md-6 card"
                                style={{ padding: "20px" }}
                              >
                                <span className="badge">Billing Address</span>
                                <p>
                                  {selectedBillingAddress.billing_name},<br />
                                  {selectedBillingAddress.billing_email},<br />
                                  {selectedBillingAddress.billing_phone_no},
                                  <br />
                                  {
                                    selectedBillingAddress.billing_address_line_one
                                  }
                                  ,<br />
                                  {selectedBillingAddress.billing_country},
                                  <br />
                                  {selectedBillingAddress.billing_state},
                                  <br />
                                  {selectedBillingAddress.billing_city} -{" "}
                                  {selectedBillingAddress.billing_pincode}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div style={scrollContainerStyle}>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th width="5%">S.NO</th>
                                  <th width="20%">Product Name</th>
                                  <th width="20%">Slug</th>
                                  <th width="10%">Image</th>
                                  <th width="12%">Original price</th>
                                  <th width="12%">Discount price</th>
                                  <th width="10%">Tax amount</th>
                                  <th width="12%">Sales price</th>
                                  <th width="12%">SubTotal</th>
                                  <th width="12%">Quantity</th>
                                  <th width="12%">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {cartData?.length > 0 ? (
                                  cartData.map((item, index) => (
                                    <tr key={item.product_id}>
                                      <td>{index + 1}</td>
                                      <td>{item.product_name}</td>
                                      <td>{item.slug}</td>

                                      <td>
                                        <img
                                          src={
                                            item.thumnail_image.startsWith(
                                              AppUrl
                                            )
                                              ? item.thumnail_image
                                              : `${AppUrl}${item.thumnail_image}` ||
                                                noImage
                                          }
                                          width="50"
                                        />
                                      </td>
                                      <td>{item.original_price.toFixed(2)}</td>
                                      <td>{item.discount_price.toFixed(2)}</td>
                                      <td>{item.tax_amount.toFixed(2)}</td>
                                      <td>{item.sales_price.toFixed(2)}</td>
                                      <td>{item.sub_total.toFixed(2)}</td>
                                      <td>
                                        {editableIndex === index ? (
                                          <input
                                            type="number"
                                            value={quantities}
                                            onChange={handleQuantityChange}
                                          />
                                        ) : (
                                          item.item_count
                                        )}
                                      </td>
                                      <td>
                                        {editableIndex === index ? (
                                          <>
                                            <button
                                              onClick={(event) =>
                                                handleUpdateClick(
                                                  item.product_id,
                                                  event
                                                )
                                              }
                                            >
                                              Save
                                            </button>
                                            <button
                                              onClick={() =>
                                                setEditableIndex(null)
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <div style={{ display: "flex" }}>
                                              <button
                                                className="btn btn-primary"
                                                onClick={(event) =>
                                                  handleEditClick(index, event)
                                                }
                                              >
                                                Edit
                                              </button>
                                              <button
                                                className="btn btn-danger"
                                                onClick={(event) =>
                                                  handleDeleteClick(
                                                    item.product_id,
                                                    event
                                                  )
                                                }
                                                style={{ marginLeft: "10px" }}
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="11">No cart data available</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="col-md-8"></div>

                        <div className="col-md-4">
                          <h4 style={{ marginTop: "10px" }}>
                            <b>Total: </b>
                            {totalPrice}
                          </h4>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={!isUserSelected}
                            onClick={handleGenerateOrder}
                          >
                            Generate
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <AdminFooter />
    </>
  );
};

export default Ordergenerate;
