import React, { useEffect, useState } from "react";
import CommonTextarea from "../../../../components/CommonTextarea";
import Input from "../../../../components/Input";
import Inputcheckbox from "../../../../components/Inputcheckbox";
import UploadImage from "../../../../assets/images/defimg.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { MediaList } from "../../../../service/apiService";
import SingleImageSelect from "../../../../components/Media/SingleImageSelect";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const BannerCreate = () => {
  const navigate = useNavigate();
  const [selectedColor, setSelectedColor] = useState("#000");
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImages_two, setSelectedImages_two] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedResponsiveFile, setSelectedResponsiveFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpens, setModalIsOpens] = useState(false);
  const BANNER_CREATION_URL = "https://folcon.elegra.in/admin/create-banner";
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const [imageUrl, setImageUrl] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [BannerImage, setBannerImage] = useState(null);
  const [BannerImages, setBannerImages] = useState(null);
  const [BannerTypeList, setBannerTypeList] = useState([]);
  const [selectedBannerId, setSelectedBrannerId] = useState(null);

  const [errors, setErrors] = useState({});

  const [BannerformData, setBannerformData] = useState({
    banner_type_id: "",
    banner_name: "",
    title: "",
    description: "",
    link_one: "",
    link_two: "",
    high_lighted: "",
    image_path: "",
    responsive_image_path: "",
    background_color: "",
    is_responsive_image: 1,
    place_order: "",
    is_active: 1,
  });

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };

  const handleDeletePreviews = () => {
    setPreviewImages(null);
    setSelectedImages_two([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    const formErrors = {};
    if (!BannerformData.banner_name) {
      formErrors.banner_name = "Banner name is required";
    }

    if (!BannerformData.banner_type_id) {
      formErrors.banner_type_id = "Banner type is required";
    }

    if (!BannerformData.title) {
      formErrors.title = "Banner title is required";
    }
    if (!BannerformData.place_order) {
      formErrors.place_order = "Banner place order is required";
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    const token = localStorage.getItem("admin_token");
    const csrfToken = localStorage.getItem("csrfToken");

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-CSRF-Token": csrfToken,
      },
    };

    try {
      if (!BannerformData.banner_name || !BannerformData.title) {
        toast.error("Please Fill The Form Data");
        return;
      }
      const banner_type_id = parseInt(BannerformData.banner_type_id);
      const place_order = parseInt(BannerformData.place_order);

      const createPayload = {
        ...BannerformData,
        image_path: BannerImage,
        responsive_image_path: BannerImages,
        banner_name: BannerformData.banner_name,
        banner_type_id: banner_type_id,
        place_order: place_order,
      };

      const createResponse = await axios.post(
        "https://folcon.elegra.in/admin/create-banner",
        createPayload,
        config
      );

      console.log("data", createResponse.data);
      if (createResponse.data.status === "success") {
        setPreviewImage(null);
        setSelectedFile(null);
        toast.success("Banner created successfully!");
        setBannerformData({
          banner_type_id: "",
          banner_name: "",
          title: "",
          description: "",
          link_one: "",
          link_two: "",
          high_lighted: "",
          image_path: "",
          responsive_image_path: "",
          background_color: "",
          is_responsive_image: 1,
          place_order: "",
          is_active: 1,
        });
        navigate("/admin/banner-list");
      } else {
        toast.error("Banner creation failed. Please try again.");
      }
    } catch (error) {
      console.error("Error creating banner:", error);
      toast.error("Banner creation failed. Please try again.");
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const openModals = () => setModalIsOpens(true);
  const closeModals = () => setModalIsOpens(false);

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter((i) => i !== index));
      }
    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      setBannerImage(imageUrl[index]);
      closeModal();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBannerformData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageClicks = (index) => {
    if (selectedImages_two.includes(index)) {
      if (selectedImages_two.length > 0) {
        setSelectedImages_two(selectedImages_two.filter((i) => i !== index));
      }
    } else {
      setSelectedImages_two([index]);
      setPreviewImages(AppUrl + imageUrls[index]);
      setBannerImages(imageUrls[index]);
      closeModals();
    }
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length > 0){
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });
      //}
    }
  }, [token]);

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    setBannerformData({
      ...BannerformData,
      is_responsive_image: isChecked ? 1 : 0,
      is_active: isChecked ? 1 : 0,
    });
  };

  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      // if(response.length > 0){
      response.then((response) => {
        const imageUrlss = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrls(imageUrlss);
      });
      //}
    }
  }, [token]);

  useEffect(() => {
    const fetchBannerTypes = async () => {
      try {
        const token = localStorage.getItem("admin_token");
        const csrfToken = localStorage.getItem("csrfToken");

        const config = {
          method: "GET", // Ensure the method is set to GET
          headers: {
            Authorization: `Bearer ${token}`,
            "X-CSRF-Token": csrfToken,
          },
        };

        const response = await fetch(
          "https://folcon.elegra.in/admin/banner-type-dropdown",
          config
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();

        let bannertypeData = [];

        if (Array.isArray(responseData)) {
          bannertypeData = responseData;
        } else if (Array.isArray(responseData.data)) {
          bannertypeData = responseData.data;
        } else {
          throw new Error("Data is not in the expected format");
        }

        setBannerTypeList(bannertypeData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBannerTypes();
  }, []);

  const handleInputChange = (event) => {
    setSelectedColor(event.target.value);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Banner</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Appearance</li>
            <li className="breadcrumb-item active">Banner</li>
          </ol>
        </nav>
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="col-md-6">
                    <label
                      htmlFor="banner_type_id"
                      className="col-sm-2 col-form-label"
                    >
                      Banner Type
                    </label>
                    <div className="col-sm-8">
                      <select
                        type="text"
                        className="form-select"
                        name="banner_type_id"
                        id="banner_type_id"
                        value={BannerformData.banner_type_id}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Banner Type
                        </option>
                        {BannerTypeList.map((bannertype) => (
                          <option
                            key={bannertype.banner_type_id}
                            value={bannertype.banner_type_id}
                          >
                            {bannertype.banner_type_name}
                          </option>
                        ))}
                      </select>
                      {errors.banner_type_id && (
                        <p className="text-danger">{errors.banner_type_id}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="banner_name"
                      className="col-sm-2 col-form-label"
                    >
                      Banner Name
                    </label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        name="banner_name"
                        id="banner_name"
                        value={BannerformData.banner_name}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.banner_name && (
                      <p className="text-danger">{errors.banner_name}</p>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="title" className="col-sm-2 col-form-label">
                      Title
                    </label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        name="title"
                        id="title"
                        value={BannerformData.title}
                        onChange={handleChange}
                      />
                      {errors.title && (
                        <p className="text-danger">{errors.title}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="link_one"
                      className="col-sm-2 col-form-label"
                    >
                      Link One
                    </label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        name="link_one"
                        id="link_one"
                        value={BannerformData.link_one}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="link_two"
                      className="col-sm-2 col-form-label"
                    >
                      Link Two
                    </label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        name="link_two"
                        id="link_two"
                        value={BannerformData.link_two}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="high_lighted"
                      className="col-sm-2 col-form-label"
                    >
                      High Lighted
                    </label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        name="high_lighted"
                        id="high_lighted"
                        value={BannerformData.high_lighted}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="place_order"
                      className="col-sm-2 col-form-label"
                    >
                      Place Order
                    </label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        name="place_order"
                        id="place_order"
                        value={BannerformData.place_order}
                        onChange={handleChange}
                      />
                      {errors.place_order && (
                        <p className="text-danger">{errors.place_order}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="description"
                      className="col-sm-2 col-form-label"
                    >
                      Description
                    </label>
                    <div className="col-sm-8">
                      <CommonTextarea
                        type="text"
                        name="description"
                        id="description"
                        value={BannerformData.description}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="is_responsive_image"
                      className="col-sm-4 col-form-label"
                    >
                      Responsive Image Status
                    </label>
                    <div className="col-sm-8 form-check form-switch">
                      <Inputcheckbox
                        className="form-check-input"
                        type="checkbox"
                        id="is_responsive_image"
                        name="is_responsive_image"
                        checked={BannerformData.is_responsive_image === 1}
                        onChange={handleToggleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">
                      Status
                    </label>
                    <div className="col-sm-8 form-check form-switch">
                      <Inputcheckbox
                        className="form-check-input"
                        type="checkbox"
                        id="is_active"
                        name="is_active"
                        checked={BannerformData.is_active === 1}
                        onChange={handleToggleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label
                      htmlFor="colorPicker"
                      className="col-sm-3 col-form-label"
                    >
                      Background Color
                    </label>
                    <div className="col-sm-8">
                      <div className="input-group">
                        <input
                          id="colorPicker"
                          type="text"
                          name="color_code"
                          value={selectedColor}
                          onChange={handleInputChange}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <span
                          className="input-group-text"
                          style={{
                            marginTop: "-10px",
                            border: `1px solid #000`,
                            borderRadius: "100%",
                            backgroundColor: selectedColor,
                            color: "#000",
                            borderLeft: "none",
                            padding: "25px",
                            width: "10px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6"></div>

                  <div className="col-md-6">
                    <label>Preview</label>

                    <br />
                    {previewImage ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={previewImage}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            width: "200px",
                            height: "200px",
                          }}
                        />

                        <button
                          type="button"
                          onClick={handleDeletePreview}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br></br>
                    <button
                      type="button"
                      className="btn btn-primary mt-2 btn-sm btn-block"
                      onClick={openModal}
                    >
                      Select Image
                    </button>
                  </div>

                  <div className="col-md-6">
                    <label>Resposive Image</label>
                    <br />
                    {previewImages ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={previewImages}
                          alt="Preview"
                          style={{
                            maxWidth: "100%",
                            width: "200px",
                            height: "200px",
                          }}
                        />

                        <button
                          type="button"
                          onClick={handleDeletePreviews}
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    ) : (
                      <img src={UploadImage} alt="Alt" />
                    )}
                    <br></br>
                    <button
                      type="button"
                      className="btn btn-primary mt-2 btn-sm btn-block"
                      onClick={openModals}
                    >
                      Select Image
                    </button>
                  </div>

                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SingleImageSelect
            isOpen={modalIsOpen}
            handleClose={closeModal}
            handleImageClick={handleImageClick}
            selectedImages={selectedImages}
            imageUrl={imageUrl}
          />

          <SingleImageSelect
            isOpen={modalIsOpens}
            handleClose={closeModals}
            handleImageClick={handleImageClicks}
            selectedImages={selectedImages_two}
            imageUrl={imageUrls}
          />
        </div>
      </section>
    </main>
  );
};

export default BannerCreate;
