import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import Mediacreate from "../../pages/views/Media/MediaStore";

const MediaStore = () => {
    
    return (
        <div>
            <AdminHeader/>
            <SideBar/>
            <main className="main-wrap">
            <Mediacreate/>
            </main>
            <AdminFooter/>

        </div>
        

    )
}

export default MediaStore;