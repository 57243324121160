import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../../components/Dropdown";
import { CampaignDropDown, productCampaignSubmit, productCampaignUpdate, productCampaignEdits, AllCampaign } from "../../../service/apiService";
import { CustomToast } from "../../../Helper/Helper";

const ProductCampaignEditForm = ({ productId }) => {
  const [campaignLists, setCampaignLists] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [campaignList, setCampaignList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [campaignFields, setCampaignFields] = useState([{ campaign_id: null, dropdownValue: "" }]);
  const [disabled, setDisabled] = useState(true);
  const [editingCampaignId, setEditingCampaignId] = useState(null);
  const [newCampaignName, setNewCampaignName] = useState("");
  const [previousCampaignName, setPreviousCampaignName] = useState("");


  

  useEffect(() => {
    if (!productId) {
      console.error("productId is undefined");
      return;
    }

    setDisabled(!productId);
    if (productId) {
      // productClassificationEdit();
      campaignDropDownList();
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      fetchData(productId);
    }
  }, [productId]);
  

  const fetchData = async (productId) => {
    try {
       const responseData = await AllCampaign(token,productId);
       let productData = [];


       if (Array.isArray(responseData)) {
         productData = responseData;
       } else if (Array.isArray(responseData.data)) {
         productData = responseData.data;
       } else {
         throw new Error("Data is not in the expected format");
       }
     
       setCampaignLists(responseData.data);
    } catch (error) {
      console.error("Error fetching product classifications:", error);
    }
  };



  const campaignDropDownList = async () => {
    try {
      const response = await CampaignDropDown(token);
      setCampaignList(response.data);
    } catch (error) {
      console.error("Error fetching campaign list:", error);
    }
  };

  const productCampaignEdit = async () => {
    if (productId) {
      const response = null;

      //const response = await productCampaignEdits(token, productId);
      if(response){
      const campaignArray = response.data.campaigns;
      if (campaignArray) {
        const mappedCampaigns = campaignArray.map(campaign => ({
          campaign_id: campaign.campaign_id,
          dropdownValue: campaign.campaign_id.toString(),
        }));
        setCampaignFields(mappedCampaigns);
      }
    }
    }
  };

  const handleAddCampaign = () => {
    setCampaignFields([...campaignFields, { campaign_id: null, dropdownValue: "" }]);
  };

  const handleToggleActive = (index) => {
    const updatedFields = [...campaignFields];
    updatedFields[index].isActive = !updatedFields[index].isActive;
    setCampaignFields(updatedFields);
  };

  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...campaignFields];
    updatedFields[index].dropdownValue = value;
    setCampaignFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (campaignFields[0].dropdownValue !== "") {
      const payload = {
        product_id: productId,
        campaign_id: parseInt(campaignFields[0].dropdownValue),
      };

      const response = await productCampaignUpdate(token, payload);
      if (response.status_code === 200) {
       // toast.success(response.message);
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
        fetchData(productId);
      } else if (response.status_code === 400) {
       // toast.error(response.message);
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select at least one campaign");
    }
  };

  const handleCreateCampaign = async (index) => {
    const campaign = campaignFields[index];
    if (campaign.dropdownValue !== "") {
      const payload = {
        product_id: productId,
        campaign_id: parseInt(campaign.dropdownValue),
      };

      const response = await productCampaignSubmit(token, payload);
      if (response.status_code === 200) {
        //toast.success("Campaign created successfully");
        setToastMessage("Campaign created successfully");
        // productCampaignEdit();
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
        fetchData(productId);
      } else if (response === 400) {
       // toast.error("Already Exist");
        setToastMessage("Already Exist");
        // productCampaignEdit();
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } else {
      toast.error("Please select at least one campaign");
    }
  };

  const handleEditClick = (campaignId, campaignName) => {
    setEditingCampaignId(campaignId);
    setNewCampaignName(campaignId.toString()); // Set to campaign_id or related value
    setPreviousCampaignName(campaignName); // Optionally, save previous campaign name
  };

  const handleInputChange = (e) => {
    setNewCampaignName(e.target.value);
  };

  const scrollContainerStyle = {
    maxHeight: '150px', // Adjust the max height as needed
    overflowY: 'auto',
  };

  const handleSaveClick = async (campaign_map_id) => {
    try {
      const payload = {
        // campaign_id: parseInt(newCampaignName),
        // campaign_map_id: parseInt(newCampaignName),
        campaign_id: parseInt(newCampaignName),
        campaign_map_id: campaign_map_id,
        product_id: productId,
      };
  
      console.log("Payload:", payload);
  
      const response = await productCampaignUpdate(token, payload);
      console.log(response);
  
      console.log("API Response:", response);
  
      if (response.status_code === 200) {
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
  
        const updatedCampaigns = campaignLists.map((campaign) =>
          campaign.campaign_id === editingCampaignId
            ? { ...campaign, campaign_name: newCampaignName }
            : campaign
        );
        setCampaignLists(updatedCampaigns);
        setEditingCampaignId(null);
        setNewCampaignName("");
        fetchData(productId); // Call the fetchData function to refresh the data
      } else if (response.status_code === 400) {
        setToastMessage(response.message);
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      } else {
        console.error("Unexpected response:", response);
        setToastMessage("An unexpected error occurred");
        setTimeout(() => {
          setToastMessage(null);
        }, 3000);
      }
    } catch (error) {
      setToastMessage("Error updating campaign name");
      setTimeout(() => {
        setToastMessage(null);
      }, 3000);
      console.error("Error updating campaign name:", error);
    }
  };
  
  


  const handleCancelClick = () => {
    setNewCampaignName(previousCampaignName);
    setEditingCampaignId(null);
  };

  const handleDropdownChange = (e) => {
    //alert(e.target.value);
    setNewCampaignName(e.target.value);
  };

  return (
    <div className="col-lg-12">
      {toastMessage && <CustomToast message={toastMessage} onClose={() => setToastMessage(null)} />}
      <div className="card">
        <div className="card-body">
          <h4>Campaigns</h4>
          <form className="row g-3" onSubmit={handleSubmit}>
            {disabled ? (
              <div className="col-md-12">
                <div className="row g-3 align-items-center">
                  <div className="col-md-4">
                    <label className="form-label">Campaign</label>
                    <Dropdown options={campaignList} disabled />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {campaignFields.map((campaign, index) => (
                  <div key={index} className="col-md-12">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-3">
                        <label className="form-label">Campaign</label>
                        <select
                          id={`campaign-dropdown-${index}`}
                          className="form-select"
                          value={campaign.dropdownValue}
                          onChange={(e) => handleChangeDropdown(index, e.target.value)}
                        >
                          {!campaignList ? (
                            <option value="">No Data...</option>
                          ) : campaignList.length === 0 ? (
                            <option value="">No campaign found</option>
                          ) : (
                            <>
                              <option value="">Select campaign</option>
                              {campaignList.map((campaignItem) => (
                                <option key={campaignItem.campaign_id} value={campaignItem.campaign_id}>
                                  {campaignItem.campaign_name.toUpperCase()}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      {campaign.campaign_id === null && (
                        <div className="col-md-3 pt-4">
                          <label className="form-label"></label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleCreateCampaign(index)}
                          >
                            Create
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </form>
          <br /><br />

          <div style={scrollContainerStyle}>
          <table className="table">
            <thead>
              <tr>
                <th>Campaign Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {campaignLists.map((campaign) => (
                <tr key={campaign.campaign_id}>
                  <td>
                    {editingCampaignId === campaign.campaign_id ? (
                      <select
                        className="form-select"
                        value={newCampaignName}
                        onChange={handleDropdownChange}
                      >
                        <option value="">Select campaign</option>
                        {campaignList.map((campaignItem) => (
                          <option key={campaignItem.campaign_id} value={campaignItem.campaign_id}>
                            {campaignItem.campaign_name.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    ) : (
                      campaign.campaign_name.toUpperCase()
                    )}
                  </td>
                  <td>
                    {editingCampaignId === campaign.campaign_id ? (
                      <>
                        <button className="btn btn-primary" onClick={() => handleSaveClick(campaign.campaign_map_id)} >
                          Save
                        </button>
                        <button className="btn btn-secondary ms-2" onClick={handleCancelClick}>
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn btn-info"
                        onClick={() => handleEditClick(campaign.campaign_id, campaign.campaign_name)}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
                </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductCampaignEditForm;
