import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faStreetView } from "@fortawesome/free-solid-svg-icons";


 
const Alladmin = () => {
    const [Admin, setAdmin] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
          const token = localStorage.getItem('admin_token');
         // const response = await ProductList
        }catch (error) {
            console.log(error);
        }
    };
         

    const header = (
     
       
        <div className="table-header">
            <div>
                <h3>Admin</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                {/* <button onClick={() => navigate('/admin/brands/create')} className="btn btn-primary">Create Brand</button> */}
            </div>
        </div>
    );
    const handleEdit = (rowData) => {
        console.log(rowData);
    };
    const handleDelete = (rowData) => {
        console.log(rowData);
    };

    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'name', header: 'Admin Name' },
        { field: 'Email', header: 'Email' },
        { field: 'Phone_no', header: 'Phone no' },
        { field: 'role', header: 'Role' },
       
        { field: 'is_active', header: 'Status', body: (rowData) => <span className={rowData.is_active === 1 ? "active-status" : "inactive-status"}>{rowData.is_active === 1 ? 'Active' : 'Inactive'}</span> },
        { field: 'action', header: 'Action', body: (rowData) => (
            <div className="text-end">
                <div className="dropdown">
                    <a href="#" data-bs-toggle="dropdown" className="btn btn-light rounded btn-sm font-sm">
                        <FontAwesomeIcon icon={faStreetView} />
                    </a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" onClick={() => handleEdit(rowData)}>Edit info</a>
                        <a className="dropdown-item text-danger" onClick={() => handleDelete(rowData)}>Delete</a>
                    </div>
                </div>
            </div>
        ) }
    ];

    return (
        <main id="main" className="main">
            <div className="pagetitle">
          <h1>Admin List</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashbaord">Dashbord</a>
              </li>
              <li className="breadcrumb-item">Admin</li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
 
  </div>
            <ReactDataTable
                data={Admin}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                header={header}
                emptyMessage="No Category records found"
                columns={columns}
            />
        </main>
    );
};

export default Alladmin;
