import React, { useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Input from '../../../components/Input';
import InputCheckbox from '../../../components/Inputcheckbox';
import { useNavigate } from "react-router-dom";

const Taxcreate = () => {
  const navigate = useNavigate();

  const [TaxFormData, setTaxFormData] = useState({
    name: '',
    percentage: '',
    label: '',
    is_active: 1
  });

  const [errors, setErrors] = useState({
    name: '',
    percentage: '',
    label: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'name') {
      newValue = value.toUpperCase().replace(/[^A-Z\s]/g, '');
    }
    if (name === 'percentage') {
      // Allow only numbers
      newValue = value.replace(/[^0-9]/g, '');
    }
    setTaxFormData({ ...TaxFormData, [name]: newValue });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setTaxFormData({
      ...TaxFormData,
      is_active: activeValue
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!TaxFormData.name.trim()) {
      formIsValid = false;
      errors.name = 'Tax name is required';
    }

    if (!TaxFormData.percentage.trim()) {
      formIsValid = false;
      errors.percentage = 'Percentage is required';
    } else if (isNaN(TaxFormData.percentage) || TaxFormData.percentage <= 0) {
      formIsValid = false;
      errors.percentage = 'Percentage must be a positive number';
    }

    if (!TaxFormData.label.trim()) {
      formIsValid = false;
      errors.label = 'Tax label is required';
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error('Please fill the errors in the form');
      return;
    }

    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      const percentage = parseFloat(TaxFormData.percentage);
      const createPayload = {
        ...TaxFormData,
        percentage: percentage,
      };

      const createResponse = await axios.post('https://folcon.elegra.in/admin/create-tax', createPayload, config);

      if (createResponse.data.status === "success") {
        toast.success('Tax created successfully!');
        setTaxFormData({
          name: '',
          percentage: '',
          label: '',
          is_active: 1
        });
        navigate('/admin/tax-list');
      } else {
        toast.error('Tax creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating tax:', error);
      toast.error('Tax creation failed. Please try again.');
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Tax Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Tax</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit} >
                  <div className="col-md-6">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Tax Name</label>
                    <div className="col-sm-8">
                      <Input id="name" name="name" value={TaxFormData.name} onChange={handleChange} placeholder="Type here" />
                      {errors.name && <div className="text-danger">{errors.name}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="percentage" className="col-sm-2 col-form-label">Percentage</label>
                    <div className="col-sm-8">
                      <Input id="percentage" name="percentage" type="text" value={TaxFormData.percentage} onChange={handleChange} placeholder="Type here" />
                      {errors.percentage && <div className="text-danger">{errors.percentage}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="label" className="col-sm-2 col-form-label">Tax Label</label>
                    <div className="col-sm-8">
                      <Input id="label" name="label" value={TaxFormData.label} onChange={handleChange} placeholder="Type here" />
                      {errors.label && <div className="text-danger">{errors.label}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-8">
                      <InputCheckbox type="checkbox" id="toggle" checked={TaxFormData.is_active === 1} onChange={handleToggleChange} />
                      <label htmlFor="toggle" className="form-check-label"> {TaxFormData.is_active === 1 ? "Active" : "Inactive"}</label>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Taxcreate;
