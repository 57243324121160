import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import UploadImage from "../../assets/images/defimg.jpeg";
import InputCheckbox from "../Inputcheckbox";
import Input from "../Input";
import SingleImageSelect from "../Media/SingleImageSelect";
import {
  BrandDropDown,
  CategoriesDropDown,
  DiscountDropDown,
  MediaList,
  TaxDropDown,
} from "../../service/apiService";
import { ValidateBrand, ValidateCategory, ValidatePrice, ValidateProductName, ValidateQuantity, ValidateSku, ValidateTax, ValidateUnit, ValidateVariant, ValidateWeight, returnDate, validateAddress, validateSlug, validateStock } from "../../validation/CustomValidation";
import { CustomValidationError } from "../../Helper/Helper";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const ProductForm = ({ onSuccess }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [discountList, setDiscountList] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const token = localStorage.getItem("admin_token");
  const AppUrl = "https://folcon.elegra.in/api/files/";
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState('');
  const [specification, setSpecification] = useState('');


  useEffect(() => {
    if (token) {
      const response = MediaList(token);
      response.then((response) => {
        const imageUrls = response.data.filter(
          (url) =>
            url.endsWith(".jpg") ||
            url.endsWith(".png") ||
            url.endsWith(".jpeg") ||
            url.endsWith(".gif")
        );
        setImageUrl(imageUrls);
      });

      CategoryDropdownList();
      BrandDropDownList();
      TaxDropDownList();
      DiscountDropDownList();
    }
  }, [token]);
  const [productformData, setProductFormData] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_have_variant: "",
    unit: "",
    weight: "",
    returning_date: "",
    is_active: 1,
  });
  const [validationErrors, setValidationErrors] = useState({
    product_name: "",
    description: "",
    specification: "",
    sku: "",
    quantity: "",
    minimum_stock_warning: "",
    price: "",
    discount: "",
    tax: "",
    category: "",
    brand: "",
    slug: "",
    is_have_variant: "",
    unit: "",
    weight: "",
    returning_date: "",
    is_active: 1,
  });


  const generateSlug = (name) => {
    return name
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '')
      .replace(/\s+/g, '-');
  };

  const handleChange = (e) => {
    const { target: { id, value, dataset } = {} } = e;
    const capitalizeWords = (text) => {
      return text.replace(/([^\w\s])/gi, (match) => match.replace(/\s+/g, '')).replace(/\b\w+\b/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    };

    const transformedValue = (id === "product_name") ? capitalizeWords(value) : value;
    let updatedFormData = {
      ...productformData,
      [id]: value,
    };

    if (id === 'product_name') {
      updatedFormData = {
        ...updatedFormData,
        [id]: transformedValue,
        slug: generateSlug(value),
      };
    }
    setProductFormData(updatedFormData);
  };
  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setProductFormData({
      ...productformData,
      is_active: activeValue
    });
  };
  const handlePreOrderChange = (e) => {
    const isChecked = e.target.checked;
    const preOrderValue = isChecked ? 1 : 0;
    
    setProductFormData({
      ...productformData,
      is_pre_order: preOrderValue,
      quantity: isChecked ? 0 : productformData.quantity,
    });
  };

  const CategoryDropdownList = async () => {
    const CategoriesDropDownList = await CategoriesDropDown(token);
    setCategoryList(CategoriesDropDownList.data);
  };
  const BrandDropDownList = async () => {
    const BrandDropDownList = await BrandDropDown(token);
    setBrandList(BrandDropDownList.data);
  };
  const TaxDropDownList = async () => {
    const TaxDropDownList = await TaxDropDown(token);
    setTaxList(TaxDropDownList.data);
  }
  const DiscountDropDownList = async () => {
    const DiscountDropDownList = await DiscountDropDown(token);
    setDiscountList(DiscountDropDownList.data);
  }

  const handleImageClick = (index) => {
    if (selectedImages.includes(index)) {
      if (selectedImages.length > 0) {
        setSelectedImages(selectedImages.filter((i) => i !== index));
      }

    } else {
      setSelectedImages([index]);
      setPreviewImage(AppUrl + imageUrl[index]);
      closeModal();
    }
  };

  const handleDeletePreview = () => {
    setPreviewImage(null);
    setSelectedImages([]);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');
    const productNameError = ValidateProductName(productformData.product_name);
    const skuError = ValidateSku(productformData.sku);
    const priceError = ValidatePrice(productformData.price);
    const taxError = ValidateTax(productformData.tax);
    const brandError = ValidateBrand(productformData.brand);
    const categoryError = ValidateCategory(productformData.category);
    const quantityError = ValidateQuantity(productformData.quantity);
    const unitError = ValidateUnit(productformData.unit);
    const varientError = ValidateVariant(productformData.is_have_variant);
    const weightError = ValidateWeight(productformData.weight);
    const slugError = validateSlug(productformData.slug);
    const stockCountError = validateStock(productformData.minimum_stock_warning);
    const returningDateError = returnDate(productformData.returning_date);
    setValidationErrors({
      product_name: productNameError,
      sku: skuError,
      price: priceError,
      tax: taxError,
      brand: brandError,
      category: categoryError,
      quantity: quantityError,
      minimum_stock_warning: stockCountError,
      // unit: unitError,
      //is_have_variant: varientError,
      weight: weightError,
      slug: slugError,
      returning_date: returningDateError
    });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {

      if (selectedFile) {
        const formDataToSend = new FormData();
        formDataToSend.append('file_name', selectedFile);

        const productuploadResponse = await axios.post('https://folcon.elegra.in/admin/media-upload', formDataToSend, config);

        if (productuploadResponse.data && productuploadResponse.data.data) {
          imageUrl = productuploadResponse.data.data;
        } else {
          toast.error('Error uploading image. Please try again.');
          return;
        }
      }
      const quantity = parseInt(productformData.quantity, 10);
      const minimum_stock_warning = parseInt(productformData.minimum_stock_warning, 10);
      const price = parseInt(productformData.price, 10);
      const discount = parseInt(productformData.discount, 10);
      const tax = parseInt(productformData.tax, 10);
      // temporty data
      const category = parseInt(productformData.category, 10);
      const brand = parseInt(productformData.brand, 10);
      const is_have_variant = parseInt(productformData.is_have_variant, 10);
      const unit = parseInt(productformData.unit, 10);
      const weight = parseInt(productformData.weight, 10);
      const returning_date = parseInt(productformData.returning_date, 10);





      const createPayload = {
        ...productformData,
        image_path: previewImage.split('/').pop(),
        product_name: productformData.product_name,
        quantity: quantity,
        minimum_stock_warning: minimum_stock_warning,
        price: price,
        discount: discount,
        tax: tax,
        // temporty data
        category: category,
        brand: brand,
        is_have_variant: 1,
        unit: 1,
        weight: weight,
        returning_date: returning_date,
        description: description,
        specification: specification

      };

      if (!productNameError
        && !skuError
        && !priceError
        && !brandError
        && !categoryError
        && !quantityError
        && !stockCountError
        && !taxError
        && !weightError
        && !returningDateError
      ) {
        const productcreateResponse = await axios.post('https://folcon.elegra.in/admin/create-product', createPayload, config);
        if (productcreateResponse.data.status === "success") {
          onSuccess(productcreateResponse.data.data.ID);
          toast.success('Product created successfully!');
        } else {
          toast.error('Product creation failed. Please try again.');
        }
      }

    } catch (error) {
      console.error('Error creating product:', error);
      toast.error('Product creation failed. Please try again.');
    }
  };
  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <form className="row g-3">
            <div className="col-md-6">
              <label htmlFor="inputEmail5" className="form-label">
                Product Name
              </label>
              <Input
                type="text"
                placeholder="Type here"
                className="form-control"
                id="product_name"
                value={productformData.product_name}
                onChange={handleChange}
              />
              <CustomValidationError error={validationErrors.product_name} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Brand
              </label>
              <select
                id="brand"
                className="form-select"
                value={productformData.brand}
                onChange={handleChange}
              >
                {!brandList ? (
                  <option value="">No Data...</option>
                ) : brandList.length === 0 ? (
                  <option value="">No brand found</option>
                ) : (
                  <>
                    <option value="">Select brand</option>
                    {brandList.map((brand) => (
                      <option key={brand.id} value={brand.id}>
                        {brand.brand_name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}

              </select>
              <CustomValidationError error={validationErrors.brand} />
            </div>
            <div className="col-md-6">
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                PreOrder Enable
              </label>
              <div className="form-check form-switch">
                <InputCheckbox className="form-check-input" type="checkbox" id="toggle" checked={productformData.is_pre_order === 1} onChange={handlePreOrderChange}
                defaultChecked={false}
                />
              </div>
            </div>  
            <div className="col-md-12">
              <label htmlFor="inputPassword5" className="form-label">
                Description
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                config={{
                  toolbar: [
                    'heading', '|',
                    'bold', 'italic', 'blockQuote', '|',
                    'numberedList', 'bulletedList', '|',
                    'undo', 'redo'
                  ],
                }}
              />

              {/* <Input type="text" placeholder="Type here" className="form-control" id="description" value={productformData.description} onChange={handleChange} /> */}
              <CustomValidationError error={validationErrors.description} />
            </div>

            <div className="col-md-6=12">
              <label htmlFor="inputPassword5" className="form-label">
                Specification
              </label>
              <CKEditor
                editor={ClassicEditor}
                data={specification}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSpecification(data);
                }}
                config={{
                  toolbar: [
                    'heading', '|',
                    'bold', 'italic', 'blockQuote', '|',
                    'numberedList', 'bulletedList', '|',
                    'undo', 'redo'
                  ],
                }}
              />
              {/* <Input type="text" placeholder="Enter specification" className="form-control" id="specification" value={productformData.specification} onChange={handleChange} /> */}
            </div>
            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Category
              </label>
              <select
                id="category"
                className="form-select"
                value={productformData.category}
                onChange={handleChange}
              >
                {!categoryList ? (
                  <option value="">No Data...</option>
                ) : categoryList.length === 0 ? (
                  <option value="">No category found</option>
                ) : (
                  <>
                    <option value="">Select Category</option>
                    {categoryList.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.category_name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
              <CustomValidationError error={validationErrors.category} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Slug
              </label>
              <input type="text" placeholder="Type here" name="slug" className="form-control readonly" id="slug" value={productformData.slug} onChange={handleChange} readOnly />
              <CustomValidationError error={validationErrors.slug} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Sku
              </label>
              <Input type="text" placeholder="Type here" className="form-control" id="sku" value={productformData.sku} onChange={handleChange} />
              <CustomValidationError error={validationErrors.sku} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Is have Variant
              </label>
              <input type="text" placeholder="Type here" className="form-control" id="is_have_variant" value={1} onChange={handleChange} readOnly />
              <CustomValidationError error={validationErrors.is_have_variant} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Quantity
              </label>
              <input type="text" placeholder="Type here" name="quantity" className="form-control" id="quantity" value={productformData.quantity} onChange={handleChange}
               disabled={productformData.is_pre_order === 1}
              />
              <CustomValidationError error={validationErrors.quantity} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Unit
              </label>
              <input type="text" placeholder="Type here" className="form-control" id="unit" value={1} onChange={handleChange} readOnly />
              <CustomValidationError error={validationErrors.unit} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Minimum Stock Warning
              </label>
              <Input type="text" placeholder="Type here" className="form-control" id="minimum_stock_warning" value={productformData.minimum_stock_warning} onChange={handleChange} />
              <CustomValidationError error={validationErrors.minimum_stock_warning} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Weight
              </label>
              <Input type="text" placeholder="Type here" className="form-control" id="weight" value={productformData.weight} onChange={handleChange} />
              <CustomValidationError error={validationErrors.weight} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Price
              </label>
              <Input type="text" placeholder="Type here" className="form-control" id="price" value={productformData.price} onChange={handleChange} />
              <CustomValidationError error={validationErrors.price} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputPassword5" className="form-label">
                Returning Date
              </label>
              <Input type="text" placeholder="Type here" className="form-control" id="returning_date" value={productformData.returning_date} onChange={handleChange} />
              <CustomValidationError error={validationErrors.returning_date} />
            </div>
            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Discount
              </label>
              <select
                id="discount"
                className="form-select"
                value={productformData.discount}
                onChange={handleChange}
              >
                {!discountList ? (
                  <option value="">No Data...</option>
                ) : discountList.length === 0 ? (
                  <option value="">No Discount found</option>
                ) : (
                  <>
                    <option value="">Select Discount</option>
                    {discountList.map((discount) => (
                      <option key={discount.id} value={discount.id}>
                        {discount.name.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>
            <div className="col-md-6">
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                Status
              </label>
              <div className="form-check form-switch">
                <InputCheckbox className="form-check-input" type="checkbox" id="toggle" checked={productformData.is_active === 1} onChange={handleToggleChange}  defaultChecked={true} />
              </div>
            </div>

            <div className="col-md-6">
              <label htmlFor="inputState" className="form-label">
                Tax
              </label>
              <select
                id="tax"
                className="form-select"
                value={productformData.tax}
                onChange={handleChange}
              >
                {!taxList ? (
                  <option value="">No Data...</option>
                ) : taxList.length === 0 ? (
                  <option value="">No tax found</option>
                ) : (
                  <>
                    <option value="">Select Tax</option>
                    {taxList.map((tax) => (
                      <option key={tax.id} value={tax.id}>
                        {tax.label.toUpperCase()}
                      </option>
                    ))}
                  </>
                )}
              </select>
              <CustomValidationError error={validationErrors.tax} />
            </div>
           
            <div className="col-md-6">
              <label>Thumbnail Image</label>
              <br />
              {previewImage ? (
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={previewImage}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      width: "200px",
                      height: "200px",
                    }}
                  />
                  <button
                    type="button"
                    onClick={handleDeletePreview}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              ) : (
                <img src={UploadImage} alt="Alt" />
              )}
              <br></br>
              <div className="d-flex justify-content-center mt-3">
                <button
                  type="button"
                  className="btn btn-primary mt-2 btn-sm btn-block"
                  onClick={openModal}
                >
                  Select Image
                </button>
              </div>
            </div>
            
            <hr></hr>
            <div className="text-center">
              <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <SingleImageSelect
        isOpen={modalIsOpen}
        handleClose={closeModal}
        handleImageClick={handleImageClick}
        selectedImages={selectedImages}
        imageUrl={imageUrl}
        token={token}
        setImageUrl={setImageUrl}

      />
    </div>
  );
};

export default ProductForm;
