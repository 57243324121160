import React from 'react';
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import SellerList from "../../pages/views/Seller/Allseller";


const Seller = () => {
    return (
        <div>
        <AdminHeader/>
        <SideBar/>
        <main className="main-wrap">
            <SellerList/>      
        </main>
        <AdminFooter/>
     </div>
    )

}


export  default  Seller;