import React from 'react';

const Input = ({ label, type , name, value, onChange, placeholder,id, disabled }) => {
  return (
    <div className="form-group">
      {/* {label && <label htmlFor={name}>{label}</label>} */}
      <input
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="form-control"
        disabled={disabled}
      />
    </div>
  );
};

export default Input;