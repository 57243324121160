import React from "react";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader"
import SideBar from "../../pages/views/Dashboard/SideBar"
import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import SubPageCreate from "../../pages/views/SubPages/SubPageCreate";

const MainSubListCreate = () => {
    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main>
                <SubPageCreate />
            </main>
            <AdminFooter />
        </div>
    )
}


export default MainSubListCreate;