import React, { useState, useEffect } from "react";
import ReactDataTable from "../../../components/DataTable";
import { InputText } from 'primereact/inputtext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStreetView, faCircleXmark, faSave,faEdit } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-modal';
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from 'react-paginate';

import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";
import { useNavigate } from 'react-router-dom';
import {GetchatbotEnquiryList,ChatbotcategorryDropdown} from "../../../service/apiService";






const ChatbotEnquiryList = () => {
    const navigate = useNavigate();
    const [subPages, setSubPages] = useState([]);

   
    const [selectedPageId, setSelectedPageId] = useState(null);

    const [ChatbotCategoryList, setChatbotCategoryList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const [editingId, setEditingId] = useState(null);
    const [editingValue, setEditingValue] = useState("");
    const [faqFields, setfaqFields] = useState([{ id: null, dropdownValue: "" }]);    

    const token = localStorage.getItem("admin_token");

    useEffect(() => {
        fetchData();
        fetchSubpageData();

    }, []);

    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 10;

    const pageCount = Math.ceil(ChatbotCategoryList?.length / itemsPerPage);
    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };
    const currentChatbotCategoryList = ChatbotCategoryList?.slice(pageNumber * itemsPerPage, (pageNumber + 1) * itemsPerPage)





    const fetchData = async () => {
        try {
          const token = localStorage.getItem("admin_token");
          const response = await GetchatbotEnquiryList(token);
         // alert(response);
          setChatbotCategoryList(response.data);
        } catch (error) {
          console.log(error);
        }
      };
   
      const fetchSubpageData = async () => {
        try {
          const response = await ChatbotcategorryDropdown(token);
         
          setSubPages(response.data);
        } catch (error) {
          console.log(error);
        }
      };
    
    const handleEdit = (id, value,campaign_id) => {
        setEditingId(id);
        setSelectedPageId(campaign_id);
        setEditingValue(value);
      
      };


      const getCategoryNameById = (id) => {
        const category = subPages.find((page) => page.id === id);
        return category ? category.name : "Unknown Category";
    };

    const handleSubmit = async (id) => {
        try {
          const settingToUpdate = ChatbotCategoryList.find((setting) => setting.id === id);
          if (!settingToUpdate) {
            throw new Error("Setting not found");
          }
    
          let updatedSetting;
          const parsedCategoryId = parseInt(selectedPageId, 10);

            updatedSetting = {
              id: settingToUpdate.id,
              category_id: parsedCategoryId,
              name: editingValue,
             
            };
        
    
          const csrfToken = localStorage.getItem("csrfToken");
    
          const response = await axios.post(
            "https://folcon.elegra.in/admin/update-chatbot-enquiry",
            updatedSetting,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                 "X-CSRF-Token": csrfToken,          },
            }
          );
    
          if (response.status === 200) {
            
            setChatbotCategoryList((prevSettings) =>
              prevSettings.map((setting) =>
                setting.id === id
                  ? {
                      ...setting, 
                      category_id:updatedSetting.category_id,
                      name: updatedSetting.name,
                    }
                  : setting
              )
            );
            toast.success(response.data.message || "Chatbot Cetegory updated successfully!");
            setEditingId(null); // Reset editing state
          } else {
            toast.error("Failed to update Chatbot Cetegory.");
          }
        } catch (error) {
          console.log("API Error:", error);
          toast.error("Failed to update Chatbot Cetegory.");
        }
      };




    const header = (


        <div className="table-header">
            <div>
                <h3>Enquiry List</h3>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={globalFilter}
                            onInput={(e) => setGlobalFilter(e.target.value)}
                            placeholder="Search..."
                        />
                    </span>
                </div>
            </div>
            <div className="create-category-button">
                <button onClick={() => navigate('/admin/chatbot/enquiry-create')} className="btn btn-primary">Create FAQ</button>
            </div>
        </div>
    );



    const columns = [
        { field: 'id', header: 'ID' },
        { field: 'category_id', header: 'Category Name',
            body: (rowData) => {

                if (editingId === rowData.id) {
                    return (
                        <>
                        <select
                        value={selectedPageId}
                        onChange={(e) => setSelectedPageId(e.target.value)}
                      >
                        <option value="">Select Category</option>
                        {subPages && subPages.map((page) => (
                          <option key={page.id} value={page.id} selected={rowData.category_id == page.id}>
                            {page.name}
                          </option>
                        ))}
                      </select>
                      

                         </>
                    );
                } else {
                    return getCategoryNameById(rowData.category_id);
                }

            }




        },
        { field: 'name', header: 'Name',
           sortable: true,
            body: (rowData) => {
                if (editingId === rowData.id) {
                    return (
                        <>
                       
                        <InputText
                            value={editingValue}
                            onChange={(e) => setEditingValue(e.target.value)}
                        />

                         </>
                    );
                } else {
                    return rowData.name; // Render the name normally when not in edit mode
                }
                
            }
         },
        
      
        {
            field: 'action', header: 'Action', body: (rowData) => (
                <div className="text-end">
                <div className="dropdown">
                    <a
                        href="#"
                        data-bs-toggle="dropdown"
                        className="btn btn-light rounded btn-sm font-sm"
                    >
                        <FontAwesomeIcon icon={faStreetView} />
                    </a>
                    <div className="dropdown-menu">
                        {editingId === rowData.id ? (
                            <a
                                className="dropdown-item edit-icon"
                                onClick={() => handleSubmit(rowData.id)}
                                style={{ cursor: "pointer" }}
                            >
                                <FontAwesomeIcon icon={faSave} /> Save
                            </a>
                        ) : (
                            <a
                                className="dropdown-item edit-icon"
                                onClick={() => handleEdit(rowData.id, rowData.name,rowData.category_id)}
                                style={{ cursor: "pointer" }}
                            >
                                <FontAwesomeIcon icon={faEdit} /> Edit
                            </a>
                        )}
                    </div>
                </div>
            </div>
            )
        }
    ];

    return (
        <>
        
        <main id="main" className="main">
        <AdminHeader /><SideBar />  
            <div className="pagetitle">
                <h1>Chatbot Enquiry list</h1>
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li className="breadcrumb-item">Chatbot Enquiry</li>
                        <li className="breadcrumb-item active">List</li>
                    </ol>
                </nav>

            </div>
            <section className="section">


                <ReactDataTable
                    data={currentChatbotCategoryList}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    header={header}
                    emptyMessage="No Faq records found"
                    columns={columns}
                />
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />

            </section>
          

            <AdminFooter />      
               
            
</main>

         </>
    );
};

export default ChatbotEnquiryList;
