import AdminFooter from "../../pages/views/Dashboard/AdminFooter";
import AdminHeader from "../../pages/views/Dashboard/AdminHeader";
import SideBar from "../../pages/views/Dashboard/SideBar";
import SubPageList from "../../pages/views/SubPages/SubPageList";

export default function MainSubListPage() {
    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main>
                <SubPageList />
            </main>
            <AdminFooter />
        </div>
    )
}