import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {createFaq,FaqcategorryDropdown} from "../../../../service/apiService";

import { useNavigate } from "react-router-dom";


const FaqCreate = () => {
  const [faqformData, setfaqFormData] = useState({
   
    title: '',
    description: '',
    is_active: 1
  });
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    description: ""
  });
  var navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState(null);

  const token = localStorage.getItem("admin_token");
  const [faqFields, setfaqFields] = useState([{ id: null, dropdownValue: "" }]);
  const [faqList, setfaqList] = useState([]);
  useEffect(() => {
    faqDropDownList();
  }, []);

  const handleChangeDropdowns = (index, value) => {
    const updatedFields = [...faqFields];
    updatedFields[index].dropdownValue = value;
    updatedFields[index].id = parseInt(value, 10); // Convert to integer
    setfaqFields(updatedFields);
  };
  
  const faqDropDownList = async () => {
    try {
      const response = await FaqcategorryDropdown(token);
      setfaqList(response.data);
    } catch (error) {
      console.error("Error fetching faq list:", error);
    }
  };





  const handleChange = (e) => {
    const { id, value } = e.target;
    setfaqFormData({
      ...faqformData,
      [id]: value,
      
    });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setfaqFormData({
      ...faqformData,
      is_active: activeValue
    });
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
     if (faqFields[0].dropdownValue !== "") {
      
    const token = localStorage.getItem('admin_token');
    // const csrfToken = localStorage.getItem('csrfToken');
    const errors = {};
    if (!faqformData.title) {
      errors.title = "Title is required";
      errors.description = "Description is required";
    }

    setValidationErrors(errors);
    const faqcategory_id = faqFields[0]?.id || null;

    // const config = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'X-CSRF-Token': csrfToken,
    //   },
    // };

    try {
      if (!faqformData.title || !faqformData.description) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const payload = {
        ...faqformData,
        faq_category_id: faqcategory_id,
        title: faqformData.title,
        description: faqformData.description,
      };

      const createResponse = await createFaq(token, payload);
      if (createResponse.status === "success") {

        toast.success('FAQ created successfully!');
       // navigate('/admin/faqCategories');
        setfaqFormData({
            faq_category_id:'',
            title: '',
          description: '',
          is_active: 1
        });
      } else {
        toast.error('FAQ creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating FAQ:', error);
      toast.error('FAQ creation failed. Please try again.');
    }

}

else {
    toast.error("Please select faq category or enter value");
  }

  };


  return (
    <main id="main" className="main">
     
      <div className="pagetitle">
        <h1>FAQ Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">FAQ</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Faq Category</label>
                    <div className="col-sm-4">
                    {faqFields.map((faq, index) => (
                     
                       
                        
                            <select
                              id={`faq-dropdown-${index}`}
                              className="form-select"
                              value={faq.dropdownValue}
                              onChange={(e) => handleChangeDropdowns(index, e.target.value)}
                            >
                              {!faqList ? (
                                <option value="">No Data...</option>
                              ) : faqList.length === 0 ? (
                                <option value="">No faq found</option>
                              ) : (
                                <>
                                  <option value="">Select faq</option>
                                  {faqList.map((faqItem) => (
                                    <option key={faqItem.id} value={faqItem.id}>
                                      {faqItem.name.toUpperCase()}
                                    </option>
                                  ))}
                                </>
                              )}
                            </select>
                        
                       
                    ))}
                    
                      

                    </div>
                    </div>
                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Title</label>
                    <div className="col-sm-4">
                      <input id="title" type="text" value={faqformData.title} placeholder="Type here" onChange={handleChange}  className="form-control" />
                      {validationErrors.title && <div className="text-danger">{validationErrors.title}</div>}

                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">Description</label>
                    <div className="col-sm-4">
                      <input id="description" type="text" value={faqformData.description} placeholder="Type here" onChange={handleChange} className="form-control"  />
                      {validationErrors.description  && <div className="text-danger">{validationErrors.description}</div>}

                    </div>
                  </div>
                  <div className="md-4">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-4">
                      <input className="form-check-input" type="checkbox" id="toggle" checked={faqformData.is_active === 1} onChange={handleToggleChange} />
                    </div>
                  </div>



                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

      </section>
    
    </main>

  )



}


export default FaqCreate