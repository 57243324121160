import React, { useState } from "react";
import "../../assets/css/style.css";
import "../../assets/css/owl.carousel.min.css";
import "../../assets/css/owl.theme.default.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
const HomePage = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [isOfferVisible, setIsOfferVisible] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const toggleOffer = () => {
    setIsOfferVisible(!isOfferVisible);
  };
  const togglePopup = () => {
    setIsShowPopup(!isShowPopup);
  };
  return (
    <>
      <header className="main_header">
        <nav className="navbar navbar-expand-lg navbar-light shadow-sm">
          <div className="container-fluid">
            <div>
              {/* for responsive menu button */}
              <button id="main_side_bar" className="d-sm-none">
                <i className="fa-solid fa-bars" />
              </button>
              {/* logi */}
              <a className="navbar-brand me-5" href="/">
                <img
                  src={require("../../assets/img/logo.png")}
                  alt="Logo"
                  className="img-fluid"
                  width={60}
                />
              </a>
              {/* for responsive screen */}
            </div>
            <div
              className="collapse navbar-collapse menu_part"
              id="navbarTogglerDemo02"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-left-opt">
                {/* single menu */}
                <li className="nav-item menu-item menu-item-one has-submenu">
                  <a
                    className="nav-link active menu-anchor"
                    aria-current="page"
                    href="/"
                  >
                    One
                  </a>
                  <div className="submenu">
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* single menu */}
                <li className="nav-item menu-item menu-item-two has-submenu">
                  <a
                    className="nav-link menu-anchor"
                    aria-current="page"
                    href="/"
                  >
                    Two
                  </a>
                  <div className="submenu">
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* single menu */}
                <li className="nav-item menu-item menu-item-three has-submenu">
                  <a
                    className="nav-link menu-anchor"
                    aria-current="page"
                    href="/"
                  >
                    Three
                  </a>
                  <div className="submenu">
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* single menu */}
                <li className="nav-item menu-item menu-item-four has-submenu">
                  <a
                    className="nav-link menu-anchor"
                    aria-current="page"
                    href="/"
                  >
                    Four
                  </a>
                  <div className="submenu">
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                {/* single menu */}
                <li className="nav-item menu-item menu-item-five has-submenu">
                  <a
                    className="nav-link menu-anchor"
                    aria-current="page"
                    href="/"
                  >
                    Five
                  </a>
                  <div className="submenu">
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* single column */}
                    <div className="sub_menu_par">
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                      {/* sub menu */}
                      <div className="sub_menu_par_sing">
                        <h6 className="submenu-heading">Heading 1</h6>
                        <ul className="list-unstyled">
                          <li className="submenu-item">
                            <a href="#">Web Dev</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">Graphic</a>
                          </li>
                          <li className="submenu-item">
                            <a href="#">SEO</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              {/* search form */}
              <form className="search_form">
                <div className="search">
                  <i className="fa-solid fa-magnifying-glass" />
                  <input
                    type="text"
                    placeholder="Search for product, brands and more"
                  />
                </div>
              </form>
              {/* buttons */}
              <div className="user-options">
                <ul className="list-unstyled d-flex align-items-center">
                  <li>
                    <i className="fa-regular fa-user" />
                    <span>Profile</span>
                  </li>
                  <li>
                    <i className="fa-regular fa-heart" />
                    <span>Wishlist</span>
                  </li>
                  <li>
                    <i className="bx bx-shopping-bag" />
                    <span>Bag</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* buttons */}
            <div className="user-options d-sm-none">
              <ul className="list-unstyled d-flex align-items-center">
                <li>
                  <a href="#" className="search_btn">
                    <i className="fa-solid fa-magnifying-glass" />
                  </a>
                  <div className="search_window">
                    <a href="#" className="cls_search_window">
                      <i className="fa-solid fa-arrow-left" />
                    </a>
                    <input
                      type="text"
                      placeholder="Search for brands and products"
                    />
                    <a href="#">
                      <i className="fa-solid fa-magnifying-glass" />
                    </a>
                  </div>
                </li>
                <li>
                  <i className="fa-regular fa-user" />
                </li>
                <li>
                  <i className="fa-regular fa-heart" />
                </li>
                <li>
                  <i className="bx bx-shopping-bag" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {/* header ends here */}
      {/* responsive sidebar starts here */}
      <div className="main_side_bar_bg">
        <div className="main_side_bar">
          {/* top banner */}
          <div className="m_s_top">
            <img
              src={require("../../assets/img/main_sidebar.webp")}
              alt=""
              className="img-fluid"
            />
          </div>
          {/* middle menu */}
          <div className="m_s_middle">
            {/* menu starts here */}
            <ul className="m_s_1_p">
              <li className="has_sub">
                <h6>Men</h6>
                <ul className="menu_content">
                  <li className="has_sub">
                    <h6>Top Wear</h6>
                    <ul className="menu_content">
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>don't have sub</h6>
                  </li>
                </ul>
              </li>
              <li>
                <h6>don't have sub</h6>
              </li>
              <li>
                <h6>don't have sub</h6>
              </li>
              <li>
                <h6>don't have sub</h6>
              </li>
              <li>
                <h6>don't have sub</h6>
              </li>
              <li>
                <h6>don't have sub</h6>
              </li>
              <li className="has_sub">
                <h6>Men</h6>
                <ul className="menu_content">
                  <li className="has_sub">
                    <h6>Top Wear</h6>
                    <ul className="menu_content">
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                      <li>
                        <h6>T-shirts</h6>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>don't have sub</h6>
                  </li>
                </ul>
              </li>
            </ul>
            {/* responsive sidebar ends here */}
          </div>
          {/* bottom menu */}
          <div className="m_s_bottom">
            <ul>
              <li>
                <a href="">
                  Myntra Studio <span className="badge">New</span>
                </a>
              </li>
              <li>
                <a href="">
                  Myntra Mall <span className="badge">New</span>
                </a>
              </li>
              <li>
                <a href="">Myntra Insider </a>
              </li>
              <li>
                <a href="">Gift Card</a>
              </li>
              <li>
                <a href="">Gift Card</a>
              </li>
              <li>
                <a href="">Gift Card</a>
              </li>
              <li>
                <a href="">Gift Card</a>
              </li>
              <li>
                <a href="">Gift Card</a>
              </li>
            </ul>
          </div>
          {/* bottom banner */}
          <div className="m_s_btm_img">
            <img
              src={require("../../assets/img/main_sidebar_btm.webp")}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="main_side_bar_overlay" />
      <main>
        {/* Top banner starts here */}
        <section className="top_banner">
          <div className="container-fluid">
            {/* ============= for desktop ================= */}
            <img
              src={require("../../assets/img/top_banner/top-banner.webp")}
              alt=""
              className="img-fluid d-none d-sm-block"
            />
            {/* ============= for mobile ================== */}
            <img
              src="./assets/img/responsive.jpg"
              alt=""
              className="img-fluid d-sm-none"
            />
          </div>
        </section>
        {/* Top banner Ends here */}
        {/* Home banner starts here */}
        <section className="hero_banner section_padding">
          <div className="container-fluid">
            {/* ============== for desktop =============== */}
            <div className="owl-carousel owl-theme d-none d-sm-block">
              {/* single banner */}
              <Slider {...settings}>
                <div className="item">
                  <img
                    src={require("../../assets/img/hero/hero.png")}
                    alt="Hero banner"
                    className="img-fluid"
                  />
                </div>
                <div className="item">
                  <img
                    src={require("../../assets/img/hero/hero.png")}
                    alt="Hero banner"
                    className="img-fluid"
                  />
                </div>
              </Slider>
            </div>
            {/* ============== for mobile ============ */}
            <div className="owl-carousel owl-theme d-sm-none">
              {/* single banner */}
              <Slider {...settings}>
                <div className="item">
                  <img
                    src={require("../../assets/img/responsive.jpg")}
                    alt="Hero banner"
                    className="img-fluid"
                  />
                </div>
                <div className="item">
                  <img
                    src={require("../../assets/img/responsive.jpg")}
                    alt="Hero banner"
                    className="img-fluid"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/* Home banner Ends here */}
        {/* section 3 starts here */}
        <section className="section_three section_padding">
          <div className="container-fluid">
            {/* ============== For Desktop ================ */}
            {/* header */}
            <div className="row">
              <div className="col-md-12 d-none d-sm-block">
                <img
                  src={require("../../assets/img/s_3/hero-code.webp")}
                  alt="heading"
                  className="img-fluid"
                />
              </div>
            </div>
            {/* images */}
            <div className="row">
              <div className="col-md-6 d-none d-sm-block ">
                <img
                  src={require("../../assets/img/s_3/below-code-left.webp")}
                  alt="heading"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-6 d-none d-sm-block">
                <img
                  src={require("../../assets/img/s_3/below-code-right.webp")}
                  alt="heading"
                  className="img-fluid"
                />
              </div>
            </div>
            {/* ================ For Responsive =================== */}
            <div className="row d-sm-none">
              {/* single image */}
              <div className="col-12">
                <img
                  src={require("../../assets/img/responsive.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* section 3 Ends here */}
        {/* section 4 Banner Starts here */}
        <section className="section_five_bann section_padding">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/* =========== For Desktop ========== */}
                <img
                  src={require("../../assets/img/s_4/bottom-banner.webp")}
                  alt="heading"
                  className="img-fluid d-none d-sm-block"
                />
                {/* =========== For responsive ========== */}
                <img
                  src={require("../../assets/img/responsive.jpg")}
                  alt=""
                  className="img-fluid d-sm-none"
                />
              </div>
            </div>
          </div>
        </section>
        {/* section 4 Banner Ends here */}
        {/* section 4 starts here */}
        <section className="section_four section_padding">
          <div className="container-fluid">
            {/* images starts here */}
            <div className="row px-2 justify-content-center text-center">
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 p-0">
                <img
                  src={require("../../assets/img/s_4/one.webp")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* section 4 Ends here */}
        {/* section 5 starts here */}
        <section className="section_five section_padding">
          <div className="container-fluid">
            {/* header */}
            <div className="row">
              <div className="col-md-12">
                <img
                  src={require("../../assets/img/s_5/category.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            {/* images */}
            <div className="row justify-content-center text-center">
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-xl-2 col-lg-4 col-md-3 col-12 mt-4">
                <img
                  src={require("../../assets/img/s_5/single_img.png")}
                  alt="single image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* section 5 Ends here */}
        {/* section 6 starts here */}
        <section className="section_six section_padding">
          <div className="container-fluid">
            {/* =========== for desktop ============ */}
            <div className="row">
              {/* single image */}
              <div className="col-md-6 d-none d-sm-block">
                <img
                  src={require("../../assets/img/s_3/below-code-left.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>
              {/* single image */}
              <div className="col-md-6 d-none d-sm-block">
                <img
                  src={require("../../assets/img/s_3/below-code-right.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            {/* ========== for responsive ============= */}
            <div className="row d-sm-none">
              {/* single image */}
              <div className="col-12">
                <img
                  src={require("../../assets/img/responsive.jpg")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* section 6 ends here */}
      </main>
      {/* Footer starts here */}
      <footer className="section_padding">
        <div className="container">
          <div className="row">
            {/* responsive button */}
            <div className="col-md-12 responsive_foot d-sm-none">
              <p>More about Online Shopping at Myntra</p>
            </div>
            {/* inside the content for every screen size */}
            <div className="col-md-12 responsive_foot_content">
              {/* footer links */}
              <div className="row">
                <div className="col-md-2">
                  <div className="footer_links">
                    <h6>ONLINE SHOPPING</h6>
                    <ul>
                      <li>
                        <a href="">one</a>
                      </li>
                      <li>
                        <a href="">two</a>
                      </li>
                      <li>
                        <a href="">three</a>
                      </li>
                      <li>
                        <a href="">four</a>
                      </li>
                      <li>
                        <a href="">five</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="footer_links">
                    <h6>CUSTOMER POLICIES</h6>
                    <ul>
                      <li>
                        <a href="">one</a>
                      </li>
                      <li>
                        <a href="">two</a>
                      </li>
                      <li>
                        <a href="">three</a>
                      </li>
                      <li>
                        <a href="">four</a>
                      </li>
                      <li>
                        <a href="">five</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer_links">
                    <h6>EXPERIENCE OUT APP ON MOBILE</h6>
                    <div className="d-flex">
                      <img
                        src={require("../../assets/img/footer/apple_store.png")}
                        alt=""
                        className="img-fluid"
                        width={160}
                      />
                      <img
                        src={require("../../assets/img/footer/google_play.png")}
                        alt=""
                        className="img-fluid"
                        width={160}
                      />
                    </div>
                    <h6 className="social_head">KEEP IN TOUCH</h6>
                    <div className="d-flex gap-2 social_icons">
                      <a href="">
                        <i className="bx bxl-facebook-square" />
                      </a>
                      <a href="">
                        <i className="bx bxl-instagram-alt" />
                      </a>
                      <a href="">
                        <i className="bx bxl-twitter" />
                      </a>
                      <a href="">
                        <i className="bx bxl-youtube" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer_links">
                    <ul>
                      <li className="d-flex gap-2 align-items-center mb-4 mt-3">
                        <img
                          src={require("../../assets/img/footer/original.png")}
                          alt=""
                          className="img-fluid"
                          width={50}
                        />
                        <div>
                          <h6>100% ORIGINAL</h6>
                          <p>guarantee for all products at myntra.com</p>
                        </div>
                      </li>
                      <li className="d-flex gap-2 align-items-center">
                        <img
                          src={require("../../assets/img/footer/Window-image.png")}
                          alt=""
                          className="img-fluid"
                          width={50}
                        />
                        <div>
                          <h6>Return within 14days</h6>
                          <p>of receiving your order</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* popular searches */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">POPULAR SEARCHES</h6>
                  <div className="popular_search">
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                    <a href="">Makeup</a>
                    <a href=""> Dresses For Girls</a>
                    <a href=""> T-Shirts</a>
                    <a href=""> Sandals</a>
                    <a href="">Headphones</a>
                  </div>
                </div>
              </div>
              {/* copy right */}
              <div className="row mt-4">
                <div className="col-md-4">
                  <p>
                    In case of any concern, <a href="">Contact Us</a>{" "}
                  </p>
                </div>
                <div className="col-md-4">
                  <p>© 2024 www.myntra.com. All rights reserved.</p>
                </div>
                <div className="col-md-4">
                  <p className="text-end">A Flipkart company</p>
                </div>
              </div>
              <hr />
              {/* address */}
              <div className="row">
                <div className="col-md-6">
                  <address>
                    Registered Office Address Buildings Alyssa, Begonia and
                    Clover situated <br />
                    in Embassy Tech Village, Outer Ring Road,
                    <br />
                    Devarabeesanahalli Village, Varthur Hobli,
                    <br /> Bengaluru – 560103, India
                  </address>
                </div>
                <div className="col-md-6 text-end align-self-end">
                  CIN: U72300KA2007PTC041799
                  <br />
                  Telephone: +91-80-61561999
                </div>
              </div>
              <hr />
              {/* single content */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">
                    ONLINE SHOPPING MADE EASY AT MYNTRA
                  </h6>
                  <p>
                    If you would like to experience the best of online shopping
                    for men, women and kids in India, you are at the right
                    place. Myntra is the ultimate destination for fashion and
                    lifestyle, being host to a wide array of merchandise
                    including clothing, footwear, accessories, jewellery,
                    personal care products and more. It is time to redefine your
                    style statement with our treasure-trove of trendy items. Our
                    online store brings you the latest in designer products
                    straight out of fashion houses. You can shop online at
                    Myntra from the comfort of your home and get your favourites
                    delivered right to your doorstep.
                  </p>
                </div>
              </div>
              <hr />
              {/* single content */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">
                    BEST ONLINE SHOPPING SITE IN INDIA FOR FASHION
                  </h6>
                  <p>
                    Be it clothing, footwear or accessories, Myntra offers you
                    the ideal combination of fashion and functionality for men,
                    women and kids. You will realise that the sky is the limit
                    when it comes to the types of outfits that you can purchase
                    for different occasions.
                  </p>
                  <ul className="list-unstyled">
                    <li>
                      <b>1. Smart men’s clothing </b> - At Myntra you will find
                      myriad options in smart formal shirts and trousers, cool
                      T-shirts and jeans, or kurta and pyjama combinations for
                      men. Wear your attitude with printed T-shirts. Create the
                      back-to-campus vibe with varsity T-shirts and distressed
                      jeans. Be it gingham, buffalo, or window-pane style,
                      checked shirts are unbeatably smart. Team them up with
                      chinos, cuffed jeans or cropped trousers for a smart
                      casual look. Opt for a stylish layered look with biker
                      jackets. Head out in cloudy weather with courage in
                      water-resistant jackets. Browse through our innerwear
                      section to find supportive garments which would keep you
                      confident in any outfit.
                    </li>
                    <li>
                      <b>2. Trendy women’s clothing -</b>Online shopping for
                      women at Myntra is a mood-elevating experience. Look hip
                      and stay comfortable with chinos and printed shorts this
                      summer. Look hot on your date dressed in a little black
                      dress, or opt for red dresses for a sassy vibe. Striped
                      dresses and T-shirts represent the classic spirit of
                      nautical fashion. Choose your favourites from among
                      Bardot, off-shoulder, shirt-style, blouson, embroidered
                      and peplum tops, to name a few. Team them up with
                      skinny-fit jeans, skirts or palazzos. Kurtis and jeans
                      make the perfect fusion-wear combination for the cool
                      urbanite. Our grand sarees and lehenga-choli selections
                      are perfect to make an impression at big social events
                      such as weddings. Our salwar-kameez sets, kurtas and
                      Patiala suits make comfortable options for regular wear.
                    </li>
                    <li>
                      <b>3. Smart men’s clothing </b> - At Myntra you will find
                      myriad options in smart formal shirts and trousers, cool
                      T-shirts and jeans, or kurta and pyjama combinations for
                      men. Wear your attitude with printed T-shirts. Create the
                      back-to-campus vibe with varsity T-shirts and distressed
                      jeans. Be it gingham, buffalo, or window-pane style,
                      checked shirts are unbeatably smart. Team them up with
                      chinos, cuffed jeans or cropped trousers for a smart
                      casual look. Opt for a stylish layered look with biker
                      jackets. Head out in cloudy weather with courage in
                      water-resistant jackets. Browse through our innerwear
                      section to find supportive garments which would keep you
                      confident in any outfit.
                    </li>
                    <li>
                      <b>4. Trendy women’s clothing -</b>Online shopping for
                      women at Myntra is a mood-elevating experience. Look hip
                      and stay comfortable with chinos and printed shorts this
                      summer. Look hot on your date dressed in a little black
                      dress, or opt for red dresses for a sassy vibe. Striped
                      dresses and T-shirts represent the classic spirit of
                      nautical fashion. Choose your favourites from among
                      Bardot, off-shoulder, shirt-style, blouson, embroidered
                      and peplum tops, to name a few. Team them up with
                      skinny-fit jeans, skirts or palazzos. Kurtis and jeans
                      make the perfect fusion-wear combination for the cool
                      urbanite. Our grand sarees and lehenga-choli selections
                      are perfect to make an impression at big social events
                      such as weddings. Our salwar-kameez sets, kurtas and
                      Patiala suits make comfortable options for regular wear.
                    </li>
                  </ul>
                </div>
              </div>
              <hr />
              {/* single content */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">
                    ONLINE SHOPPING MADE EASY AT MYNTRA
                  </h6>
                  <p>
                    If you would like to experience the best of online shopping
                    for men, women and kids in India, you are at the right
                    place. Myntra is the ultimate destination for fashion and
                    lifestyle, being host to a wide array of merchandise
                    including clothing, footwear, accessories, jewellery,
                    personal care products and more. It is time to redefine your
                    style statement with our treasure-trove of trendy items. Our
                    online store brings you the latest in designer products
                    straight out of fashion houses. You can shop online at
                    Myntra from the comfort of your home and get your favourites
                    delivered right to your doorstep.
                  </p>
                </div>
              </div>
              <hr />
              {/* single content */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">
                    ONLINE SHOPPING MADE EASY AT MYNTRA
                  </h6>
                  <p>
                    If you would like to experience the best of online shopping
                    for men, women and kids in India, you are at the right
                    place. Myntra is the ultimate destination for fashion and
                    lifestyle, being host to a wide array of merchandise
                    including clothing, footwear, accessories, jewellery,
                    personal care products and more. It is time to redefine your
                    style statement with our treasure-trove of trendy items. Our
                    online store brings you the latest in designer products
                    straight out of fashion houses. You can shop online at
                    Myntra from the comfort of your home and get your favourites
                    delivered right to your doorstep.
                  </p>
                </div>
              </div>
              <hr />
              {/* single content */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">
                    ONLINE SHOPPING MADE EASY AT MYNTRA
                  </h6>
                  <p>
                    If you would like to experience the best of online shopping
                    for men, women and kids in India, you are at the right
                    place. Myntra is the ultimate destination for fashion and
                    lifestyle, being host to a wide array of merchandise
                    including clothing, footwear, accessories, jewellery,
                    personal care products and more. It is time to redefine your
                    style statement with our treasure-trove of trendy items. Our
                    online store brings you the latest in designer products
                    straight out of fashion houses. You can shop online at
                    Myntra from the comfort of your home and get your favourites
                    delivered right to your doorstep.
                  </p>
                </div>
              </div>
              <hr />
              {/* single content */}
              <div className="row">
                <div className="col-md-12">
                  <h6 className="footer_title">
                    ONLINE SHOPPING MADE EASY AT MYNTRA
                  </h6>
                  <p>
                    If you would like to experience the best of online shopping
                    for men, women and kids in India, you are at the right
                    place. Myntra is the ultimate destination for fashion and
                    lifestyle, being host to a wide array of merchandise
                    including clothing, footwear, accessories, jewellery,
                    personal care products and more. It is time to redefine your
                    style statement with our treasure-trove of trendy items. Our
                    online store brings you the latest in designer products
                    straight out of fashion houses. You can shop online at
                    Myntra from the comfort of your home and get your favourites
                    delivered right to your doorstep.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Ends here */}
      {/* notification button starts */}
      <div className="notification_btn bell-icon" onClick={togglePopup}>
        <FontAwesomeIcon icon={faBell} shake />
        {/* <i className="bx bx-bell" /> */}
      </div>

      <div className={` popup notification_popup ${isShowPopup ? "show" : ""}`}>
        <div className="popup-header">
          <h3>
            Latest Offers{" "}
            <span className="close-btn" onClick={togglePopup}>
              ×
            </span>
          </h3>
          <p>
            Powered by <a href="#">iZooto</a>
          </p>
        </div>
        <div className="popup-content">
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
          {/* single offer */}
          <div className="offer">
            <img src="https://via.placeholder.com/50" alt="Offer Image" />
            <div className="offer-details">
              <p>Sip in style</p>
              <span>yesterday</span>
            </div>
          </div>
        </div>
      </div>
      {/* notification button ends */}
      {/* offer popup starts here */}
      <div className={`offer_popup ${isOfferVisible ? "show" : ""}`}>
        <div className="offer_btn" onClick={toggleOffer}>
          <div className="arrow" />
          <h3>FLAT ₹ 300 OFF</h3>
        </div>
        <div className="offer_content">
          <div className="row">
            {/* top side */}
            <div className="col-md-12 px-5 o_c_top">
              <div className="row align-items-center py-4">
                <div className="col-6">
                  <h6>Avail Flat</h6>
                  <h2>300 OFF</h2>
                </div>
                <div className="col-6">
                  <img
                    src={require("../../assets/img/offer_popup.webp")}
                    alt=""
                    className="img-fluid"
                    width={150}
                  />
                </div>
              </div>
              <div className="row bottom">
                <div className="col-6">
                  <h6>
                    Coupon Code: <span>MYNTRA300</span>{" "}
                  </h6>
                  <p>Applicable on your first order</p>
                </div>
                <div className="col-6">
                  <a href="#">SIGN UP NOW &gt;</a>
                </div>
              </div>
            </div>
            {/* bottom side */}
            <div className="col-md-12 o_c_bottom">
              <div className="o_c_b_p">
                <h6>
                  {" "}
                  <i className="fa-solid fa-shirt" /> Genuine Products{" "}
                </h6>
                <h6>
                  {" "}
                  <i className="fa-solid fa-shirt" />
                  Try &amp; Buy{" "}
                </h6>
                <h6>
                  {" "}
                  <i className="fa-solid fa-shirt" />
                  Easy Exchanges &amp; Returns{" "}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* offer popup starts here */}
      {/* jquery js */}
      {/* bootstrap js */}
      {/* Owl Carousel Js */}
      {/* custom js */}
    </>
  );
};
export default HomePage;
