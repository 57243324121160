import React, { useState } from "react"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminHeader from "../../Dashboard/AdminHeader";
import SideBar from "../../Dashboard/SideBar";
import AdminFooter from "../../Dashboard/AdminFooter";
import {createBlogTagsAPICall } from "../../../../service/apiService";


const BlogsTagCreate = () => {
    const [formData, setFormData] = useState({
        name: '',
        is_active: 0
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleToggleChange = (e) => {
        const isChecked = e.target.checked;
        const activeValue = isChecked ? 1 : 0;
        setFormData({
            ...formData,
            is_active: activeValue
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("admin_token");
        try {
            if (!formData.name ) {
                toast.error('Please Fill The Form Data');
                return;
            }
            const response = await createBlogTagsAPICall(token, formData);
            if (response.status_code === 200) {
                toast.success('Blog tag created successfully!');
                setFormData({
                    name: '',
                    is_active: 0
                });
            } else {
                toast.error('Blog tag creation failed. Please try again.');
            }
        } catch (error) {
            console.error('Error creating blog:', error);
            toast.error('Blog tag creation failed. Please try again.');
        }
    };

    return (
        <div>
            <AdminHeader />
            <SideBar />
            <main className="main-wrap">
                <main id="main" className="main">
                    <div className="pagetitle">
                        <h1>Brand Tag Create</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/dashboard">Dashboard</a>
                                </li>
                                <li className="breadcrumb-item">Blog</li>
                                <li className="breadcrumb-item">Blog Tag</li>
                                <li className="breadcrumb-item active">Create</li>
                            </ol>
                        </nav>
                    </div>
                    {/* End Page Title */}
                    <section className="section">
                        <div className="row">
                            <div className="">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <form className="row g-3" onSubmit={handleSubmit}>
                                            <div className="col-md-6">
                                                <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                                                <div className="col-sm-8">
                                                    <input id="name" type="text" value={formData.name} name="name" placeholder="Type here" onChange={handleChange} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                                                <div className="col-sm-4">
                                                    <input className="form-check-input" type="checkbox" id="toggle" checked={formData.is_active === 1} onChange={handleToggleChange} />
                                                    <label htmlFor="toggle" className="form-check-label"> {formData.is_active === 1 ? "Active" : "Inactive"}</label>
                                                </div>
                                            </div>

                                            <div className="text-center">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </main>
            <AdminFooter />
        </div>
    )
}

export default BlogsTagCreate;
