import React, { useEffect, useState } from "react";
// import UploadImage from '../../../assets/images/upload.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from "react-router-dom";
import AdminHeader from "../Dashboard/AdminHeader";
import SideBar from "../Dashboard/SideBar";
import AdminFooter from "../Dashboard/AdminFooter";

const PagesCreate = () => {
  const [PagesformData, setPagesFormData] = useState({
    name: '',
    slug: '',
    page_content_id: 0,
    is_have_categories: 0,
    is_have_brands: 0,
    is_active: 1
  });
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    slug: ""
  });
  var navigate = useNavigate();
  // const [selectedFile, setSelectedFile] = useState(null);

  const token = localStorage.getItem("admin_token");






  const handleChange = (e) => {
    const { id, value } = e.target;
    setPagesFormData({
      ...PagesformData,
      [id]: value,
      slug: generateSlug(value)
    });
  };

  const handleToggleChange = (e) => {
    const isChecked = e.target.checked;
    const activeValue = isChecked ? 1 : 0;
    setPagesFormData({
      ...PagesformData,
      is_active: activeValue
    });
  };

  const generateSlug = (name) => {
    return name.trim().toLowerCase().replace(/\s+/g, '-');
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('admin_token');
    const csrfToken = localStorage.getItem('csrfToken');
    const errors = {};
    if (!PagesformData.name) {
      errors.name = "Name is required";
    }

    setValidationErrors(errors);

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-CSRF-Token': csrfToken,
      },
    };

    try {
      if (!PagesformData.name || !PagesformData.slug) {
        toast.error('Please Fill The Form Data');
        return;
      }
      const createPayload = {
        ...PagesformData,
        name: PagesformData.name,
      };

      const createResponse = await axios.post('https://folcon.elegra.in/admin/create-page', createPayload, config);

      if (createResponse.data.status === "success") {

        toast.success('Page created successfully!');
        navigate('/admin/List');
        setPagesFormData({
          name: '',
          slug: '',
          page_content_id: 0,
          is_have_categories: 0,
          is_have_brands: 0,
          is_active: 1
        });
      } else {
        toast.error('Page creation failed. Please try again.');
      }
    } catch (error) {
      console.error('Error creating Page:', error);
      toast.error('Page creation failed. Please try again.');
    }
  };


  return (
    <main id="main" className="main">
      <AdminHeader />
      <SideBar />
      <div className="pagetitle">
        <h1>Pages Create</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/dashboard">Dashboard</a>
            </li>
            <li className="breadcrumb-item">Pages</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-10">
            <div className="card mb-4">
              <div className="card-body">
                <form className="row g-3" onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="name" className="col-sm-2 col-form-label">Name</label>
                    <div className="col-sm-4">
                      <input id="name" type="text" value={PagesformData.name} placeholder="Type here" onChange={handleChange} className="form-control" />
                      {validationErrors.name && <div className="text-danger">{validationErrors.name}</div>}

                    </div>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="slug" className="col-sm-2 col-form-label">Slug</label>
                    <div className="col-sm-4">
                      <input id="slug" type="text" value={PagesformData.slug} placeholder="Type here" onChange={handleChange} className="form-control" readOnly />
                    </div>
                  </div>
                  <div className="md-4">
                    <label htmlFor="toggle" className="col-sm-2 col-form-label">Status</label>
                    <div className="col-sm-4">
                      <input className="form-check-input" type="checkbox" id="toggle" checked={PagesformData.is_active === 1} onChange={handleToggleChange} />
                    </div>
                  </div>



                  <div className="text-center">
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

      </section>
      <AdminFooter />
    </main>

  )



}


export default PagesCreate