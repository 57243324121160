import React, { useEffect, useState } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import { VariantDropDown, productVariantSubmit } from "../../service/apiService";
import { ToastContainer, toast } from "react-toastify";
import { CustomToast } from "../../Helper/Helper";

const ProductVariantsForm = ({ productId, disabled }) => {
  const [toastMessage, setToastMessage] = useState(null);
  const [variantList, setVariantList] = useState([]);
  const token = localStorage.getItem("admin_token");
  const [variantFields, setVariantFields] = useState([{ dropdownValue: "", textboxValue: "" }]);

  useEffect(() => {
    VariantDropDownList();
  }, []);

  const VariantDropDownList = async () => {
    try {
      const response = await VariantDropDown(token);
      setVariantList(response.data);
    } catch (error) {
      console.error("Error fetching variant list:", error);
    }
  };

  const handleAddVariant = () => {
    setVariantFields([...variantFields, { dropdownValue: "", textboxValue: "" }]);
  };

  const handleDeleteVariant = (index) => {
    const updatedFields = [...variantFields];
    updatedFields.splice(index, 1);
    setVariantFields(updatedFields);
  };


  const handleChangeDropdown = (index, value) => {
    const updatedFields = [...variantFields];
    updatedFields[index].dropdownValue = value;
    setVariantFields(updatedFields);
  };

  const handleChangeTextbox = (index, value) => {
    const updatedFields = [...variantFields];
    updatedFields[index].textboxValue = value;
    setVariantFields(updatedFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (variantFields[0].dropdownValue !== "" || variantFields[0].textboxValue !== "") {
    const payload = {
      product_id: productId,
      variants: variantFields.map((variant) => ({
        variant_label_id: parseInt(variant.dropdownValue),
        value: variant.textboxValue,
        is_active: 1 
      })) 
    };
    const response = await productVariantSubmit(token, payload);
    if (response.status_code === 200) {
      toast.success(response.message);
      setToastMessage(response.message);
     // setVariantFields([{ dropdownValue: "", textboxValue: "" }]);
      //setVariantList([]);
      setTimeout(() => {
        setToastMessage(null); 
      }, 3000);
    }
  }else{
    toast.error("Please select variant or enter value");
  }
  };
  
  
  return (
    <div className="col-lg-12">
      {toastMessage && (
        <CustomToast message={toastMessage} onClose={() => setToastMessage(null)} />
      )}
      <div className="card">
        <div className="card-body">
          <h4>Variants</h4>
          <form className="row g-3" onSubmit={handleSubmit}>
            {disabled ? (
              <div className="col-md-12">
                <div className="row g-3 align-items-center">
                  <div className="col-md-4">
                    <label className="form-label">Variant</label>
                    <Dropdown options={variantList} disabled />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {variantFields.map((variant, index) => (
                  <div key={index} className="col-md-12">
                    <div className="row g-3 align-items-center">
                      <div className="col-md-4">
                        <label className="form-label">Variant</label>
                        <select
                          id={`variant-dropdown-${index}`}
                          className="form-select"
                          value={variant.dropdownValue}
                          onChange={(e) => handleChangeDropdown(index, e.target.value)}
                        >
                          {!variantList ? (
                            <option value="">No Data...</option>
                          ) : variantList.length === 0 ? (
                            <option value="">No Variant found</option>
                          ) : (
                            <>
                              <option value="">Select Variant</option>
                              {variantList.map((variantItem) => (
                                <option key={variantItem.id} value={variantItem.id}>
                                  {variantItem.name.toUpperCase()}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      </div>
                      <div className="col-md-4">
                        <label className="form-label">Variant Value</label>
                        <Input
                          value={variant.textboxValue}
                          onChange={(e) => handleChangeTextbox(index, e.target.value)}
                        />
                      </div>
                      <div className="col-md-4">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleDeleteVariant(index)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="text-center mt-3">
              <button type="button" className="btn btn-primary" onClick={handleAddVariant}>
                +
              </button>
            </div>
            {
              disabled ?
              <div className="text-center mt-3">
              <button type="submit" className="btn btn-primary" disabled>
                Submit
              </button>
            </div>
            :
            <>
                <div className="text-center mt-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              </>
 
            }
         
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
   
  );
};

export default ProductVariantsForm;
